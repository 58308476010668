export function shortenFileName(fileName, maxLength) {
  const dotIndex = fileName.lastIndexOf(".");
  const extension = dotIndex === -1 ? "" : fileName.substring(dotIndex + 1);
  const fileNameWithoutExtension =
    dotIndex === -1 ? fileName : fileName.substring(0, dotIndex);

  if (fileNameWithoutExtension.length <= maxLength) {
    return fileName;
  }

  const ellipsisLength = 3;
  const shortenedFileNameWithoutExtension = fileNameWithoutExtension.substring(
    0,
    maxLength - ellipsisLength
  );

  return shortenedFileNameWithoutExtension + "..." + extension;
}

export function IdNameCompany(value) {
  const indexTiret = value.indexOf("-");
  if (indexTiret !== -1) {
    const id = value.substring(0, indexTiret);
    const companyName = value.substring(indexTiret + 1);
    return {id, companyName};
  } else {
    return {id: null, companyName: null};
  }
}
