import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import moment from "moment";

export function* reqFetchReminders() {

  yield takeLatest(actions.REQ_FETCH_REMINDERS, function*() {

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, "/reminders");

      const {orders} = response.data;
      yield put(actions.updateReminders(orders));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

}

export function* reqBulkDeleteReminders() {

  yield takeLatest(actions.BULK_DELETE_REMINDER, function*({payload}) {

    try {

      const {references} = payload

      yield call(ApiProvider.axiosWithToken.post, "/reminders/bulk", {references});

      yield put(actions.reqFetchReminders());

      notificationHelper.showSuccess(`Succès`, `Vos relances ont bien été supprimées`, 5);

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });

}

export function* exportToExcelReminders(){

  yield takeLatest(actions.EXPORT_TO_EXCEL_REMINDER,function*(){
     try {
      const setup={
        headers : {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType:'arraybuffer'
      }

      const response= yield call(ApiProvider.axiosWithToken.get, `/reminders/export-excel`,setup);

      const outputFilename = `${"relances_"+moment({hours:0}).format('DD_MM_YYYY')}.xlsx`;
  
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();

     } catch (error) {
        if(error.response.data.error){
          notificationHelper.showError(error.response.data.error)
        }else{
          notificationHelper.showError('SERVER_ISSUE');
        }
     }
  });
}

export default function* rootSaga() {
  yield all([
    fork(reqFetchReminders),
    fork(reqBulkDeleteReminders),
    fork(exportToExcelReminders)
  ])
}
