/* eslint-disable require-yield */
import * as notificationHelper from "../../helpers/notificationHelper";
import actions from "./actions";
import remindersActions from "../reminders/actions";
import invoicesActions from "../invoices/actions";

import { takeLatest, all, fork, put, call } from "redux-saga/effects";
import ApiProvider from "./../../helpers/api";

function* fetchClients(action) {
  try {
    yield put(actions.isLoading(true));

    const response = yield call(ApiProvider.axiosWithToken.get, "/clients");

    const { clients } = response.data;

    yield put(actions.updateClients(clients));

    yield put(actions.isLoading(false));
  } catch (error) {
    yield put(actions.isLoading(false));
    const errorMessage = error.response?.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

function* removeClients(action) {
  try {
    yield put(actions.isLoading(true));

    const ids = action.payload;

    yield call(ApiProvider.axiosWithToken.post, "/clients/delete", { ids });

    yield put(actions.fetchClients());
    yield put(remindersActions.reqFetchReminders());
    yield put(invoicesActions.fetchInvoices());

    notificationHelper.showSuccess(
      "Succés",
      "Votre client a bien été supprimer",
      5
    )

    yield put(actions.isLoading(false));
  } catch (error) {
    yield put(actions.isLoading(false));
    const errorMessage = error.response?.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

function* modifyClients(action){
  try {
    yield put(actions.isLoading(true));

    const {id,data} = action.payload;

    yield call(ApiProvider.axiosWithToken.put, `/clients/update/${id}`,{data});
    
    yield put(actions.fetchClients());
    yield put(actions.isLoading(false));
    yield put(actions.modifyClientsDone());
    yield put(actions.isOpen(false));

    notificationHelper.showSuccess(
      "Succés",
      "Votre client a bien été mise à jour",
      5
    )
    
  } catch (error) {
    yield put(actions.isLoading(false));
    const errorMessage = error.response?.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

export function* reqFetchClients() {
  yield takeLatest(actions.REQ_FETCH_CLIENTS, fetchClients);
}

export function* reqRemoveClients() {
  yield takeLatest(actions.REMOVE_CLIENTS, removeClients);
}

export function* reqModifyClients(){
  yield takeLatest(actions.REQ_MODIFY_CLIENTS, modifyClients);
}

export default function* rootSaga() {
  yield all([
    fork(reqFetchClients),
    fork(reqRemoveClients),
    fork(reqModifyClients)
  ]);
}
