const actions = {
  FETCH_APP_CONFIG: "FETCH_APP_CONFIG",
  UPDATE_PRICE_CONFIG: 'UPDATE_PRICE_CONFIG',
  UPDATE_DOCUMENT_CONFIG: 'UPDATE_DOCUMENT_CONFIG',

  fetchAppConfig: () => ({
    type: actions.FETCH_APP_CONFIG
  }),

  updatePriceConfig: priceConfig => ({
    type: actions.UPDATE_PRICE_CONFIG,
    payload: priceConfig
  }),

  updateDocumentConfig: documentConfig => ({
    type: actions.UPDATE_DOCUMENT_CONFIG,
    payload: documentConfig
  }),
};

export default actions;
