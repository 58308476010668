import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button, Row, Col } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import usersActions from '../../../Redux/user/actions';
import { colors } from '../../../helpers/appColors';
import apiConfig from '../../../config/api.config';


class SignupModal extends Component {

  state = {
    email: null,
    password: null,
    confirmation: null,
    captchaValue: null
  };

  handleCancel = () => {
    this.props.hideModal('homeSignup');
  };


  handleRegister = () => {
    this.props.registerUser(this.state)
  };

  handleInputChange = e => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(obj);
  };

  onChangeRecaptcha = (value) => {
    this.setState({
      captchaValue: value
    })
  }

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
      h2: {
        borderBottom: `solid 1px ${colors.primaryGreen}`,
        fontFamily: 'helvetica neue',
        fontWeight: 200,
        fontSize: 26,
        color: '#4b5258 !important',
        paddingBottom: 10
      },
      em: {
        fontSize: 12,
        marginBottom: 10,
        display: 'block'
      },
      input: {
        textAlign: 'center',
        marginBottom: 20,
        marginTop: 5
      }
    };

    return (
      <Modal
        title={`Créez votre compte`}
        visible={this.props.homeSignupVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >
        <h2 style={style.h2}>Je crée mon compte gratuitement</h2>

        <em style={style.em}>Conformément à la loi "Informatique et libertés" du 6 janvier 1978 modifiée,
          vous bénéficierez d'un droit d'accès et de rectification aux informations vous concernant
        </em>

        <strong>Mon adresse e-mail :</strong>
        <Input style={style.input} name='email' onChange={this.handleInputChange}/>

        <strong>Mon mot de passe* :</strong>
        <Input style={style.input} type='password' name='password' onChange={this.handleInputChange} />

        <strong>Confirmation :</strong>
        <Input style={style.input} type='password' name='confirmation' onChange={this.handleInputChange} />

        <em>(*) 5 caractères minimum (lettres et chiffres uniquement)</em>

        <br/>
        <br/>
        <Row gutter={16}>
          <Col span={12} offset={4}>
            <ReCAPTCHA
                sitekey={apiConfig.siteKey}
                onChange={this.onChangeRecaptcha}
            />
          </Col>
        </Row>
        <br/>
        <Button type='primary' loading={this.props.registerIsLoading} onClick={this.handleRegister}>Je m'inscris</Button>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    homeSignupVisible: state.modals.homeSignupVisible,
    registerIsLoading: state.user.registerIsLoading
  }),
  {
    hideModal: modalsActions.hideModal,
    registerUser: usersActions.registerUser,
    toggleRegisterLoader: usersActions.toggleRegisterLoader
  }
)(SignupModal);