import styled from 'styled-components';
import { device } from '../../../helpers/deviceBreakpoints';
import { colors } from '../../../helpers/appColors';

const Wrapper = styled.section`
  border-bottom: #f1f1f1 1px solid;
  position: fixed;
  background: white !important;
  width: 100%;
  min-height: 100px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
  
  .ant-anchor-ink{
    display: none;
  }

  .rm-border{
    border : 0px solid!important
  }


  .debtor-btn a{
    margin-left: 30px;
    border-radius : 0px !important;
    color : ${colors.debtorBtn} !important;

    @media ${device.tablet}{
      margin-left: 0px;
    }

    @media ${device.desktopM}{
      margin-left: 0px;
    }
  }
  
  
  #main-logo {

    @media ${device.desktopM} {
      width: 160px!important;
    }

    @media ${device.laptopL} {
      left: 10;
      width: 165px;
    }
    @media ${device.laptop} {
      left: 50%;
      margin-left: -60px;
      width: 165px;
    }
    
    top: 20px;
    width: 165px;
    position: absolute;
    left: 20px
  }
  
  #btn-connection {
    @media ${device.laptop} {
      display: none;
    }
    width: 100%;
  }
  
  #btn-inscription {
    @media ${device.laptop} {
      display: none;
    }
    width: 100%;
    margin-top: 5px;
    border: solid ${colors.primaryGreen} 1px;
    color: ${colors.primaryGreen};
  }
  
  #burger-menu {
    display: none;
    @media ${device.laptop} {
      display: block;
      position: absolute;
      right: 20px;
    }
    font-size: 18px;
  }
  
  #top-menu {
    list-style: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
    
    @media ${device.laptop} {
      display: none;
    }
    
    .onglet{
        @media ${device.laptopL} {
          font-size: 14px;
          width: 140px;
        }
        @media ${device.desktopM} {
          font-size: 12px;
          width: 110px;
        }
        font-size: 16px;
        display: inline-block;
        border-right: 2px solid #3eb17b;
        width: 175px;
        text-align: center;
        align-items: center;
    }
    .onglet a{
        color:#4b5258;
        text-decoration: none;
        white-space: break-spaces;
    }
    
    .onglet a:hover{
        text-decoration: none;
        color:#4b5258;
    }
    
    .onglet:first-child{
        border-left: 2px solid #3eb17b;
    
    }
  }

`;

export default Wrapper;