import styled from 'styled-components';
import { device } from '../../../helpers/deviceBreakpoints';

const Wrapper = styled.section`
  border-top: #f1f1f1 1px solid;
  background: white !important;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
  margin-top: 30px;
  
  #menu {
    list-style: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .onglet{
      font-size: 14px;
      display: inline-block;
      border-right: 2px solid #3eb17b;
      text-align: center;
      align-items: center;
      padding: 0px 20px;
    }
    .onglet a{
        color:#4b5258;
        text-decoration: none;
    }
    
    .onglet a:hover{
        text-decoration: none;
        color:#4b5258;
    }
    
    .onglet:first-child{
        border-left: 2px solid #3eb17b;
    
    }
  }

`;

export default Wrapper;