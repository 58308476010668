import styled from 'styled-components';
import { device } from '../../helpers/deviceBreakpoints';
import { colors } from '../../helpers/appColors';
import heroBg from '../../resources/images/landing_page/autre/hero2.jpg'

const StyleWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  
  h1{
    font-size: 65px;
    font-weight: 200;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: #4b5258 !important;
    margin-bottom: 8px;
    
    @media ${device.laptop} {
      font-size: 35px;
    }
    
  }
  
  h2{
    font-size: 50px;
    font-weight: 200;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: #4b5258 !important;
    line-height: 60px;
    
    @media ${device.laptop} {
      font-size: 25px;
      line-height: 30px;
    }
    
  }
  
  #hero-block {
    background-image: url(${heroBg});
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 90vh;
    margin-top: 100px;
    padding-top: 150px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  #play-button{
    font-size: 100px;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  #play-button:hover{
    color: ${colors.primaryGreen};
  }
  
  #bloc-cabinet{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 100px 0;
    margin: 0 !important;
    
    h3 {
      font-size: 25px;
      font-weight: bold;
    }
    
    p {
      font-size: 15px;
      width: 250px;
      margin: auto;
    }
    
    .cabinet-icon { 
      color: ${colors.primaryGreen};
      font-size: 45px;
      margin: 20px;
    }
  }
  
  #bloc-solution{
    padding: 100px 0;
    text-align: center;
    margin: 0 !important;
    
    p {
      font-size: 15px;
      margin: auto;
    }
    
    .lecture {
      margin-top: 50px;
      width: 75px;
    }
    
    .lettre {
      margin-top: 20px;
      width: 80%;
      max-width: 300px;
    } 
  }
  
  #crumbs {
    @media ${device.laptop} {
      display: none;
    }
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    padding-left: 7%;
    ul {
      padding: 0px;
    }
    
    li {
      display: inline;
      p {
        font-weight: 600 !important;
        margin-bottom: 1px;
        font-style: normal;
        font-size: 20px;
      }
      span {
        float: left;
        height: 200px;
        width: 23%;
        background: #E2E6E7;
    
        text-align: center;
        padding: 18px 20px 0 60px;
        position: relative;
        margin: 0 10px 0 0;
    
        font-size: 14px;
        text-decoration: none;
        color: #4b5258;
      }
      
      span:after {
        content: "";
        border-top: 100px solid transparent;
        border-bottom: 100px solid transparent;
        border-left: 40px solid #E2E6E7;
        position: absolute;
        right: -40px;
        top: 0;
        z-index: 1;
        height: 200px;
      }
      
      span:before {
        content: "";
        border-top: 100px solid transparent;
        border-bottom: 100px solid transparent;
        border-left: 40px solid #fff;
        position: absolute;
        left: 0;
        top: 0;
        height: 200px;
      }
    }
    
    li:first-child {
      span {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      span:before {
        display: none;
      }
    }
    
    li:last-child {
      span {
        padding-right: 40px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      
      span:after {
        display: none;
      }
    }
  }
  
  #bloc-accompagne{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 100px 0;
    margin: 0 !important;
    
    h3 {
      font-size: 25px;
      font-weight: bold;
    }
    
    p {
      font-size: 15px;
    }
    
    .icon{
      width: 75px;
    }
    
  }
  
  
  #bloc-tarif{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 100px 0;
    margin: 0 !important;
    
    #tableau-tarif{
      font-family: inherit;
      font-size: 0.8em;
      max-width: 1200px;
      margin: auto;
      overflow: hidden;
      overflow-x: auto;
      
      .text-center {
        text-align: center;
      }
    }
    
    #tableau-tarif table{
      width: 100%;
      min-width: 600px;
      font-weight: bold;
    }
    
    #tableau-tarif table tbody tr td{
      height: 50px;
    }
    
    
    .column-tarif{
      max-width: 90px !important;
      font-weight: normal;
      font-size: 14px;
    }
    
    
    .left-title{
      font-size: 15px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    
    .bigTitle{
      font-size: 15px;
    }
    
    .primary-green-cell{
      background: #3db17a !important;
      color: white;
      text-align: left;
    }
    
    .primary-red-cell{
      background: #F03945 !important;
      color: white;
      text-align: left;
    }
    
    .primary-grey-cell{
      background: #4C5256 !important;
      color: white;
      text-align: left;
    }
    
    .secondary-grey-cell{
      background: #A5A5A7 !important;
      color: white;
      text-align: left;
    }
    
    .primary-black-cell{
      background: #222020 !important;
      color: white;
      text-align: left;
    }
    
    .tarif-green-cell {
      background-color: #b8dfc6;
      color: #515151;
      text-align: left;
    }
    
    .red-cell {
      background-color: #F8B1A2;
      color: #515151;
      text-align: left;
    }
    
    .grey-cell {
      background: #E4E4E5;
      color: #515151;
    }
    
    #tableau-tarif table th, #tableau-tarif table td{
      border-left: solid white 10px;
      border-bottom: solid 1px white;
      padding: 5px;
    }
    
    @media screen and (max-width: 1024px) {
      #tableau-tarif table th, #tableau-tarif table td{
        border-left: solid white 5px;
        padding: 5px;
      }
  
      .left-title{
        font-size: 14px;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
  
      .bigTitle{
        font-size: 14px;
      }
  
      #tableau-tarif{
        font-size: 0.7em;
      }
    }
    
    @media screen and (max-width: 425px) {
      #tableau-tarif table th, #tableau-tarif table td{
        border-left: solid white 2px;
      }
  
      #tableau-tarif{
        font-size: 0.6em;
      }
    }
    
  }
  
  #bloc-presse{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 100px 0;
    margin: 0 !important;
    
    .ant-row {
      margin: 50px 0px;
      display: flex;
      align-items: center;
    }
    
    .text6{
      font-size: 24px;
      font-style: italic;
    }
    
    .logo-big{
      width: 80% !important;
      max-width: 300px;
    }
      
    a img{
      width: 70%;
      max-width: 170px;
    }
  }
  
  #bloc-followers{
    a img{
      width: 70%;
      max-width: 200px;
      margin: 10px 70px;
    }
  }
  
  
  .subtitle{
    font-size: 26px !important;
    @media ${device.laptop} {
      font-size: 16px !important;
    }
  }
  
  .linear-grey-gradient {
    background: linear-gradient(180deg, rgba(236,237,238,1) 0%, rgba(255,255,255,1) 12%, rgba(255,255,255,1) 100%);
  }
  
  #bloc-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  
    padding: 50px 0;
    margin: 0 !important;
  }
  
  .social-icon{
    font-size: 40px;
    color: ${colors.primaryGreen};
  }
  
  .social-icon-square{
    background: rgb(61, 178, 122);
    height: 36px;
    width: 36px;
    display: inline-block;
    vertical-align: text-bottom;
    color: white;
    font-size: 9px;
    justify-content: center;
    font-weight: bold;
    padding-top: 10px;
    text-align: center;
    margin: 0px 2px;
    border-radius: 2px;
  }
  
  .ant-anchor-link {
    padding: 7px 0 7px 0 !important;
  }
  
`;

export default StyleWrapper;