import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  invoices: [],
  invoicesIsLoading: false,
  error: null,
  loading: false,
  isModalOpen: false
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.UPDATE_INVOICES:
      newState.invoices = action.payload;
      return newState;

    case actions.INVOICES_IS_LOADING:
      newState.invoicesIsLoading = action.payload;
      return newState;
    
    case actions.IS_MODAL_OPEN:
      newState.isModalOpen = action.payload;
      return newState;

    default:
      return state;
  }
}
