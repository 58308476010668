import styled from "styled-components";
import { Modal } from "antd";

const CustomModal = styled(Modal)`
  
  width: 40%;
  min-width: 700px;
  
  .ant-modal-body{
    padding: 0 0 24px 0;
  }
  
  .ant-modal-header{
    border-bottom: none;
  }
`;

export default CustomModal;