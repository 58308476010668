import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Form, Spin, Table, Input} from 'antd';
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import NumberFormat from "react-number-format";
import orderActions from '../../../Redux/order/actions';

class TabOrders extends Component{

  state = {
    searchValue: null
  };

  componentDidMount(){
    this.props.reqFetchMyOrders();
  }

  onFinish = (values) => {
  };

  render(){
    const { user } = this.props;

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (text, record, index) => <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>,
        sorter: (a,b) => a.createdAt < b.createdAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Facture',
        dataIndex: 'transaction',
        sorter: (a,b) => a.transaction < b.transaction ? -1:1,
        showSorterTooltip: false,
        render: (text, record, index) => (record.invoice)?<a href={record.invoice} target='_blank'>{text}</a> : <span>{text}</span>
      },
      {
        title: 'Libellé',
        dataIndex: 'name',
        sorter: (a,b) => a.name < b.name ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Montant TTC',
        dataIndex: 'amount',
        render: (text, record, index) => {
          if(record.invoice) {
            return (<span>
              <NumberFormat
                displayType={'text'}
                value={text}
                decimalSeparator={','}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator={' '}
                suffix={'€'}
              />
            </span>)
          }else{
            return `-`;
          }
        },
        sorter: (a,b) => {

          if(a.invoice && b.invoice){
            return a.amount < b.amount ? -1:1
          }else if(a.invoice && !b.invoice){
            return 1
          }else if(!a.invoice && b.invoice){
            return -1;
          }else{
            return -1;
          }
        },
        showSorterTooltip: false
      },
    ];

    const origin = this.props.orders;

    const data = origin.filter(el => {
      if(
        this.state.searchValue
        && moment(el.createdAt).format('DD/MM/YYYY').toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && el.transaction.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && el.amount.toString().replace('.',',').toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && el.name.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
      ) return false;
      return true;
    });

    return (
      <Spin spinning={!user}>
        <Form onFinish={this.onFinish}>
          <div className='grey-block'>
            <h3>Mes factures</h3>

            <Input
              style={{width: 300, marginBottom: 10}}
              placeholder='Rechercher'
              onChange={(e) => this.setState({searchValue: e.target.value})}
            />

            <Table
              size={'small'}
              bordered={true}
              columns={columns}
              dataSource={data}
              rowKey='id'
              loading={this.props.isLoading}
            />
          </div>
        </Form>
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    orders: state.order.myOrders,
    isLoading: state.order.isLoading
  }),
  {
    reqFetchMyOrders: orderActions.reqFetchMyOrders
  }
)(TabOrders));