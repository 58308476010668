import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { Button, Row, Col, Divider } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import PricingTag from "../Helpers/PricingTag";
import {FormInput} from "../../../helpers/forms";
import NumberFormat from "react-number-format";
import _ from "lodash";
import {colors} from "../../../helpers/appColors";
import CustomModal from "./CustomModal.style";


class MailDirectRecouvrementModal extends Component {

  componentDidUpdate(prevProps, prevState, snapshot){
    if(
      (
        this.props.customerMailDirectRecouvrementVisible !== prevProps.customerMailDirectRecouvrementVisible
        || this.props.myCompanies !== prevProps.myCompanies
      )
      && this.props.customerMailDirectRecouvrementVisible) {
      if (this.props.formRef.current) {
        const {
          companyId,
          mailDrMail,
        } = this.props.formRef.current.getFieldsValue();
        if(companyId){
          const company = _.find(this.props.myCompanies, {id: parseInt(companyId)});
          if(company){
            this.props.formRef.current.setFieldsValue({
              mailDrMail: mailDrMail ? mailDrMail : company.email,
            })
          }
        }
      }
    }
  }

  onFinish = () => {
    this.props.formRef.current.setFieldsValue({submitType: 'mail_direct_recouvrement'});
    this.props.formRef.current.submit();
  };

  render(){

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    const { user, appPrices } = this.props;

    if(!user) return <></>;

    const pricing = appPrices.mailDrPricing;

    return (
      <CustomModal
        title={`Mail Direct Recouvrement`}
        visible={this.props.customerMailDirectRecouvrementVisible}
        onCancel={() => this.props.hideModal('customerMailDirectRecouvrement')}
        footer={null}
        style={style.modal}
        destroyOnClose={true}
      >
        <Row gutter={16} style={{textAlign: 'center', margin: 0, background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={`${pricing.free === 0 ? 'Gratuit' : pricing.free}`} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={`${pricing.starter === 0 ? 'Gratuit' : pricing.starter}`} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={`${pricing.premium === 0 ? 'Gratuit' : pricing.premium}`} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={`${pricing.privilege === 0 ? 'Gratuit' : pricing.privilege}`} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 10, color: "white"}}>
          </Col>
        </Row>

        <br/>

        <p style={{textAlign: 'center'}}>
          <em>
          Déclenchez l’envoi d’un mail automatique de notre cabinet
          </em>
        </p>

        <Row gutter={16} style={{margin: 0}}>
          <Col span={12} offset={6}>
            <FormInput
              label={`Adresse mail de votre débiteur`}
              alignLabel='center'
              placeholder={`Email`}
              name={`mailDrMail`}
              type='email'
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
            />
          </Col>
        </Row>

        <p style={{textAlign: 'center', marginTop: 20}}>
          <em>Montant en principal:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalBalance}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong><br/>
          <em>Pénalités de retard calculées:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalPenalties}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong>
        </p>

        <div style={{textAlign: 'center'}}>
          <Button type='primary' onClick={this.onFinish}>Visualiser</Button>
        </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    customerMailDirectRecouvrementVisible: state.modals.customerMailDirectRecouvrementVisible,
    appPrices: state.app.prices,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance,
    myCompanies: state.calculator.myCompanies
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)(MailDirectRecouvrementModal);