import React, { Component } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from '../../Redux/user/actions';
import modalActions from '../../Redux/modals/actions';
import appActions from '../../Redux/app/actions';
import StyleWrapper from './StyleWrapper';

import CustomerHeader from './Header/CustomerHeader';
import CustomerFooter from './Footer/CustomerFooter';
import CustomerDashboard from './Dashboard/CustomerDashboard';
import CustomerBbp from './Bbp/CustomerBbp';
import CustomerCalculator from "./Calculator/CustomerCalculator";
import CustomerProfile from './Profile/CustomerProfile';
import CustomerSubscription from "./Subscription/CustomerSubscription";
import NewProfileModal from "./Modals/NewProfile";
import CustomInformationModal from "./Modals/CustomInformation";
import CustomerOrder from "./Order/CustomerOrder";
import CustomerLanding from "./Landing/CustomerLanding";

class CustomerMain extends Component{

  componentDidMount(){
    if(this.props.match.params.hash){
      const { hash } = this.props.match.params;
      this.props.confirmUser(hash);
    }
    if(this.props.token){
      this.props.fetchUser();
      this.props.fetchAppConfig();
    }
  }

  render(){
    const {user, appPrices, appDocuments} = this.props;
    if(!user || !appPrices || !appDocuments) return (<></>);

    return (
      <StyleWrapper>
        <CustomInformationModal/>
        <NewProfileModal/>
        <CustomerHeader/>
        <section id='customer-main'>
          <Switch>
            <Route path='/main'>
              <CustomerLanding/>
            </Route>
            <Route path='/dashboard'>
              <CustomerDashboard/>
            </Route>
            <Route path='/bbp'>
              <CustomerBbp/>
            </Route>
            <Route path='/penalites'>
              <CustomerCalculator/>
            </Route>
            <Route path='/amiable'>
              <CustomerCalculator/>
            </Route>
            <Route path='/contentieux'>
              <CustomerCalculator/>
            </Route>
            <Route path='/judiciaire'>
              <CustomerCalculator/>
            </Route>
            <Route path='/profile'>
              <CustomerProfile/>
            </Route>
            <Route path='/subscribe'>
              <CustomerSubscription/>
            </Route>
            <Route path='/order/:orderId'>
              <CustomerOrder/>
            </Route>
          </Switch>
        </section>
        <CustomerFooter/>
      </StyleWrapper>
    )
  }

}

export default withRouter(connect(
  state => ({
    token: state.user.token,
    user: state.user.info,
    appPrices: state.app.prices,
    appDocuments: state.app.documents
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    showModal: modalActions.showModal,
    fetchAppConfig: appActions.fetchAppConfig
  }
)(CustomerMain));
