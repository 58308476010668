import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal } from 'antd';
import modalsActions from '../../../Redux/modals/actions';


class CustomInformationModal extends Component {


  render(){

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    return (
      <Modal
        title={this.props.customerCustomTitle}
        visible={this.props.customerCustomInformationVisible}
        onCancel={() => this.props.hideModal('customerCustomInformation')}
        footer={null}
        style={style.modal}
        width={'40%'}
      >

        <p style={{textAlign: 'center'}}>
          {this.props.customerCustomText}
        </p>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    customerCustomInformationVisible: state.modals.customerCustomInformationVisible,
    customerCustomText: state.modals.customerCustomText,
    customerCustomTitle: state.modals.customerCustomTitle,
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)(CustomInformationModal);