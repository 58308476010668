/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import userActions from "../../../Redux/user/actions";
import clientsActions from "../../../Redux/clients/actions";
import invoiceActions from "../../../Redux/invoices/actions";
import invoicePdfActions from "../../../Redux/invoicesPdf/actions";
import moment from "moment";
import NumberFormat from "react-number-format";
import { colors } from "../../../helpers/appColors";
import _, { times } from "lodash";
import { showError } from "../../../helpers/notificationHelper";
import {
  Table,
  Button,
  Input,
  Select,
  Row,
  Col,
  Popconfirm,
  Modal,
  Form,
} from "antd";
import {
  CheckOutlined,
  UploadOutlined,
  DeleteOutlined,
  FormOutlined
} from "@ant-design/icons";
import calculatorActions from "../../../Redux/calculator/actions";
import history from "../../../helpers/history";
import { shortenFileName } from "../../../helpers/utils";

//import apiConfig from "./../../../config/api.config";

import { v4 as uuidv4 } from "uuid";
import { FormInput } from "../../../helpers/forms";

const { Option } = Select;
const SelectActionOptions = ["Créer une relance", "delete", "create_reminder"];

class CustomerClients extends Component {
  state = {
    searchValue: null,
    selectedOption: SelectActionOptions,
    selectedRows: [],
    selectedRow : {},
    selectedRowKeys : [],
    disabledSubmit: true,
    disabledDelete: true,
    disabledEdit: true,
  };
  

  clientRef = React.createRef();


  componentDidMount() {
    this.props.fetchClients();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.modifyClientsDone && this.props.modifyClientsDone) {
      // Exécuter le code après la modification
      this.setState({
        selectedRow: {},
        selectedRows: [],
        selectedRowKeys: [],
        disabledSubmit: true,
        disabledDelete: true,
      });
    }
  }

  openModal = (record = null) => {
    let selectedIds = [];
    for (let option of this.state.selectedRows) {
      selectedIds.push(option.key);
    }

    if(selectedIds.length>1){
      showError('ONLY')
    }else{

      let selectedRow;

      if(record){
        selectedRow= record;
        this.setState({
          selectedRow : selectedRow
        })
      }else{
        selectedRow = this.state.selectedRow;
      }

      this.clientRef.current.setFieldsValue({
        address : selectedRow.addresse,
        email : selectedRow.email,
        zip : selectedRow.zip,
        city : selectedRow.city
      })
      this.props.isOpen(true);
    }
  }

  handleCreateReminderAction = () => {
    let selectedIds = [];
    for (let option of this.state.selectedRows) {
      selectedIds.push(option.key);
    }

    if (selectedIds.length > 1) {
      showError('ONLY');
    } else {
      const id = selectedIds[0];
      const tempClient = _.find(this.props.clients, { id });
      this.props.updateDefaultFormValues({
        companyId: tempClient.id,
        companyName: tempClient.companyName,
        step: 1,
        invoices: [
          {
            number: null,
            emittedAt: null,
            dueAt: null,
            totalWithTaxes: null,
            payments: [],
            disabled: false,
            factureName: '',
            factureUrl: ''
          },
        ],
        rateType: "none",
      });
      
      let selectedOptionUrl = "";
      switch (this.state.selectedOption) {
        case "Pénalités_retard":
          selectedOptionUrl = "/penalites";
          break;
        case "Amiable":
          selectedOptionUrl = "/amiable";
          break;
        case "Contentieux":
          selectedOptionUrl = "/contentieux";
          break;
        case "Judiciaire":
          selectedOptionUrl = "/judiciaire";
          break;
        default:
          selectedOptionUrl = "/main"; 
          break;
      }
  
      history.push(selectedOptionUrl);
    }
  };

  // Fonction pour l'action "Delete"
  handleDeleteAction = () => {
    let selectedIds = [];
    for (let option of this.state.selectedRows) {
      selectedIds.push(option.key);
    }

    this.props.removeClients(selectedIds);

    // Réinitialiser l'état après la suppression
    this.setState({
      selectedRow: {},
      selectedRows: [],
      selectedRowKeys: [],
      disabledSubmit: true,
      disabledDelete: true,
      disabledEdit: true
    });
  };


  handleSubmitAction = (isInName = false) => {
    let selectedIds = [];
    for (let option of this.state.selectedRows) {
      selectedIds.push(option.key);
    }
  
  
    if (this.state.selectedOption.includes("update") || isInName) {
      if (selectedIds.length > 1) {
        showError('ONLY');
      } else {
        const values = this.clientRef.current.getFieldsValue();
        const selectedRow = this.state.selectedRow;
        const id = selectedRow.key;
        const dataToSent = {
          name: selectedRow.companyName,
          ...values
        };
  
        this.props.modifyClients(id, dataToSent);
        this.setState({
          selectedRow: {},
          selectedRows: [],
          selectedRowKeys: [],
          disabledSubmit: true,
          disabledDelete: true,
          disabledEdit: true
        });
      }
    }
  
  };

  onSelectChange = value => {
    this.setState({
      selectedOption: value
    })
    if(this.state.selectedRows.length>0){
      this.setState({
        disabledSubmit: false,
      })
    }
  }
  

  render() {
    const courrierLabels = {
      courrier_direct_recouvrement: "Courrier DR",
      injonction: "Injonction",
      mail_direct_recouvrement: "Mail DR",
      mise_en_demeure_direct_recouvrement: "Mise en demeure DR",
      mise_en_demeure: "Mise en demeure",
      reminder: "Rappel",
      unpaid_notice: "Avis d'impayé",
      written_proof: "Justificatif comptable",
    };

    const columns = [
      {
        title: "Nom / Raison sociale",
        dataIndex: "companyName",
        sorter: (a,b) => a.companyName < b.companyName ? -1:1,
        showSorterTooltip: false,
        render : (text,record,index) =>{
          return (
            <span 
              onMouseEnter={(event)=>{
                event.target.style.color = colors.primaryGreen
              }} 
              onMouseLeave={(event)=>{
                event.target.style.color = colors.textColor
              }}
              style={{cursor:'pointer'}} onClick={(event)=>this.openModal(record)}>  {record.companyName} </span>
          )
        }
      },
      {
        title: "Adresse",
        dataIndex: "addresse",
        sorter: (a,b) => a.addresse < b.addresse ? -1:1,
        showSorterTooltip: false,
      },
      {
        title: "CP",
        dataIndex: "zip",
        sorter: (a,b) => a.zip < b.zip ? -1:1,
        showSorterTooltip: false,
      },
      {
        title: "Ville",
        dataIndex: "city",
        sorter: (a,b) => a.city < b.city ? -1:1,
        showSorterTooltip: false,
      },
      {
        title: "Mail",
        dataIndex: "email",
        sorter: (a,b) => a.email < b.email ? -1:1,
        showSorterTooltip: false,
      },
      {
        title: "Encours",
        dataIndex: "sommeMontantInvoices",
        render: value => <span>
        <NumberFormat
          displayType={'text'}
          value={value}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        />
      </span>,
        sorter: (a,b) => a.sommeMontantInvoices < b.sommeMontantInvoices ? -1:1,
        showSorterTooltip: false
      },
      {
        title: (
          <span>
            Dernière relance
          </span>
        ),
        dataIndex: 'lastAction',
        render: (text, record, index) => {
          if(record.lastAction){
            return (
              <div style={{textAlign: 'center'}}>
                <Button
                  type='default'
                  href={record.lastActionUrl}
                  size='small'
                  target={'_blank'}
                >
                  {courrierLabels[text]}
                </Button>
                <br/> le {moment(record.lastActionAt).format('DD/MM/YYYY')}
              </div>
            )
          }
        },
      }
    ];

    const rowSelection = {
      selectedRowKeys:this.state.selectedRowKeys,
      onChange: (newSelectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: newSelectedRowKeys,
          selectedRows: selectedRows,
          selectedRow: selectedRows[0],
        })
        if (selectedRows.length > 0 ) {
          if (this.state.selectedOption[2] === "create_reminder") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: false,
              disabledEdit: false
            });
          }else{
          this.setState({
            disabledSubmit: false,
            disabledDelete: false,
            disabledEdit: false
          });}
        } else {
          if (this.state.selectedOption[2] === "create_reminder") {
            this.setState({
              disabledSubmit: true,
              disabledDelete: true,
              disabledEdit: true
              
            });
          }else{
          this.setState({
            disabledSubmit: true,
            disabledDelete: true,
            disabledEdit: true
          });}
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    let data = [];

    this.props.clients.map((client, idx) => {
      data.push({
        index: idx,
        uuid: uuidv4(),
        key: client.id,
        ...client
      });
    });

    data = data.filter((el) => {
      const formattedLastAction = courrierLabels[el.lastAction]
        ? courrierLabels[el.lastAction]
        : "";
      if (
        this.state.searchValue &&
        el.companyName
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1 &&
        el.addresse
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1 &&
        el.zip
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1 &&
        el.city
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1 &&
        el.email
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1 &&
        formattedLastAction
          .toString()
          .toUpperCase()
          .search(this.state.searchValue.toUpperCase()) === -1
      )
        return false;
      return true;
    });

    const selectedRow = this.state.selectedRow;

    return (
      <div>
        <Form ref={this.clientRef} >
        {
           (
            <Modal 
              title={selectedRow?.companyName}
              centered
              open={this.props.open}
              onCancel={() => this.props.isOpen(false)}
              footer={null}
              width={1000}
            >
              
                <Row style={{textAlign: 'center'}} gutter={16}>
                  <Col span={8}>
                    <p><b>Adresse</b></p>
                    <Form.Item
                      rules={[
                        {
                          max: 44,
                          message: "Maximum 45 caractères",
                          warningOnly: true
                        }
                      ]}
                      name={"address"}
                    >
                      
                      <Input.TextArea placeholder={'Adresse (Maximum 45 caracatères)'} maxLength={45} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <p><b>Code postal</b></p>
                    <Form.Item
                      name={"zip"}
                    >
                      <Input placeholder={'Code postal'}/>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <p><b>Ville</b></p>
                    <Form.Item
                      name={"city"}
                    >
                      <Input placeholder={'Ville'} />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <p><b>Adresse mail</b></p>
                    <Form.Item
                      name={"email"}
                      rules={[{
                        type: 'email',
                        message: 'Adresse mail invalide',
                      }]}
                    >
                      <Input placeholder={'Adresse mail'} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{textAlign: 'center'}}>
                  <Col span={24}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        onClick={()=> this.handleSubmitAction(true)}
                      >
                        Valider
                      </Button>
                  </Col>
                </Row>
          </Modal>
          )
        }
         </Form>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"  }}>
          <div>
              <Select
                value={this.state.selectedOption}
                placeholder="Créer une relance"
                style={{ width: 170 }}
                onChange={this.onSelectChange}
              >
                <Option value="Pénalités_retard">Pénalités de retard</Option>
                <Option value="Amiable">Amiable</Option>
                <Option value="Contentieux">Contentieux</Option>
                <Option value="Judiciaire">Judiciaire</Option>
              </Select>
              <Button
                  type={"primary"}
                  disabled={this.state.disabledSubmit}
                  style={{ marginLeft: 5 }}
                  onClick={() => this.handleCreateReminderAction()}
                >
                  <CheckOutlined />
                </Button>
                
                <Button disabled={this.state.disabledEdit} style={{backgroundColor: 'transparent' , margin: '0 2px' , border: 'none' , color:  this.state.disabledEdit ? 'grey' : 'green' , cursor: this.state.disabledEdit ? 'not-allowed' : 'pointer' }} onClick={() => !this.state.disabledEdit && this.openModal()} icon={ <FormOutlined />}></Button>

                <Popconfirm
                  title="La suppression d’un client entraîne la suppression des factures et relances de ce client."
                  onConfirm={() => this.handleDeleteAction()}
                  onCancel={() => false}
                  okText="Oui"
                  cancelText="Annuler"
                >
                  <Button type={'primary'} disabled={this.state.disabledDelete} shape='circle' style={{ borderRadius: '50%' }} icon={<DeleteOutlined />}/>
                </Popconfirm>
            </div>
          <div>
            {/*<Button type={'default'} className='blue-btn' style={{marginRight: 5}} ><PlusOutlined /> Ajouter</Button>*/}
            {/*<Button type={'default'} style={{marginRight: 5}} ><DownloadOutlined /> Importer (.xls)</Button>*/}
            {
              <Button
                type={"default"}
                disabled={data.length > 0 ? false : true}
                style={{ marginRight: 5 }}
                onClick={() =>
                 {}
                }
              >
                <UploadOutlined /> Exporter
              </Button>
            }
            <Input
              style={{ width: 300, marginBottom: 10 }}
              placeholder="Rechercher"
              onChange={(e) =>this.setState({ searchValue: e.target.value })}
            />
          </div>
        </div>
        <Table
          size={"small"}
          bordered={true}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      userId: state.user.info.id,
      token: state.user.token,
      clients: state.clients.clients,
      open : state.clients.open,
      invoices: []
    }),
    {
      fetchClients : clientsActions.fetchClients,
      modifyClients : clientsActions.modifyClients,
      removeClients : clientsActions.removeClients,
      isOpen : clientsActions.isOpen,
      updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
      modifyClientsDone : clientsActions.modifyClientsDone
    }
  )(CustomerClients)
);
