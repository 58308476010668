import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.footer`
  padding-left:200px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: solid 2px ${colors.primaryGreen};
  
  ul{
    margin: 0 0 50px 0;
    padding:0;
  }
  }


  li {
    text-align: center;
    display: inline-block;
    list-style:none;
    margin: 20px;
    @media(max-width:1400px){
      margin:20px 10px
    }
  }
  
  .main-logo{
    min-width: 150px;
    width: 100%;
  }
  
  .menu-icon{
    font-size: 24px;
    color: ${colors.textGrey};
  }
  
  .menu-title{
    text-transform: uppercase;
    font-weight: bold;
    color: ${colors.textGrey};
  }
  
  .menu-button:hover, .menu-button.active{
    .menu-icon{
      color: ${colors.primaryGreen} !important;
    }
    .menu-title{
      color: ${colors.primaryGreen} !important;
    }
  }
  
`;

export default StyleWrapper;