import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Tabs} from 'antd';
import connect from "react-redux/es/connect/connect";
import _ from 'lodash';
import userActions from "../../../Redux/user/actions";
import orderActions from '../../../Redux/order/actions';
import StyleWrapper from './StyleWrapper';
import TabAccount from './TabAccount';
import TabCoordinates from './TabCoordinates';
import TabOrders from "./TabOrders";
import TabOptions from "./TabOptions";
import TabPayments from "./TabPayments";

const { TabPane } = Tabs;


class CustomerProfile extends Component{

  componentDidMount(){
    this.checkUrlParamsAndFinaliseOrder();
  }

  checkUrlParamsAndFinaliseOrder = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search); 
    const orderId = queryParams.get("order");
    const hash = queryParams.get("hash");

    if(orderId && hash) {
      // FINALIZEORDER
      this.props.finalizeOrder('stripe',orderId,hash,false,true)
    }
  }

  getActiveTab = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search); 
    const tab = queryParams.get('tab'); 
    return tab || 'account';
  }

  render(){
    const { user } = this.props;

    const mentionsLegales = _.find(this.props.documents, ['name', 'mentions_legales']);

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center', width: '95%',marginLeft: '85px'}}>
          <Col span={24} className="shadowed-block">
            <Tabs defaultActiveKey={this.getActiveTab()}>
              <TabPane tab="Mon compte" key="account">
                <TabAccount/>
              </TabPane>
              <TabPane tab="Mes coordonnées" key="coordinates">
                <TabCoordinates/>
              </TabPane>
              <TabPane tab="Mes factures" key="orders">
                <TabOrders/>
              </TabPane>
              <TabPane tab="Mes options" key="options">
                <TabOptions/>
              </TabPane>
              <TabPane tab="Mon compte prépayé" key="payments">
                <TabPayments/>
              </TabPane>
            </Tabs>

            <p>
              <strong>Protection des données à caractère personnel (RGPD)</strong><br/>
              Les informations recueillies font l'objet d'un traitement informatique destiné à la gestion des comptes utilisateurs. Le destinataire des données est l'entreprise PB IT (RCS 850 460 858).<br/>
              Conformément à la loi "informatique et libertés" du 6 janvier 1978 modifiée en 2004, vous bénéficiez d'un droit d'accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en adressant un simple mail à support@recouvrup.fr.<br/>
              Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.
              <br/>
              <a href={mentionsLegales.url} target='_blank'>Consulter notre politique de protection des données personnelles</a>
            </p>
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    documents: state.app.documents
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    finalizeOrder : orderActions.reqFinalizeOrder
  }
)(CustomerProfile));