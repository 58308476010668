import React, { Component } from "react";
import styled from 'styled-components';
import connect from "react-redux/es/connect/connect";
import {Anchor, Button} from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import homeNavbarActions from '../../../Redux/homeNavbar/actions';
import modalsActions from "../../../Redux/modals/actions";
import _ from "lodash";

const { Link } = Anchor;


class BurgerMenu extends Component{

  render(){

    if(!this.props.documents) return <></>

    const charte = _.find(this.props.documents, {name:'charte_bbp'});


    const Menu = styled.div`
      width: 100%;
      background-color: rgba(255,255,255,0.9);
      height: 100vh;
      position: absolute;
      top: 0px;
      text-align: center;
      padding-top: 100px;
      z-index: 1000;
      
      
      ul {
        list-style: none;
        padding: 0;
      }
      
      li {
        margin: 40px 0px;
      }
      
      li a {
        color: black;
        font-size: 22px;
      }
      
      #close-burger {
        position: absolute;
        right: 22px; 
        top: 100px;
      }
      
    `;

    return (
      <Menu style={{display: (this.props.burgerVisible) ? 'block' : 'none'}}>
        <CloseCircleOutlined id='close-burger' onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}/>
        <Anchor>
        <ul>
          <li onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}><Link href="/#bloc-accompagne" title='NOTRE CABINET' /></li>
          <li onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}><Link href="/#bloc-solution" title='SOLUTIONS'/></li>
          <li>
            <a target="_blank" rel="noreferrer" href={charte.url}>
              LA CHARTE BBP®
            </a>
          </li>
          <li onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}><Link href="/#bloc-tarif" title='TARIFS'/></li>
          <li onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}><a target="_blank" rel="noreferrer" href="https://recouvrup.zendesk.com/hc/fr">CENTRE D'AIDE</a></li>
          <li onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}><Link className="debtor-btn" href="/debiteur" title='DÉBITEUR'/></li>
          <li><Button type='primary' onClick={() => this.props.showModal('homeLogin')}>SE CONNECTER</Button></li>
          <li><Button type='ghost' onClick={() => this.props.showModal('homeSignup')}>INSCRIPTION</Button></li>
        </ul>
        </Anchor>
      </Menu>
    );
  }
}

export default connect(
  state => ({
    documents: state.app.documents,
    burgerVisible: state.homeNavbar.burgerVisible
  }),
  {
    toggleBurger: homeNavbarActions.toggleBurger,
    showModal: modalsActions.showModal
  }
)(BurgerMenu);