export default  {
  'letter.created': 'Créé',
  'letter.accepted': 'Accepté',
  'letter.filing_proof': 'Preuve de dépôt reçue',
  'letter.sent': 'Envoyé',
  'letter.error': 'Erreur',
  'letter.in_transit': 'En transit',
  'letter.waiting_to_be_withdrawn': 'En attente de retrait',
  'letter.distributed': 'Distribué',
  'letter.returned_to_sender': 'Retourné',
  'letter.wrong_address': 'Adresse incorrect',
  'letter.delivery_proof': 'Accusé reçu',
  'letter.canceled': 'Annulé'
};