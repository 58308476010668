import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Row, Col, Form, Spin, Button, Tooltip} from 'antd';
import NumberFormat from "react-number-format";
import connect from "react-redux/es/connect/connect";
import {FormInput} from "../../../helpers/forms";
import {QuestionCircleOutlined} from "@ant-design/icons";
import orderActions from '../../../Redux/order/actions';

class TabPayments extends Component{

  onFinish = (values) => {
    console.log(values);
    this.props.reqCreateCreditOrder(values.prepaidAmount);
  };

  render(){
    const { user } = this.props;

    if(!user) return (<></>);

    return (
      <Spin spinning={!user}>
        <Form onFinish={this.onFinish}>
          <div className='grey-block'>
            <h3>Mon compte prépayé</h3>
            <Row gutter={16}>
              <Col span={24} style={{paddingLeft: 20}}>
                <p>
                  Le compte prépayé est un portefeuille virtuel, à créditer avec votre CB, que vous pouvez ensuite utiliser pour régler plus rapidement les prestations de Recouvr'Up
                </p>
              </Col>
              <Col span={24} style={{paddingLeft: 10}}>
                <div style={{border: 'solid lightgrey 2px', display: 'inline-block', padding: '10px 20px', borderRadius: 5, margin: '20px 10px 30px 10px', background: 'white'}}>
                  <strong>Solde actuel : </strong>
                  <NumberFormat
                    displayType={'text'}
                    value={user.prepaidAmount ? user.prepaidAmount : 0}
                    decimalSeparator={','}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator={' '}
                    suffix={'€'}
                  />
                </div>
              </Col>
              <Col span={6} style={{paddingLeft: 20}}>
                <FormInput type='currency' name={'prepaidAmount'} placeholder={'Montant HT à créditer'} label={(<strong>Créditer mon compte <Tooltip placement="top" title={`Le montant renseigné est le montant hors taxe sur lequel sera appliqué la TVA en vigueur`}><QuestionCircleOutlined/></Tooltip></strong>)}/>
              </Col>
              <Col span={6} style={{paddingTop: 26}}>
                <Button loading={this.props.isLoading} type='primary' htmlType={'submit'}>Créditer</Button>
              </Col>

            </Row>
          </div>
        </Form>
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    isLoading : state.order.isLoading
  }),
  {
    reqCreateCreditOrder: orderActions.reqCreateCreditOrder
  }
)(TabPayments));