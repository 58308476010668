import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding: 25px 40px 20px 150px;
  
  h3{
    font-size: 1.7em;
    color: ${colors.primaryGreen};
    font-weight: bold;
  }
  
  .ant-tabs-tab-btn{
    font-size: 17px;
  }

`;

export default StyleWrapper;