import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import { colors } from '../../../helpers/appColors';


class VideoModal extends Component {

  handleCancel = () => {
    this.props.hideModal('homeVideo');
  };

  render(){

    const style = {
      modal: {
        textAlign: 'center',
        width: '50%'
      },
      h2: {
        borderBottom: `solid 1px ${colors.primaryGreen}`,
        fontFamily: 'helvetica neue',
        fontWeight: 200,
        fontSize: 26,
        color: '#4b5258 !important',
        paddingBottom: 10
      },
      em: {
        fontSize: 12,
        marginBottom: 10,
        display: 'block'
      },
      input: {
        textAlign: 'center',
        marginBottom: 20,
        marginTop: 5
      }
    };

    return (
      <Modal
        title={`Recouvr'Up en 50 secondes`}
        visible={this.props.homeVideoVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >

        <iframe width="100%" height="315" src="https://www.youtube.com/embed/wW38wYC9Pak" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>

      </Modal>
    )
  }
}

export default connect(
  state => ({
    homeVideoVisible: state.modals.homeVideoVisible
  }),
  {
    hideModal: modalsActions.hideModal
  }
)(VideoModal);