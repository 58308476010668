const actions = {
  FETCH_USER: "FETCH_USER",
  UPDATE_USER: "UPDATE_USER",
  USERINFO_IS_LOADING: "USERINFO_IS_LOADING",
  REGISTER_USER: "REGISTER_USER",
  REGISTER_IS_LOADING: "REGISTER_IS_LOADING",
  CONFIRM_USER: "CONFIRM_USER",
  LOGOUT: "LOGOUT",
  LOG_USER: "LOG_USER",
  REQUEST_INITIAL_UPDATE_USER: "REQUEST_INITIAL_UPDATE_USER",
  REQUEST_UPDATE_USER: "REQUEST_UPDATE_USER",
  REQUEST_LOGIN: "REQUEST_LOGIN",
  REQ_FETCH_COMPANY_INFO: "REQ_FETCH_COMPANY_INFO",
  UPDATE_COMPANY_INFO: "UPDATE_COMPANY_INFO",
  REQUEST_RECOVER_PWD: "REQUEST_RECOVER_PWD",
  REQUEST_UPDATE_OPTIONS: "REQUEST_UPDATE_OPTIONS",

  fetchUser: () => ({
    type: actions.FETCH_USER,
  }),

  updateUser: data => ({
    type: actions.UPDATE_USER,
    payload: data
  }),

  toggleUserLoader: isLoading => ({
    type: actions.USERINFO_IS_LOADING,
    payload: isLoading
  }),

  toggleRegisterLoader: isLoading => ({
    type: actions.REGISTER_IS_LOADING,
    payload: isLoading
  }),

  registerUser: payload => ({
    type: actions.REGISTER_USER,
    payload: payload
  }),

  confirmUser: (hash) => ({
    type: actions.CONFIRM_USER,
    payload: {hash}
  }),

  logUser: (token) => ({
    type: actions.LOG_USER,
    payload: {token}
  }),

  logout: () => ({
    type: actions.LOGOUT
  }),

  requestInitialUpdateUser: data => ({
    type: actions.REQUEST_INITIAL_UPDATE_USER,
    payload: data
  }),

  requestUpdateUser: data => ({
    type: actions.REQUEST_UPDATE_USER,
    payload: data
  }),

  requestLogin: data => ({
    type: actions.REQUEST_LOGIN,
    payload: data
  }),

  requestRecoverPwd: data => ({
    type: actions.REQUEST_RECOVER_PWD,
    payload: data
  }),

  reqFetchCompanyInfo: siren => ({
    type: actions.REQ_FETCH_COMPANY_INFO,
    payload: siren
  }),

  updateCompanyInfo: companyInfo => ({
    type: actions.UPDATE_COMPANY_INFO,
    payload: companyInfo
  }),

  reqUpdateOptions: data => ({
    type: actions.REQUEST_UPDATE_OPTIONS,
    payload: {data}
  })

};

export default actions;
