const actions = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
  HIDE_ALL_MODAL: "HIDE_ALL_MODAL",
  SHOW_CUSTOM_MODAL: 'SHOW_CUSTOM_MODAL',

  showModal: modalName => ({
    type: actions.SHOW_MODAL,
    payload: modalName
  }),

  showCustomModal: (title, text) => ({
    type: actions.SHOW_CUSTOM_MODAL,
    payload: { title, text }
  }),

  hideModal: modalName => ({
    type: actions.HIDE_MODAL,
    payload: modalName
  }),

  hideAllModal: () => ({
    type: actions.HIDE_ALL_MODAL,
  })

};

export default actions;
