import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button, Form } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import usersActions from '../../../Redux/user/actions';
import { colors } from '../../../helpers/appColors';
import { FormInput } from '../../../helpers/forms';


class RecoverPwd extends Component {

  handleCancel = () => {
    this.props.hideModal('homeRecoverPwd');
  };

  onFinish = (values) => {
    this.props.requestRecoverPwd(values);
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
      h2: {
        borderBottom: `solid 1px ${colors.primaryGreen}`,
        fontFamily: 'helvetica neue',
        fontWeight: 200,
        fontSize: 26,
        color: '#4b5258 !important',
        paddingBottom: 10
      },
      em: {
        fontSize: 12,
        marginBottom: 10,
        display: 'block'
      },
      input: {
        textAlign: 'center',
        marginTop: 5
      }
    };

    return (
      <Modal
        title={`Mot de passe perdu`}
        visible={this.props.homeRecoverPwdVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >
        <h2 style={style.h2}>Veuillez saisir votre adresse email</h2>
        <Form onFinish={this.onFinish}>
          <FormInput
            name={'email'}
            rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
            label={'Mon adresse email :'}
            placeholder={'Email'}
            style={style.input}
          />
          <br/>
          <br/>
          <Button type='primary' htmlType={'submit'}>Renvoyez-moi un nouveau mot de passe</Button>
        </Form>

      </Modal>
    )
  }
}

export default connect(
  state => ({
    homeRecoverPwdVisible: state.modals.homeRecoverPwdVisible
  }),
  {
    hideModal: modalsActions.hideModal,
    requestRecoverPwd: usersActions.requestRecoverPwd
  }
)(RecoverPwd);