import styled from "styled-components";

import { colors } from "../../../helpers/appColors";
import image from "../../../resources/images/image-espace-debiteur.jpg";

const StyleWrapper = styled.div`

    margin-top: 100px;

    .espace-debiteur{
        display: flex;
        width: 100%;
        min-height: 100vh;
    }

    .espace-debiteur__img{
        width: 45%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(${image})
    }

    .espace-debiteur__content{
        width: 55%;
    }



    .img-wrapper{
       
    }
    .asked-block{
        padding: 30px 40px;
    }
    .asked-block__title{
        text-align: center;
    }

    .asked-block__title h1 {
        font-size: 30px;
        color : ${colors.primaryRed}
    }

    .asked-block__title h2,.asked-block__subtitle h2{
        font-size: 28px;
    }
    .asked-block__wrapper_list{
        width: 85%;
        margin: 30px auto;
    }
    .asked-block__wrapper_list ul{
        list-style: none;
        margin-left: 25px;
        font-size: 16px;
        font-weight: 500;
        color: ${colors.black}!important;
    }
    .icon-items{
        color:${colors.primaryGreen};
        margin-right: 15px;
    }
    .asked-block__subtitle{
        text-align: center;
        margin-bottom: 30px;
    }
    .asked-block__form {
        text-align:center;
        padding: 20px 50px;
    }
    .asked-block__form h3{
        font-size: 25px;
    }

    .asked-block__form h3 span{
        border-bottom: 1px solid;
        border-color: ${colors.textLightGrey};
    }
    .separator {
        margin: 30px 0px 0px 0px;
        height: 1px;
        width: 15px;
        background-color: ${colors.textLightGrey};
    }

    .asked-block__form_input{
        border-radius: 0px;
        border: 2px solid;
        height: 30px;
    }
    .asked-block__form_items{
        display: flex;
       justify-content : center;
    }

    .asked-block__form_item{
        padding: 10px;
    }

    .asked-block__form_btn{
        margin-top: 30px;
    }

    .asked-block__form_btn_btn{
        color: ${colors.primaryGreen};
        border-radius: 0px;
        font-weight: bold;
        padding : 0px 20px;
        border: 2px solid;
        border-color:  ${colors.primaryGreen};

    }
    .reference-block__data{
        width: 100%;
        text-align: center;
        min-height: 50px;
        background-color : ${colors.backegroundGrey};
        padding: 20px 20px 0 20px;
    }
    .reference-block__data--green-text{
        color: ${colors.primaryGreen};
    }
    .reference-block__options{
        width: 100%;
        margin-top: 30px;
    }

    .reference-block-pay_with_bridge {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    
      .reference-block-pay_with_bridge p {
        font-weight: bold;
        margin: 5px 15px;
      }
      .reference-block-pay_with_bridge img {
        width: 80px;
        height: 21px;
        margin: 8px 0;
      }

    .bridge-text{
        font-style : italic;
        color : black;
        font-size : 13px;
        padding : 11px 0;
    }  

    .reference-block__options_tittle{
        text-align: center;
    }
    .reference-block__options_items{
        width: 70%;
        margin: 0px auto;
    }
    .reference-block__options_item{
        margin: 30px 0px;
    }
    .reference-block__options_item--label{
        font-size: 16px;
        margin-left: 15px;
    }
    .reference-block__options_item--switch{
        margin-left: 35px;
        margin-top : 10px;
    }
    .reference-block__options_btn{
        text-align: center;
    }
    .red-text{
        color: ${colors.primaryRed};
    }
    .text--black{
        color: ${colors.primaryBlack}!important;
    }
    .text--center{
        text-align: center;
    }
    .raison-block-form{
        width: 75%;
    }
    .raison-block-mail{
        width: 70%;
        display: flex;
    }
    .statut-mail__input{
        border-color : ${colors.primaryBlack};
        border-radius : 0px;
    }

    .raison-block-file{
        width: 70%;
        display: flex;
    }
    .raison-block-file__label{
        text-align: right;
        width : 35%;
    }
    .raison-block-file__input{
        width : 80%;
    }

    .raison-block-mail__label{
        text-align: right;
        width : 35%;
    }
    .raison-block-mail__input{
       
        width : 80%;
    }
    .text-remark 
    {
        font-size: 12px;
    }
    .raison-block-file__upload-btn{
        color : ${colors.primaryBlack};
        border-radius : 0px;
        border-color : ${colors.primaryBlack};
    }
    .name-file{
        font-size: 12px;
        color : ${colors.primaryBlack};
        margin-right : 10px;
    }
    .remove-btn-file{
        border: 0px solid;
        color : ${colors.primaryRed};
    }

    .btn-cancel{
        position: absolute;
        right: 10px;
        top: 105px;
        border-color : ${colors.primaryBlack};
        color : ${colors.primaryBlack};
        background-color : transparent;
    }

    @media only screen and (max-width: 768px) {
        .espace-debiteur{
            display: block;
            width: 100%;
            height: auto;
        }

        .espace-debiteur__img{
            width: 100%;
            height: 600px;
        }
    
        .espace-debiteur__content{
            width: 100%;
        }
        .asked-block{
            padding: 30px 5px;
        }
        .asked-block__form {
            text-align:center;
            padding: 20px 20px;
        }
    }

`;

export default StyleWrapper;