import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { LinkedinFilled, TwitterSquareFilled } from '@ant-design/icons';
import connect from "react-redux/es/connect/connect";
import AOS from 'aos';
import _ from 'lodash';
import 'aos/dist/aos.css';
import HomeNavbar from './HomeNavbar/HomeNavbar';
import HomeLoginModal from './Modals/Signup';
import HomeSignupModal from './Modals/Login';
import HomeVideoModal from './Modals/Video';
import StyleWrapper from './StyleWrapper';
import { PlayCircleOutlined } from '@ant-design/icons';
import iconLectureVert from '../../resources/images/landing_page/icone/icone-lecture-vert.jpg';
import autreTrio from '../../resources/images/landing_page/autre/autre-trio.png';
import iconeLectureRouge from '../../resources/images/landing_page/icone/icone-lecture-rouge.jpg';
import autrePenalite from '../../resources/images/landing_page/autre/autre-penalites.png';
import iconeLectureGris from '../../resources/images/landing_page/icone/icone-lecture-gris.jpg';
import autreRelance from '../../resources/images/landing_page/autre/autre-relances.png'
import iconeLectureNoir from '../../resources/images/landing_page/icone/icone-lecture-noir.jpg'
import autrePaiement from '../../resources/images/landing_page/autre/autre-paiement.png';
import logoDirectRecouvrement from '../../resources/images/landing_page/logo/logo-direct-recouvrement.jpg';
import logoFigec from '../../resources/images/landing_page/logo/logo-figec.jpg';
import iconPaper from '../../resources/images/landing_page/icone/icone-paper.jpg';
import iconCross from '../../resources/images/landing_page/icone/icone-cross.jpg';
import iconValid from '../../resources/images/landing_page/icone/icone-valide.jpg';
import iconMalette from '../../resources/images/landing_page/icone/icone-malette.jpg';
import logoEchos from '../../resources/images/general/logo_echos.jpg';
import logoParisien from '../../resources/images/general/logo_parisien.jpg';
import logoOptionFinance from '../../resources/images/general/logo_option_finance.jpg';
import logoFranceBleu from '../../resources/images/general/francebleu.jpg';
import logoEntreprendreTv from '../../resources/images/general/entreprendretv.jpg';
import logoEconomieMatin from '../../resources/images/general/economiematin.jpg';
import logoFinyear from '../../resources/images/general/finyear.jpg';
import logoVinci from '../../resources/images/general/vinciautoroute.jpg';
import logoFrenchWeb from '../../resources/images/general/frenchweb.jpg';
import logoFrenchTech from '../../resources/images/general/french-tech.png';
import logoTribunalDigital from '../../resources/images/general/tribunal_digital.png';
import logoReseauEntreprendre from '../../resources/images/general/reseau_entreprendre.png';
import logoCci from '../../resources/images/general/cci-store.png';
import modalsActions from "../../Redux/modals/actions";
import appActions from "../../Redux/app/actions";
import FooterNavbar from './Footer/FooterNavbar';

AOS.init();

class HomePage extends Component{

  render(){

    return (
      <StyleWrapper>
        <HomeNavbar/>
        <HomeLoginModal/>
        <HomeSignupModal/>
        <HomeVideoModal/>

        <Row gutter={16} id='hero-block'>
          <Col span={24}>
            <PlayCircleOutlined id='play-button' onClick={() => {this.props.showModal('homeVideo')}}/>

            <h1 className="title_home">SOURIEZ, VOUS ÊTES PAYÉ !</h1>
            <h2 className="">Plateforme web de services en <br/> recouvrement de créances</h2>

            <Button type='primary' size='large' style={{marginBottom: 50, height: 50}} onClick={() => {this.props.showModal('homeSignup')}}>
              INSCRIPTION GRATUITE
            </Button>
          </Col>
        </Row>

        <Row gutter={16} id="bloc-cabinet" className='linear-grey-gradient'>
          <Col span={24}>
            <Row className="landing-page-content-mb">
              <Col span={24}>
                <h2>LA RELANCE DES IMPAYÉS, UN ENJEU FONDAMENTAL...</h2>
              </Col>
            </Row>

            <Row className="row">
              <Col lg={6} md={12} span={24} className="col-lg-3 text-center" data-aos="fade-up">
                <div>
                  <i className="lnil lnil-graph-alt-4 cabinet-icon"/>
                  <h3>56 MILLIARDS D'€</h3>
                  <p>de créances impayées par an estimées en France</p>
                </div>
              </Col>
              <Col lg={6} md={12} span={24} className="col-lg-3 text-center" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <i className="lnil lnil-pie-chart-alt cabinet-icon"/>
                  <h3 className="c-font-uppercase c-font-bold statTitle">36,2%</h3>
                  <p>de bons payeurs (seulement) en France</p>
                </div>
              </Col>
              <Col lg={6} md={12} span={24} className="col-lg-3 text-center" data-aos="fade-up" data-aos-delay="200">
                <div>
                  <i className="lnil lnil-layers-alt-1 cabinet-icon"/>
                  <h3>16.000</h3>
                  <p>dépôts de bilan chaque année en raison de paiements non respectés</p>
                </div>
              </Col>
              <Col lg={6} md={12} span={24} className="col-lg-3 text-center" data-aos="fade-up" data-aos-delay="300">
                <div>
                  <i className="lnil lnil-alarm-clock-alt cabinet-icon"/>
                  <h3>13,2 JOURS</h3>
                  <p>le retard de paiement moyen en France</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16} className="linear-grey-gradient" id="bloc-solution">
          <Col span={24} className="col">
            <div className="landing-page-content-mb">
              <h2>SOURIEZ, VOUS AVEZ DES SOLUTIONS !</h2>
              <p className="subtitle">
                De l'émission de la facture à la procédure judiciaire, <br/>
                Recouvr'Up accélère les délais de paiement et recouvre les impayés.
              </p>
            </div>
            <Row gutter={16}>
              <Col md={6} span={24} data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="0">
                <img alt='' className="lecture" src={iconLectureVert}/><br/>
                <p className="text-lecture">PROTÉGEZ <br/>
                  VOS CRÉANCES</p>
                <img alt='' className="lettre" src={autreTrio} />
              </Col>
              <Col md={6} span={24} data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="100">
                <img alt='' className="lecture" src={iconeLectureRouge}/><br/>
                <p className="text-lecture">RÉCLAMEZ <br/>
                  DES PÉNALITÉS<br/>
                  DE RETARD</p>
                <img alt='' className="lettre" src={autrePenalite}/>
              </Col>
              <Col md={6} span={24} data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="200">
                <img alt='' className="lecture" src={iconeLectureGris}/><br/>
                <p className="text-lecture" >ADRESSEZ <br/>
                  LES RELANCES <br/>
                  D'UN CABINET</p>
                <img alt='' className="lettre" src={autreRelance}/>
              </Col>
              <Col md={6} span={24} data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                <img alt='' className="lecture" src={iconeLectureNoir}/><br/>
                <p className="text-lecture">SAISISSEZ<br/>LE
                  TRIBUNAL<br/>DE COMMERCE</p>
                <img alt='' className="lettre" src={autrePaiement} />
              </Col>
            </Row>

            <Row gutter={16} style={{margin: 0}} data-aos="fade-right" data-aos-anchor-placement="center-bottom">
              <Col span={24}>
                <div id="crumbs" className="landing-page-content-mb">
                  <ul>
                    <li><span>
                      <p>JE PROTÈGE</p>
                      mes créances contre les mauvais
                      payeurs, à l’aide des stickers BBP.
                    </span></li>
                    <li><span>
                      <p> JE RÉCLAME </p>
                      à mon client des pénalités
                      de retard avec des documents
                      professionnels
                      (justificatif comptable,
                      couriers de relance)

                    </span></li>
                    <li><span>
                      <p> J'ADRESSE </p>
                      à mon client
                      des sommations du cabinet
                      Direct Recouvrement.
                    </span></li>
                    <li><span>
                      <p>JE SAISIS</p>
                      le tribunal et fais signifier
                      l'ordonnance par un
                      huissier de justice.
                    </span></li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{marginTop: 35}}>
              <Col span={24}>
                <div className="text-center">
                  <Button type='primary' size='large' style={{height: 50}} onClick={() => {this.props.showModal('homeSignup')}}>
                    JE CRÉE MON COMPTE
                  </Button>
                </div>
                <h3 style={{marginTop: 20, textTransform: 'uppercase'}} >
                  et j’effectue mes 1ères relances (gratuitement et sans engagement)
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16} className="linear-grey-gradient" id="bloc-accompagne">
          <Col span={24} style={{padding: '0px 20px'}}>
            <div className="landing-page-content-mb">
              <h2>SOURIEZ, VOUS ÊTES BIEN ACCOMPAGNÉ !</h2>
              <p className="subtitle">
                Recouvr'Up est une plateforme Web fournissant les services dématérialisés du cabinet Direct
                Recouvrement.
              </p>
            </div>
            <Row gutter={16} style={{marginTop: 70, marginLeft: 0, marginRight: 0}}>
              <Col span={12} style={{textAlign: 'center'}}>
                <a rel="noreferrer" href="https://direct-recouvrement.fr" target="_blank" title="Direct Recouvrement">
                  <img alt='' className="img-fluid logo-direct-recouvrement"
                       data-aos="fade-right" data-aos-anchor-placement="center-bottom"
                       src={logoDirectRecouvrement} style={{maxWidth: 300, width: '100%'}} />
                </a>
              </Col>
              <Col span={12} style={{textAlign: 'center'}}>
                <a rel="noreferrer" href="https://www.figec.com/" target="_blank" title="FIGEC">
                  <img alt='' className="img-fluid logo-figec"
                       data-aos="fade-right" data-aos-anchor-placement="center-bottom"
                       src={logoFigec} style={{maxWidth: 300, width: '100%'}}/>
                </a>
              </Col>

              <p style={{maxWidth: 600, margin: '50px auto'}}>
                Membre de la FIGEC (Fédération Nationale de l'Information d'Entreprise, de la Gestion de Créances
                et de l'Enquête Civile),
                nous nous engageons au respect de la charte déontologique édictée par notre
                Fédération.
              </p>

            </Row>

            <div style={{padding : '4%', marginLeft: '10%', marginRight: '10%', marginTop: 20, background:'#EDEEEF', borderRadius: '10px'}}>
              <Row className="row">
                <Col lg={6} span={24} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="0">
                  <img alt='' className="icon" src={iconPaper}/>
                  <div className="icone-description">Transparence et transactions.</div>
                </Col>
                <Col lg={6} span={24} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="100">
                  <img alt='' className="icon" src={iconCross}/>
                  <div className="icone-description">Protection des intérêts des bénéficiaires.</div>
                </Col>
                <Col lg={6} span={24} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="200">
                  <img alt='' className="icon" src={iconValid}/>
                  <div className="icone-description">Respect des débiteurs, des relations contractuelles et des
                    obligations
                    légales et règlementaire
                  </div>
                </Col>
                <Col lg={6} span={24} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                  <img alt='' className="icon" src={iconMalette}/>
                  <div className="icone-description">Professionnalisme des acteurs</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="linear-grey-gradient" id="bloc-tarif">
          <Col span={24} className="col-12">
            <div className="landing-page-content-mb">
              <h2>
                SOURIEZ, ENTRE NOUS TOUT EST CLAIR.
              </h2>
            </div>
            <Row gutter={16} style={{margin: 0}}>
              <Col span={24} style={{padding: 0}}>
                <div style={{width: '100%', marginTop: 50, marginBottom: 70}} className="elfsight-app-e34b1ed2-d434-43f9-8a3a-2a8ee29321c6"/>
              </Col>
              <Col span={24}>
                <div className="text-center">
                  <Button type='primary' size='large' style={{height: 50}} onClick={() => {this.props.showModal('homeSignup')}}>
                    J'OUVRE UN COMPTE FREE
                  </Button>
                </div>
                <h3 style={{marginTop: 20, textTransform: 'uppercase'}} >
                  et je teste gratuitement, sans engagement !
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16} id="bloc-presse" className='linear-grey-gradient'>
          <Col span={24}>
            <div>
              <h2>
                ILS PARLENT DE NOUS...
              </h2>
            </div>
            <Row gutter={16}>
              <Col xl={16} span={24}>
                <Row gutter={16} >
                  <Col sm={10} span={24} >
                    <a
                      href="http://business.lesechos.fr/directions-financieres/financement-et-operations/gestion-de-tresorerie/021288515351-delais-de-paiement-une-appli-a-la-rescousse-202489.php"
                      target="_blank" rel="noreferrer">
                      <img alt='' src={logoEchos} className="logo-big"/>
                    </a>
                  </Col>
                  <Col sm={14} span={24} className="col-sm-7">
                    <div className="text6">
                      "Un outil numérique pour aider les entreprises à calculer le montant de ces pénalités"
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col sm={10} span={24} >
                    <a
                      href="http://www.leparisien.fr/ableiges-95450/pontoise-une-application-pour-aider-les-pme-a-faire-payer-leurs-debiteurs-21-09-2015-5113465.php"
                      target="_blank" rel="noreferrer">
                      <img alt='' src={logoParisien} className="logo-big"/>
                    </a>
                  </Col>
                  <Col sm={14} span={24} >
                    <div className="text6">
                      "Calculer sûrement et efficacement les intérêts réclamés et justifier le calcul, le tout
                      sans
                      perdre de temps"
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col sm={10} span={24}>
                    <a
                      href="http://www.optionfinance.fr/actualites/actualites-generales/detail/une-application-internet-pour-faciliter-le-recouvrement-de-creances.html"
                      target="_blank" rel="noreferrer">
                      <img alt='' src={logoOptionFinance} className="logo-big"/>
                    </a>
                  </Col>
                  <Col sm={14} span={24} >
                    <div className="text6">
                      "Une application internet pour faciliter le recouvrement des créances"
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xl={8} span={24}>
                <Row gutter={16}>
                  <Col span={12} >
                    <a href="https://www.youtube.com/watch?v=j09VsK0djyA"
                       target="_blank" rel="noreferrer">
                      <img alt='' src={logoFranceBleu} className="logo-france-bleu"/>
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href="https://business.lesechos.fr/entrepreneurs/gestion-finance/factures-impayees-les-services-en-ligne-se-multiplient-317588.php#xtor=CS1-26"
                      target="_blank" rel="noreferrer">
                      <img alt='' src={logoEntreprendreTv} className="logo-les-entrepreneurs"/>
                    </a>
                  </Col>
                  <Col span={12}>
                    <a href="http://www.economiematin.fr/news-retard-paiement-entreprise-defaillance-france"
                       target="_blank" rel="noreferrer">
                      <img alt='' src={logoEconomieMatin} className="logo-economie-matin"/>
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href="http://www.finyear.com/Nouveau-un-service-en-ligne-qui-aide-les-entreprises-a-recuperer-leurs-impayes-sans-se-ruiner_a36834.html"
                      target="_blank" rel="noreferrer">
                      <img alt='' src={logoFinyear} className="logo-finyear"/>
                    </a>
                  </Col>
                  <Col span={12} >
                    <a href="https://www.youtube.com/watch?v=1WvithewNxU"
                       target="_blank" rel="noreferrer">
                      <img alt='' src={logoVinci} className="logo-vinci"/>
                    </a>
                  </Col>
                  <Col span={12} >
                    <a href="http://www.frenchweb.fr/comment-calculer-les-penalites-en-cas-de-retard-de-paiement/206367"
                       target="_blank" rel="noreferrer">
                      <img alt='' src={logoFrenchWeb} className="logo-frenchweb"/>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{marginTop:0, marginBottom: 100}} className="elfsight-app-64e50152-8a0e-4a01-b2fe-91726da75ff2"/>
          </Col>

          <Col span={24} >
            <div>
              <h2>
                ILS NOUS ACCOMPAGNENT
              </h2>
            </div>
            <Row gutter={16} id='bloc-followers'>
              <Col span={24}>
                <a rel="noreferrer" href="https://lespepitestech.com/startup-de-la-french-tech/recouvrup" title="" target="_blank">
                  <img alt='' src={logoFrenchTech} />
                </a>
                <a rel="noreferrer" href="https://www.tribunaldigital.fr/" title="" target="_blank">
                  <img alt='' src={logoTribunalDigital}/>
                </a>
                <a rel="noreferrer" href="https://www.reseau-entreprendre.org/val-d-oise/" title="" target="_blank">
                  <img alt='' src={logoReseauEntreprendre}/>
                </a>
                <a rel="noreferrer" href="https://www.ccistore.fr/produit/2630903/recouvr-up" title="" target="_blank">
                  <img alt='' src={logoCci}/>
                </a>
              </Col>
            </Row>
          </Col>

        </Row>

        <Row gutter={16} className='linear-grey-gradient' id='bloc-contact'>
          <Col span={24}>
            <div >
              <h2>
                NOUS CONTACTER :
              </h2>
            </div>
            <Row gutter={16}>
              <Col lg={6} span={20} push={2} style={{textAlign: 'left'}}>
                <br/>
                <h3 style={{fontWeight: 'bold', fontSize: 25}}>Direct Recouvrement</h3>
                <p style={{fontSize: 25}}>
                  <strong>20, rue Lavoisier, 95300 Pontoise</strong>
                  <br/>01.84.15.42.52
                  <br/>Info@direct-recouvrement.fr
                  <br/><br/>Restons connectés :
                </p>
                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/direct-recouvrement?trk=extra_biz_viewers_viewed" title="">
                  <LinkedinFilled className='social-icon'/>
                </a>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/DirRecouvrement" title="">
                  <TwitterSquareFilled className='social-icon'/>
                </a>
                <a rel="noreferrer" target="_blank" href="http://www.viadeo.com/fr/company/direct-recouvrement" title="">
                  <div className="social-icon-square">Viadéo</div>
                </a>
                <a rel="noreferrer" target="_blank" href="http://www.lejournaldurecouvrement.fr/" title="">
                  <div className="social-icon-square">Blog</div>
                </a>
                <br/>
                <br/>
              </Col>

              <Col lg={14} span={20} push={2}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d669924.2714838192!2d2.1205974!3d49.0126456!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe31c1a58413adeca!2sDIRECT+RECOUVREMENT!5e0!3m2!1sfr!2sfr!4v1418202831818"
                  frameBorder="0" style={{border: 0, width: '100%', height: 400}}/>
                <br/>
                <small><a
                  href="https://www.google.fr/maps/place/DIRECT+RECOUVREMENT/@49.0126456,2.1205974,9z/data=!4m3!3m2!1s0x0:0xe31c1a58413adeca!4b1"
                  style={{color: '#0000FF', textAlign: 'left'}}>Agrandir le plan</a></small>
              </Col>
            </Row>
          </Col>
        </Row>
        <FooterNavbar/>
      </StyleWrapper>
    )
  }

}

export default withRouter(connect(
  state => ({
  }),
  {
    showModal: modalsActions.showModal
  }
)(HomePage));