import axios from "axios";
import apiConfig from "../config/api.config";
import { getToken, clearToken } from "../helpers/cookies";
import history from "../helpers/history";
import { store } from "../Redux/store";
import userActions from "../Redux/user/actions";
import globalDataActions from "../Redux/user/actions";

class ApiProvider {
  static axiosWithToken;
  static token;

  static setToken(token) {
    ApiProvider.token = token;
    ApiProvider.axiosWithToken.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    ApiProvider.axiosWithToken.defaults.headers.common["version"] = `${apiConfig.version}`;
  }

  static init() {
    ApiProvider.axiosWithToken = axios.create({
      baseURL: `${apiConfig.baseUrl}/api`
    });


    ApiProvider.axiosWithToken.interceptors.response.use(
      function(response) {
        // Do something with response data bro :)
        if(response.data.error && response.data.error === 'WRONG_TOKEN'){
          store.dispatch(userActions.logout());
          window.location.href = '/';
        }
        return response;
      },
      function(error) {
        if (error.response.data.error === "WRONG_TOKEN") {
          store.dispatch(userActions.logout());
          window.location.href = '/';
        }else if(error.response.data.error === 'ACCESS_DENIED') {
          history.replace('/');
        }
        throw error;
      }
    );
  }
}

ApiProvider.init();
const token = getToken();
if (token) {
  ApiProvider.setToken(token);
}

export default ApiProvider;
