import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';

import {Row, Col, Button, Divider, Table, Form} from 'antd';
import {FormSelect, FormUpload} from "../../../helpers/forms";
import adminActions from "../../../Redux/admin/actions";

class AdminLabel extends Component{

  state = {
    file: null
  };

  handleBeforeUploadedFile = file => {
    this.setState({ file });
    return false;
  };

  onFinish = (values) => {
    const {name} = values;
    this.props.addConfigDocument(name, this.state.file, 'label');
  };

  render(){

    const columns = [
      {
        title: `Libellé`,
        dataIndex: 'name',
        sorter: (a,b) => a.name < b.name ? -1:1,
        showSorterTooltip: false
      },
      {
        title: (
          <span>
            Actions
          </span>
        ),
        dataIndex: 'id',
        width: 100,
        render: (text, record, index) => (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              type='default'
              style={{
                backgroundColor: colors.secondaryBlue,
                borderColor: colors.secondaryBlue,
                color: 'white'
              }}
              href={record.url}
              target='_blank'
              size='small'
            >
              Télécharger
            </Button>
          </div>
        ),
      },
    ];

    let data = [];

    if(this.props.config.labelDocuments){
      this.props.config.labelDocuments.map((label, idx) => {
        data.push(label);
      });
    }

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center'}}>
          <Col span={18} className={'shadowed-block'}>

            <h3>Label Recouvr'Up</h3>

            <h4>Modifier un document</h4>

            <Form onFinish={this.onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormSelect
                    name={`name`}
                    placeholder={`Sélectionner un document à modifier`}
                    options={[
                      {text: `Charte Business Bonne Pratiques`, value: `charte_bbp`},
                      {text: `Sticker BBP Vert x1`, value: `sticker_bbp_green_x1`},
                      {text: `Sticker BBP Vert x24`, value: `sticker_bbp_green_x24`},
                      {text: `Sticker BBP Orange x1`, value: `sticker_bbp_orange_x1`},
                      {text: `Sticker BBP Orange x24`, value: `sticker_bbp_orange_x24`},
                      {text: `Sticker BBP Rouge x1`, value: `sticker_bbp_red_x1`},
                      {text: `Sticker BBP Rouge x24`, value: `sticker_bbp_red_x24`},
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <FormUpload
                    rules={[{required: true, message: `Ce champ est obligatoire`}]}
                    name={`file`}
                    valuePropName={`file`}
                    accept={`.pdf,.jpg,.jpeg,.png`}
                    action={'#'}
                    beforeUpload={this.handleBeforeUploadedFile}
                  />
                </Col>
                <Col span={5}>
                  <Button htmlType={'submit'} type={'default'} style={{background: colors.primaryBlue, borderColor: colors.primaryBlue, color: 'white', marginTop: 25}}>Sauvegarder</Button>
                </Col>
              </Row>
            </Form>

            <Divider/>

            <Table
              rowKey={'id'}
              size={'small'}
              bordered={true}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    config: state.admin.config
  }),
  {
    addConfigDocument: adminActions.reqAddDocument,
  }
)(AdminLabel));