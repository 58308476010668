import { remove } from "lodash";

const actions = {
  UPLOAD_PDF_REQUEST: "UPLOAD_PDF_REQUEST",
  UPLOAD_PDF_SUCCESS: "UPLOAD_PDF_SUCCESS",
  UPLOAD_PDF_ERROR: "UPLOAD_PDF_ERROR",
  REMOVE_PDF_REQUEST: "REMOVE_PDF_REQUEST",
  REMOVE_PDF_SUCCESS: "REMOVE_PDF_SUCCESS",
  REMOVE_PDF_ERROR: "REMOVE_PDF_ERROR",
  IS_IN_LOADING: "IS_IN_LOADING",
  REMOVE_FILE_REQUEST: "REMOVE_FILE_REQUEST",
  REMOVE_FILE_SUCCESS: "REMOVE_FILE_SUCCESS",

  uploadPDFRequest: (data) => ({
    type: actions.UPLOAD_PDF_REQUEST,
    payload: { data },
  }),

  uploadPDFSuccess: (pdfUrl) => ({
    type: actions.UPLOAD_PDF_SUCCESS,
    payload: { pdfUrl },
  }),

  uploadPDFError: (error) => ({
    type: actions.UPLOAD_PDF_ERROR,
    payload: { error },
  }),

  removePDFRequest: (data) => ({
    type: actions.REMOVE_PDF_REQUEST,
    payload: { data },
  }),

  removePDFSuccess: (isSuccess) => ({
    type: actions.REMOVE_PDF_SUCCESS,
    payload: isSuccess
  }),

  removePDFError: (error) => ({
    type: actions.REMOVE_PDF_ERROR,
    payload: { error },
  }),

  removeFileRequest: (path)=>({
    type: actions.REMOVE_FILE_REQUEST,
    payload : path
  }),

  removeFileSuccess: (isSuccess) =>({
    type: actions.REMOVE_FILE_SUCCESS,
    payload : isSuccess
  }),

  isInLoading: (isLoading) => ({
    type: actions.IS_IN_LOADING,
    payload: isLoading ,
  })
};

export default actions;
