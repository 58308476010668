import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {Col, Modal, Row} from 'antd';
import modalsActions from '../../../../Redux/modals/actions';
import {LinkedinFilled, TwitterSquareFilled} from "@ant-design/icons";


class ContactModal extends Component {

  handleCancel = () => {
    this.props.hideModal('customerContact');
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
    };

    const squareBtnStyle = {
      background: 'rgb(61, 178, 122)',
      height: 36,
      width: 36,
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      color: 'white',
      fontSize: 9,
      justifyContent: 'center',
      fontWeight: 'bold',
      paddingTop: 10,
      textAlign: 'center',
      margin: '0px 2px',
      borderRadius: 2
    }

    return (
      <Modal
        title={`NOUS CONTACTER`}
        visible={this.props.customerContactVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >
        <Row gutter={16}>
          <Col span={24} style={{textAlign: 'center'}}>
            <br/>
            <h3 style={{fontWeight: 'bold', fontSize: 25}}>DIRECT RECOUVREMENT</h3>
            <p style={{fontSize: 20}}>
              <strong>20, rue Lavoisier, 95300 Pontoise</strong>
              <br/>01.84.15.42.52
              <br/>Info@direct-recouvrement.fr
            </p>
            <p>Restons connectés :</p>
            <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/direct-recouvrement?trk=extra_biz_viewers_viewed" title="">
              <LinkedinFilled style={{fontSize: '40px', color: '#3db27a'}}/>
            </a>
            <a rel="noreferrer" target="_blank" href="https://twitter.com/DirRecouvrement" title="">
              <TwitterSquareFilled style={{fontSize: '40px', color: '#3db27a'}}/>
            </a>
            <a rel="noreferrer" target="_blank" style={squareBtnStyle} href="http://www.viadeo.com/fr/company/direct-recouvrement" title="">
              <div className="social-icon-square">Viadéo</div>
            </a>
            <a rel="noreferrer" target="_blank" style={squareBtnStyle} href="http://www.lejournaldurecouvrement.fr/" title="">
              <div className="social-icon-square">Blog</div>
            </a>
            <br/>
            <br/>
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    customerContactVisible: state.modals.customerContactVisible
  }),
  {
    hideModal: modalsActions.hideModal
  }
)(ContactModal);