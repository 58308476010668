import React, { Component } from "react";
import StyleWrapper from "./StyleWrapper";
import HomeNavbar from "../HomeNavbar/HomeNavbar";
import HomeSignupModal from "../Modals/Signup";
import HomeLoginModal from "../Modals/Login";
import FooterNavbar from "../Footer/FooterNavbar";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import { Button, Form, Input } from "antd";

import { CheckOutlined } from "@ant-design/icons";

import espaceDebiteurActions from "../../../Redux/espaceBebiteur/actions";

class EspaceDebiteur extends Component {
  formRef = React.createRef();

  onFinish = (values) => {
    const reference = `${values.reference1}-${values.reference2}-${values.reference3}`;
    this.props.fetchReminder(reference);
  };

  validateDigits = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Le champ doit contenir seulement des chiffres")
    );
  };

  validateLetters = (_, value) => {
    if (!value || /^[a-zA-Z]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Le champ ne doit contenir que des lettres")
    );
  };

  componentDidMount() {}

  render() {
    const { reminder, loading } = this.props.espaceDebiteur;

    return (
      <StyleWrapper>
        <HomeNavbar />
        <HomeLoginModal />
        <HomeSignupModal />
        <div className="espace-debiteur">
          <div className="espace-debiteur__img"></div>
          <div className="espace-debiteur__content">
            <div className="asked-block">
              <div className="asked-block__title">
                <h1>ESPACE DÉBITEUR</h1>
                <h2>Vous venez de recevoir une relance et vous souhaitez</h2>
              </div>
              <div className="asked-block__wrapper_list">
                <ul>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Telecharger les documents (justificatif comptable, factures)
                  </li>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Mettre à jour le
                    statut de la relance
                  </li>
                  <li>
                    {" "}
                    <CheckOutlined className="icon-items" /> Confirmer le
                    paiement
                  </li>
                </ul>
              </div>
              <div className="asked-block__subtitle">
                <h2>
                  {" "}
                  Renseignez votre référence et accédez au dossier
                </h2>
              </div>
              <div className="asked-block__form">
                <h3>
                  <span>REFERENCE</span>
                </h3>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                  <div className="asked-block__form_items">
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference1"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des chiffres",
                          },
                          {
                            validator: this.validateDigits,
                          },
                        ]}
                      >
                        <Input className="asked-block__form_input"  style={{ textTransform: 'uppercase', textAlign: 'center' }} />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="separator"></div>
                    </div>
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference2"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des chiffres",
                          },
                          {
                            validator: this.validateDigits,
                          },
                        ]}
                      >
                        <Input className="asked-block__form_input"  style={{ textTransform: 'uppercase', textAlign: 'center' }} />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="separator"></div>
                    </div>
                    <div className="asked-block__form_item">
                      <Form.Item
                        name={"reference3"}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir des lettres",
                          },
                          {
                            validator: this.validateLetters,
                          },
                        ]}
                      >
                        <Input
                          name="reference1"
                          className="asked-block__form_input"
                          style={{ textTransform: 'uppercase', textAlign: 'center' }}
                          onInput={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="asked-block__form_btn">
                    <Form.Item>
                      <Button
                        className="asked-block__form_btn_btn"
                        type="default"
                        htmlType="submit"
                      >
                        VALIDER
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <FooterNavbar />
      </StyleWrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      espaceDebiteur: state.espaceDebiteur,
    }),
    {
      fetchReminder: espaceDebiteurActions.reqFetchReminder,
    }
  )(EspaceDebiteur)
);
