import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {Row, Col, Button, Input, Table, Popconfirm} from 'antd';
import {ApiOutlined, EditOutlined, PlusOutlined, UploadOutlined, DeleteOutlined} from '@ant-design/icons';
import moment from "moment";

import adminActions from "../../../Redux/admin/actions";
import modalActions from '../../../Redux/modals/actions';
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';
import AddUserModal from "../Modals/addUser";
import {DeleteButton} from "../../../helpers/forms";
import NumberFormat from "react-number-format";
import UpdateUserModal from "../Modals/updateUser";

class AdminUsers extends Component{

  state = {
    searchValue: null,
    selectedUser: null,
  };

  componentDidMount(){
    this.props.fetchUsers();
  };

  handleOpenUpdateUserModal(user){
    this.setState({
      selectedUser: user
    });
    this.props.showModal('adminUpdateUser');
  };

  handleCancelUpdateUserModal = () => {
    this.props.hideModal('adminUpdateUser');
    this.setState({
      selectedUser: null
    });
  };

  render(){

    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
        sorter: (a,b) => a.id < b.id ? -1:1,
        render: (text, record, index) => <div style={{textAlign: 'center'}}>{text}</div>,
        showSorterTooltip: false
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a,b) => a.email < b.email ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Raison sociale',
        dataIndex: 'companyName',
        sorter: (a,b) => a.companyName < b.companyName ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Offre',
        dataIndex: 'subscriptionType',
        sorter: (a,b) => a.subscriptionType < b.subscriptionType ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Renouvellement',
        dataIndex: 'subscriptionEndAt',
        render: (text, record, index) => {
          return text ? (
            <span>{moment(record.subscriptionEndAt).format('DD/MM/YYYY')}</span>
          ): '-'
        },
        sorter: (a,b) => a.subscriptionEndAt < b.subscriptionEndAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Adresse',
        dataIndex: 'companyAddress',
        render: (text, record, index) => {
          return record.companyAddress ? (<span>{record.companyAddress}, {record.companyZip}, {record.companyCity}</span>): '-';
        },
        sorter: (a,b) => a.companyAddress < b.companyAddress ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Compte prépayé TTC',
        dataIndex: 'prepaidAmount',
        render: (text, record, index) => {
          return <NumberFormat
            displayType={'text'}
            value={text}
            decimalSeparator={','}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={' '}
            suffix={'€'}
          /> ;
        },
        sorter: (a,b) => a.prepaidAmount < b.prepaidAmount ? -1:1,
        showSorterTooltip: false
      },
      {
        title: (
          <span>
            Actions
          </span>
        ),
        dataIndex: 'id',
        render: (text, record, index) => (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Popconfirm
              title="Voulez-vous vraiment vous connecter à ce compte ?"
              onConfirm={() => this.props.changeUser(record.id)}
              onCancel={() => null}
              okText="Oui"
              cancelText="Non"
            >
              <Button
                type='primary'
                size='small'
              >
                <ApiOutlined />
              </Button>
            </Popconfirm>
            <Button
              type='default'
              style={{
                backgroundColor: colors.primaryBlue,
                borderColor: colors.primaryBlue,
                color: 'white'
              }}
              onClick={() => this.handleOpenUpdateUserModal(record)}
              size='small'
            >
              <EditOutlined/>
            </Button>
            <DeleteButton
            onCancel={() => false}
            onConfirm={() => this.props.deleteUser(record.id)}
            component={
              <Button
                type='default'
                style={{
                  backgroundColor: colors.primaryRed,
                  borderColor: colors.primaryRed,
                  color: 'white'
                }}
                size='small'
              >
                <DeleteOutlined />
              </Button>
            }
            />
          </div>
        ),
      },
    ];

    let data = [];

    this.props.users.map((user, idx) => {
      data.push(user);
    });

    data = data.filter(el => {

      if(this.state.searchValue){
        const uppercaseSearch = this.state.searchValue.toUpperCase();
        if(
          (el.email && el.email.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.companyName && el.companyName.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.subscriptionType && el.subscriptionType.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.subscriptionEndAt && el.subscriptionEndAt.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.companyAddress && el.companyAddress.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.companyCity && el.companyCity.toString().toUpperCase().search(uppercaseSearch) !== -1)
          || (el.companyZip && el.companyZip.toString().toUpperCase().search(uppercaseSearch) !== -1)
        ){
          return true;
        }
        return false;
      }
      return true;
    });

    return (
      <StyleWrapper>
        <AddUserModal/>
        <UpdateUserModal user={this.state.selectedUser} onCancel={this.handleCancelUpdateUserModal}/>
        <Row gutter={16} style={{justifyContent: 'center'}}>
          <Col span={18} className={'shadowed-block'}>

            <h3>Utilisateurs</h3>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div>
                <Button type={'default'} className='blue-btn' style={{marginRight: 5}} onClick={() => this.props.showModal('adminAddUser')}><PlusOutlined /> Ajouter</Button>
                {/*<Button type={'default'} style={{marginRight: 5}} ><UploadOutlined /> Exporter</Button>*/}
                <Input style={{width: 300, marginBottom: 10}} placeholder='Rechercher' onChange={(e) => this.setState({searchValue: e.target.value})}/>
              </div>
            </div>
            <Table
              rowKey={'id'}
              size={'small'}
              bordered={true}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    users: state.admin.users
  }),
  {
    fetchUsers: adminActions.fetchUsers,
    changeUser: adminActions.reqChangeUser,
    deleteUser: adminActions.reqDeleteUser,
    showModal: modalActions.showModal,
    hideModal: modalActions.hideModal,
  }
)(AdminUsers));