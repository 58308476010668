import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import connect from "react-redux/es/connect/connect";
import { saveAs } from 'file-saver';
import userActions from "../../../Redux/user/actions";
import modalActions from '../../../Redux/modals/actions';
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';
import { trialExpired } from '../../../helpers/userHelper';

import { Row, Col, Button, Tooltip, Popconfirm} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import autreFacturePicture from '../../../resources/images/specimen/autre-facture.png';
import avisImpayePicture from '../../../resources/images/specimen/avis-impaye.png';
import miseEnDemeurePicture from '../../../resources/images/specimen/mise-en-demeure.png';
import bbpLogo from '../../../resources/images/landing_page/logo/logo-bbp.png';
import vignetteVerte from '../../../resources/images/general/vignette_verte.jpg';
import vignetteOrange from '../../../resources/images/general/vignette_orange.jpg';
import vignetteRouge from '../../../resources/images/general/vignette_rouge.jpg';
import specimen from '../../../resources/images/specimen/autre-facture-2.png';

class CustomerBbp extends Component{

  render(){
    const { user, appDocuments } = this.props;

    if(!user || !appDocuments)return(<></>);

    const chartDocument = _.find(appDocuments, {name: 'charte_bbp'});
    const greenStickerX1 = _.find(appDocuments, {name: 'sticker_bbp_green_x1'});
    const greenStickerX24 = _.find(appDocuments, {name: 'sticker_bbp_green_x24'});
    const orangeStickerX1 = _.find(appDocuments, {name: 'sticker_bbp_orange_x1'});
    const orangeStickerX24 = _.find(appDocuments, {name: 'sticker_bbp_orange_x24'});
    const redStickerX1 = _.find(appDocuments, {name: 'sticker_bbp_red_x1'});
    const redStickerX24 = _.find(appDocuments, {name: 'sticker_bbp_red_x24'});

    const showCorrectModal = (link) => {
      if(trialExpired(user) || !user.chartAcceptedAt){
        this.props.showCustomModal('Option non disponible', `Seuls les utilisateurs ayant souscrit à un abonnement starter, premium ou privilège et ayant accepté la charte Business Bonnes Pratiques peuvent télécharger les stickers`);
      }else{
        if(link.includes('.pdf')){
          window.open(link, '_blank');
        }else{
          saveAs(link, 'vignette.jpg');
        }
      }
    };

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center',width: '95%',marginLeft: '85px'}}>
          <Col span={24} className="shadowed-block">
            <h2>Acceptez la charte <em>Business Bonnes Pratiques®</em> et <span>utilisez nos sitckers</span> sur vos factures et courriers de relance</h2>

            <Row gutter={16} style={{margin: '40px 0px'}}>
              <Col span={24}>
                <div style={{textAlign: 'center'}}>
                  <img alt='' className='specimen-picture' src={autreFacturePicture} />
                  <img alt='' className='specimen-picture' src={avisImpayePicture} />
                  <img alt='' className='specimen-picture' src={miseEnDemeurePicture} />
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{marginTop: 20, margin: '40px 0px'}}>
              <Col span={10} style={{textAlign: 'right'}}>
                <img alt='' src={bbpLogo} width={200}/>
              </Col>
              <Col span={14}>
                <strong>Télécharger la charte : </strong>
                <Button type='ghost' target="_blank" rel="noreferrer" href={chartDocument.url}>Télécharger</Button>
                <br/>
                {
                  user.chartAcceptedAt ? (
                    <span className="text-success">Vous avez accepté la charte Business Bonnes Pratiques le {moment(user.chartAcceptedAt).format('DD/MM/YYYY')}</span>
                  ):(
                    <>
                      <br/>
                      <strong><em>J'accepte la charte Business Bonnes Pratiques <Tooltip placement='top' title={(<div style={{textAlign: 'center'}}>Acceptez notre charte BBP et téléchargez aussitôt vos stickers vert, orange et rouge à apposer sur
                        vos factures et courriers de relance (stickers disponibles uniquement pour les abonnements)</div>)}><QuestionCircleOutlined style={{color: colors.primaryOrange}}/></Tooltip></em></strong><br/>

                    <Popconfirm
                      placement="top"
                      title={`Voulez-vous vraiment accepter la charte ?`}
                      onConfirm={()=> this.props.requestUpdateUser({chartAcceptedAt: moment().format('YYYY-MM-DD')})}
                      okText="Oui"
                      cancelText="Non"
                    >
                      <Button type={'primary'}>Accepter</Button>
                    </Popconfirm>
                    </>
                  )
                }
                <br/><br/>
                <strong>Questions fréquentes :</strong>
                <ul>
                  <li><a
                    href="https://recouvrup.zendesk.com/hc/fr/articles/360011139959-Pourquoi-accepter-cette-charte-"
                    title="Pourquoi accepter cette charte ?" target="_blank" rel="noreferrer">Pourquoi accepter cette charte
                    ?</a></li>
                  <li><a
                    href="https://recouvrup.zendesk.com/hc/fr/articles/360011139979-Toutes-les-entreprises-sont-elles-%C3%A9ligibles-au-label-BBP-"
                    title="Toutes les entreprises sont-elles éligibles au label BBP ?" target="_blank" rel="noreferrer">Toutes
                    les entreprises sont-elles éligibles au label BBP ?</a></li>
                  <li><a
                    href="https://recouvrup.zendesk.com/hc/fr/articles/360011021640-Comment-utiliser-le-label-BBP-dans-le-recouvrement-de-mes-factures-"
                    title="Comment utiliser le label BBP dans le recouvrement de mes factures ?"
                    target="_blank" rel="noreferrer">Comment utiliser le label BBP dans le recouvrement de mes factures ?</a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row gutter={16} style={{background: '#f9f9f9', borderRadius: 5, margin: '40px 0px', padding: 20, justifyContent: 'center'}}>
              <Col span={12}>
                <h4>
                  Je télécharge les stickers BBP
                </h4>
              </Col>
              <Col span={12}>
                <h4>
                  J'insère l'image jpeg du sticker <br/>
                  sur mes factures pdf
                </h4>
              </Col>
              <Col span={12} style={{borderRight: `${colors.primaryGreen} 2px dashed`}}>
                <table id="" style={{width: '100%', fontWeight: 'bold'}}>
                  <tbody>
                  <tr>
                    <td>
                      <img alt='' src={vignetteVerte} width="200" className="macaron" title="Sticker BBP"/>
                    </td>
                    <td style={{textAlign: 'left'}}>
                      Image jpeg
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(greenStickerX1.url)} className="red-color" title="Télécharger le sticker">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre sticker sous format jpeg (image) et insérez-le sur vos documents (factures, courriers…)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                      <br/>
                      <br/>
                      24 vignettes (pdf)
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(greenStickerX24.url)} className="red-color" title="Télécharger la planche">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre planche de 24 stickers et imprimez la sur une feuille de vignettes autocollantes AVERY (réf : L7159)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img alt='' src={vignetteOrange} width="200" className="macaron" title="Sticker BBP"/>
                    </td>
                    <td style={{textAlign: 'left'}}>
                      Image jpeg
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(orangeStickerX1.url)} className="red-color" title="Télécharger le sticker">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre sticker sous format jpeg (image) et insérez-le sur vos documents (factures, courriers…)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                      <br/>
                      <br/>
                      24 vignettes (pdf)
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(orangeStickerX24.url)} className="red-color" title="Télécharger la planche">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre planche de 24 stickers et imprimez la sur une feuille de vignettes autocollantes AVERY (réf : L7159)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img alt='' src={vignetteRouge} width="200" className="macaron" title="Sticker BBP"/>
                    </td>
                    <td style={{textAlign: 'left'}}>
                      Image jpeg
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(redStickerX1.url)} className="red-color" title="Télécharger le sticker">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre sticker sous format jpeg (image) et insérez-le sur vos documents (factures, courriers…)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                      <br/>
                      <br/>
                      24 vignettes (pdf)
                      <span style={{float: 'right'}}>
                        <a onClick={() => showCorrectModal(redStickerX24.url)} className="red-color" title="Télécharger la planche">ici</a>
                        <Tooltip placement='top' title={`Téléchargez votre planche de 24 stickers et imprimez la sur une feuille de vignettes autocollantes AVERY (réf : L7159)`}>
                          <strong className="badge badge-info">?</strong>
                        </Tooltip>
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col>

              <Col span={12} style={{textAlign: 'center'}}>
                <img alt='' src={specimen} className="invoice-specimen"/>
                <br/>
                <div style={{textAlign: 'left', paddingLeft: 20, fontWeight: 'bold', marginTop: 20, fontSize: '0.9em'}}>
                  <p>
                    1- Lancer la plateforme partenaire <a className="red-color" target="_blank" rel="noreferrer" href="https://www.ilovepdf.com/fr/modifier-pdf">ilovepdf.com</a>
                  </p>
                  <p>
                    2- Sélectionnez vos factures
                  </p>
                  <p>
                    3- Ajoutez et placez l'image jpeg de votre sticker BBP préalablement téléchargé
                  </p>
                </div>
              </Col>

              <Col span={12} style={{marginTop: 40}}>
                <div>
                  <strong>Questions fréquentes :</strong>
                  <ul>
                    <li><a
                      href="https://recouvrup.zendesk.com/hc/fr/articles/360011139959-Pourquoi-accepter-cette-charte-et-utiliser-les-stickers-BBP-"
                      title="Pourquoi apposer un sticker BBP sur mes factures et courriers ?" target="_blank" rel="noreferrer">Pourquoi
                      apposer un sticker BBP sur mes factures et courriers ? </a></li>
                    <li><a
                      href="https://recouvrup.zendesk.com/hc/fr/articles/360011242319-Comment-ins%C3%A9rer-le-sticker-sur-mes-factures-"
                      title="Comment insérer facilement le sticker sur mes factures ?" target="_blank" rel="noreferrer">Comment
                      insérer facilement le sticker sur mes factures ? </a></li>
                    <li><a
                      href="https://recouvrup.zendesk.com/hc/fr/articles/360011259539-Les-stickers-sont-ils-payants-"
                      title="Les stickers sont-ils payants ? " target="_blank" rel="noreferrer">Les stickers sont-ils payants
                      ? </a></li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    appDocuments: state.app.documents
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    requestUpdateUser: userActions.requestUpdateUser,
    showCustomModal: modalActions.showCustomModal
  }
)(CustomerBbp));