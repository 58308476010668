import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x:hidden;  
  padding: 0px 40px 20px 150px;
  
  .header-shadowed-block{
    min-height: 135px;
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  #step-menu{
    margin-top: 30px;
    justify-content: center;
  }
  
  #bbp-button{
    margin: 0;
    padding: 0;
    position: absolute;
    top: 39%;
    left: 10%;
    height: 50px;
  }
  
  #bbp-button:hover{
    background: none;
  }
  
  #inner-bbp-button{
    width: 100px;
    height: 70px;
    background: none;
  }
  
  #illustration{
    width: 100%;
  }
  
  #start-button{
    position: absolute;
    left: 50%;
    margin-left: -86px;
    bottom: 40px;
  }
  
  th{
    font-size: 12px;
  }
  
  table tr td{
    font-size: 12px;
  }

  .hover-card:hover{
    background-color:  ${colors.greenHover}; 
    cursor: pointer;
  }
  
  .card{
    background-color:  ${colors.greenHover}; 
  }
`;

export default StyleWrapper;
