import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';
import userActions from '../../../Redux/user/actions';

import { Button } from 'antd';
import { DashboardOutlined, SafetyOutlined, SendOutlined,
  UserOutlined, ShoppingCartOutlined,
  PoweroffOutlined, SettingOutlined
} from '@ant-design/icons';
import mainLogo from '../../../resources/images/logo-recouvrup.png'
import {colors} from "../../../helpers/appColors";

const sousMenusData = [
  {
    title: 'Pénalités',
    link: '/penalites'
  },
  {
    title: 'Amiable',
    link: '/amiable'
  },
  {
    title: 'Contentieux',
    link: '/contentieux'
  },
  {
    title: 'Judiciaire',
     link: '/judiciaire'
  },
];

class CustomerHeader extends Component{

  constructor(props) {
    super(props);
    this.state = {
      menuOuvert: false,
      activeSousMenu: null,
    };
  }

  componentDidMount(){

    const pathname  = this.props.location.pathname;
    this.setState({
      menuOuvert: sousMenusData.some(item=> item.link === pathname ),
    })

  }

  handleMenuClick = (haveSubMenu) => {
    this.setState((prevState) => ({
      menuOuvert: haveSubMenu ? !prevState.menuOuvert : prevState.menuOuvert,
      activeSousMenu: null, // Réinitialisez activeSousMenu lors du clic sur le bouton principal
    }));

    if (this.props.menuOuvertRemind) {
      this.props.menuOuvertRemind();
    }
  };


  handleSousMenuClick = (index) => {
    this.setState({
      activeSousMenu: index,
    });
  };

  handleListItemHover = (index) => {
    if (this.props.onNavLinkHover) this.props.onNavLinkHover(index);
  };

  render(){
    const {activeSousMenuIndex,selectedOption, ouvertMenuPage} = this.props;
    const { menuOuvert, activeSousMenu } = this.state;

    
    const SousMenus = () => (
      <ul>
        {sousMenusData && Array.isArray(sousMenusData) && sousMenusData.map((item, index) => (
          <li key={index} 
          onMouseEnter={() => this.handleListItemHover(index)}
          onMouseLeave={() => this.handleListItemHover(null)}>
            <Button
               title={item.title}
               className={`menu-button ${
                 this.props.location.pathname === item.link ? 'active' : ''
               } ${(activeSousMenuIndex !== null && activeSousMenuIndex === index) ? 'hovered' : ''} ${(activeSousMenu || activeSousMenuIndex) === index ? 'active' : ''}`}
               type='text'
               href={item.link}
              onClick={() => this.handleSousMenuClick(index)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Ajoutez l'icône appropriée ici si nécessaire */}
                <div className={`menu-title `}>{item.title}</div>
              </div>
            </Button>
          </li>
        ))}
      </ul>
    );

    const MenuButton = ({Icon, text, link, isExternal, haveSubMenu = false}) => {
      const isRecouvrement = text === 'Recouvrement';
      const isRecouvrementActive = isRecouvrement && this.props.isRecouvrementActive;
      

      if (haveSubMenu) {
        return (
          <li>
            <Button
              title={text}
              className={`menu-button ${menuOuvert && isRecouvrement ? 'active' : ''} `}
              type='text'
              onClick={() => this.handleMenuClick(haveSubMenu)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon className='menu-icon' style={{ marginRight: '8px' }} />
                <div className={`menu-title${isRecouvrement && isRecouvrementActive  ? ' active' : ''}`}>{text}</div>
              </div>
            </Button>
            <span style={{ textAlign: 'left' }}>{(menuOuvert ) && <SousMenus/>}</span>
          </li>
        );
      } else {
      return (
        <li>
          <Button title={text} 
          className={`menu-button ${this.props.location.pathname === link || (menuOuvert && isRecouvrement) ? 'active' : ''} `} 
          type='text' 
          href={link} 
          target={isExternal ? '_blank': ''} 
          rel={isExternal ? 'noreferrer' : null}
          >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon className='menu-icon' style={{marginRight: '8px'}}/>
            <div className={`menu-title${isRecouvrement && isRecouvrementActive ? ' active' : ''}`}>{text}</div>
          </div>
          </Button>
        </li>
      );
    };
  }

    return (
      <StyleWrapper>
        <div className='logo'>
          <a href='/main'><img src={mainLogo} className='main-logo' title="Recouvr'up - Plateforme de recouvrement 2.0" alt="Recouvr'up"/></a>
        </div>
        <hr/>
        <ul style={{paddingBottom:'80px'}}>
        {/* <ul style={{paddingBottom:'0'}}> */}
          <MenuButton text={'Recouvrement'} Icon={SendOutlined} haveSubMenu={true} /> 
          <MenuButton text={'Tableau de bord'} Icon={DashboardOutlined} link={'/dashboard'}/>
          <MenuButton text={'Label BBP'} Icon={SafetyOutlined} link={'/bbp'}/>
          <MenuButton text={'Ma formule'} Icon={ShoppingCartOutlined} link={'/subscribe'}/>
          <MenuButton text={'Mon compte'} Icon={UserOutlined} link={'/profile'}/>
        </ul>
        <div style={{position: 'fixed',
              bottom: 6,
              left: 10,
              width: '100%',
              padding: '10px',
              textAlign: 'left'}}>
          {/* <Button style={{marginRight: 5}} href={'/subscribe'} type='default' icon={<SettingOutlined/>}>{user ? user.subscriptionType : ''}</Button> */}
          <Button title='Déconnexion' style={{backgroundColor: colors.primaryRed, borderColor: colors.primaryRed, color: 'white'}} onClick={() => this.props.logout()} ><PoweroffOutlined/></Button>
          {/* <Button style={{marginTop: 5, fontSize: 12, backgroundColor: '#f0ad4e', borderColor:'#f0ad4e', color: 'white'}} href={'/profile'} type='default' icon={<UserOutlined/>}>{user ? user.email : ''}</Button> */}
        </div>
      </StyleWrapper>
    )
  }
}

export default withRouter(connect(
  state => ({
    user: state.user.info
  }),
  {
    logout: userActions.logout
  }
)(CustomerHeader));
