const actions = {
  REQ_FETCH_INVOICES: "REQ_FETCH_INVOICES",
  UPDATE_INVOICES: "UPDATE_INVOICES",
  INVOICES_IS_LOADING: "INVOICES_IS_LOADING",
  BULK_ARCHIVE_INVOICE: "BULK_ARCHIVE_INVOICE",
  EXPORT_TO_EXCEL: "EXPORT_TO_EXCEL",
  UPLOAD_PDF_REQUEST: "UPLOAD_PDF_REQUEST",
  UPLOAD_PDF_SUCCESS: "UPLOAD_PDF_SUCCESS",
  UPLOAD_PDF_ERROR: "UPLOAD_PDF_ERROR",  
  REMOVE_PDF_REQUEST: "REMOVE_PDF_REQUEST",
  REMOVE_PDF_SUCCESS: "REMOVE_PDF_SUCCESS",
  REMOVE_PDF_ERROR: "REMOVE_PDF_ERROR",
  IS_MODAL_OPEN: "IS_MODAL_OPEN",
  BULK_ADD_INVOICE : "BULK_ADD_INVOICE",


  fetchInvoices: () => ({
    type: actions.REQ_FETCH_INVOICES,
  }),

  updateInvoices: data => ({
    type: actions.UPDATE_INVOICES,
    payload: data
  }),

  toggleLoader: (isLoading) => ({
    type: actions.INVOICES_IS_LOADING,
    payload: isLoading
  }),

  bulkArchiveInvoice: (ids, status) => ({
    type: actions.BULK_ARCHIVE_INVOICE,
    payload: {ids, status}
  }),

  isModalOpen : (isOpen) => ({
    type : actions.IS_MODAL_OPEN,
    payload : isOpen
  }), 

  exportToExcel: (isArchived)=>({
    type : actions.EXPORT_TO_EXCEL,
    payload:{isArchived}
  }),

  bulkAddInvoice : (data)=>({
    type : actions.BULK_ADD_INVOICE,
    payload : data
  })

};

export default actions;
