import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  burgerVisible: false,
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.TOGGLE_BURGER:
      newState.burgerVisible = action.payload;
      return newState;

    default:
      return state;
  }
}
