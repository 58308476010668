import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Button, Row, Col } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import PricingTag from "../Helpers/PricingTag";
import NumberFormat from "react-number-format";
import {colors} from "../../../helpers/appColors";
import CustomModal from "./CustomModal.style";


class WrittenProofModal extends Component {

  onFinish = () => {
    this.props.formRef.current.setFieldsValue({submitType: 'written_proof'});
    this.props.formRef.current.submit();
  };

  render(){

    const style = {
      modal: {
        textAlign: 'left'
      },
    };

    const { user } = this.props;

    if(!user) return <></>;

    return (
      <CustomModal
        title={`Obtenez votre justificatif comptable`}
        visible={this.props.customerWrittenProofVisible}
        onCancel={() => this.props.hideModal('customerWrittenProof')}
        footer={null}
        style={style.modal}
      >
        <Row gutter={16} style={{textAlign: 'center', margin: '0', background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={'Gratuit*'} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={'Gratuit'} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={'Gratuit'} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={'Gratuit'} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 10, color: "white"}}>
            <em>* Gratuit la première semaine</em>
          </Col>
        </Row>

        <br/>

        <p style={{textAlign: 'center'}}>
          <em>
            Recevez par mail le justificatif comptable au format pdf
          </em>
        </p>
        <p style={{textAlign: 'center', marginTop: 20}}>
          <em>Montant en principal:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalBalance}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong><br/>
          <em>Pénalités de retard calculées:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalPenalties}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong>
        </p>

        <div style={{textAlign: 'center'}}>
          <Button type='primary' onClick={this.onFinish}>Visualiser</Button>
        </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    customerWrittenProofVisible: state.modals.customerWrittenProofVisible,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)(WrittenProofModal);