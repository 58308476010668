import React, { Component } from 'react';

class PdfViewer extends Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.disableRightClick);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.disableRightClick);
  }

  disableRightClick = (event) => {
    event.preventDefault();
  }

  render() {
    const { pdfUrl,orderType } = this.props;

    return (
      <div style={maxHeight}>
        <div style={orderType ==="written_proof"||orderType==="injonction"||orderType==="mail_direct_recouvrement"?containerStyleWrittenProof: containerStyle} onContextMenu={this.disableRightClick}>
        <div style={orderType ==="written_proof"||orderType==="injonction"||orderType==="mail_direct_recouvrement"?scrollContainerStyleStyleWrittenProof: scrollContainerStyle}>
          <iframe
            ref={this.iframeRef}
            src={pdfUrl+ "#toolbar=0&scrollbar=0"}
            title="PDF Viewer"
            style={iframeStyle}
            scrolling='no'
          />
        </div>
        <div style={overlayStyle} />
        <div style={watermarkStyle}>
           Recouvr'Up NON IMPRIMABLE
        </div>
      </div>
      </div>
    );
  }
}

const maxHeight = {
    width:'50%',
    height : '450px',
    overflow: 'scroll',
}

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
};

const scrollContainerStyle = {
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow:'hidden'
};

const containerStyleWrittenProof = {
  position: 'relative',
  width: '100%',
  height: '70vh',
  overflow: 'hidden',
};
const scrollContainerStyleStyleWrittenProof = {
  position: 'relative',
  width: '100%',
  height: '75vh',
  overflow: 'hidden',
};

const iframeStyle = {
  width: '104%',
  height: '100%',
  border: 'none',
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  background: 'rgba(0, 0, 0, 0)',
};

const watermarkStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem',
  color: 'rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  userSelect: 'none',
  pointerEvents: 'none',
};

export default PdfViewer;