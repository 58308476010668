export function clearToken() {
  localStorage.removeItem("recouvrup_token");
}

export function getToken() {
  try {
    return localStorage.getItem("recouvrup_token");
  } catch (err) {
    clearToken();
    return null;
  }
}

export function setToken(token) {
  localStorage.setItem("recouvrup_token", token);
}


export function clearNewVersionCookie() {
  localStorage.removeItem("recouvrup_new_version");
}

export function getNewVersionCookie() {
  try {
    return localStorage.getItem("recouvrup_new_version");
  } catch (err) {
    clearNewVersionCookie();
    return null;
  }
}

export function setNewVersionCookie(version) {
  localStorage.setItem("recouvrup_new_version", version);
}

