import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  token: null,
  info: null,
  userInfoIsLoading: false,
  registerIsLoading: false,
  companyInfo: null
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.LOG_USER:
      newState.token = action.payload.token;
      return newState;

    case actions.LOGOUT:
      newState.token = null;
      newState.info = null;
      return newState;

    case actions.UPDATE_USER:
      newState.info = action.payload;
      return newState;

    case actions.USERINFO_IS_LOADING:
      newState.userInfoIsLoading = action.payload;
      return newState;

    case actions.REGISTER_IS_LOADING:
      newState.registerIsLoading = action.payload;
      return newState;

    case actions.UPDATE_COMPANY_INFO:
      newState.companyInfo = action.payload;
      return newState;

    default:
      return state;
  }
}
