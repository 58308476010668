import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import moment from 'moment';
import userActions from "../../../Redux/user/actions";
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';

import { Row, Col, Button,Card } from 'antd';

import reminderActions from "../../../Redux/reminders/actions";
import courrierLabels from "../../../helpers/courrierLabels";
import CustomerHeader from '../Header/CustomerHeader';
import logoAmiable from '../../../resources/images/Amiable.png';
import logoPenalite from '../../../resources/images/Pénalités.png';
import logoContentieux from '../../../resources/images/Contentieux.png';
import logoJudiciaire from '../../../resources/images/Judiciaire.png';
import { Link } from 'react-router-dom';


const sousMenusData = [
  {
    title: 'Pénalités',
    subtitle: 'Calculez vos pénalités de retard',
    content: 'Estimez rapidement le montant exact de vos pénalités de retard et recevez un justificatif comptable certifié.',
    logo: logoPenalite,
    link: '/penalites'
  },
  {
    title: 'Amiable',
    subtitle: 'Envoyez un courrier de relance',
    content: "Premier rappel, avis d'impayé... Envoyez le courrier adéquat et utilisez nos stickers BBP si vous le souhaitez.",
    logo: logoAmiable,
    link: '/amiable'
  },
  {
    title: 'Contentieux',
    subtitle: 'Mandatez notre cabinet            ',
    content: "Ayez l'esprit serein grâce aux relances automatiques de notre cabinet et notre prise en charge complète.",
    logo: logoContentieux,
    link: '/contentieux'
  },
  {
    title: 'Judiciaire',
    subtitle: 'Engagez une procédure judiciaire',
    content: "Téléchargez vos pièces et lancez la procédure d'injonction de payer en 1 clic.",
    logo: logoJudiciaire,
    link: '/judiciaire'
  },
];

class CustomerLanding extends Component{

  constructor(props) {
    super(props);
    this.state = {
      activeCardIndex: null,
      isNavLinkHovered: false,
    };
  }

  handleCardHover = (index) => {
    this.setState({
      activeCardIndex: index,
      isNavLinkHovered: false, // Réinitialiser isNavLinkHovered lorsque la carte est survolée
    });
  };
  

  handleNavLinkHover = (index) => {
    this.setState({
      isNavLinkHovered: index !== null,
      activeCardIndex: index,
    });
  };

  handleSousMenuHover = (index) => {
    this.setState({
      isNavLinkHovered: index !== null,
      activeCardIndex: index,
    });
  };

  

  componentDidMount(){
    this.props.fetchReminders();
  }
  render(){

    const { userInfo, reminders } = this.props;
    const { activeCardIndex,isNavLinkHovered } = this.state;


    if(!userInfo) return <></>

    return (
      <StyleWrapper>

        <Row gutter={16} id='step-menu'  style={{ width: '95%',marginLeft: '85px'}}>
          <Col span={12} style={{paddingLeft:0}}>
            <div  className="shadowed-block header-shadowed-block">
            <Row gutter={16} style={{fontSize: '1.2em'}}>
              <Col span={12}>
                <strong>
                  {userInfo.companyName}
                  <br/>{userInfo.companyAddress}
                  <br/>{userInfo.companyZip}, {userInfo.companyCity}
                </strong>
              </Col>
              <Col span={12} style={{textAlign: 'right'}} >
                <strong>Formule {userInfo.subscriptionType.toUpperCase()}</strong><br/>
                {userInfo.subscriptionType === "Privilege" ? " " : <a href={`/subscribe`} title={`Mettre à niveau`} style={{fontWeight: 'bold', marginLeft: 20, textDecoration: 'underline'}}>Passer à la formule supérieure</a>}
              </Col>
            </Row>
            </div>
          </Col>
          <Col span={12} style={{paddingRight:0}}>
            <div className="shadowed-block header-shadowed-block"> 
              <Row gutter={16} style={{fontSize: '1.2em'}}>
                <Col span={24}>
                  <strong>
                    Dernière relance
                    <br/>{
                    reminders[0] ?
                      (
                        <>
                          <a href={reminders[0].documentUrl} title='document' target='_blank'>
                          {courrierLabels[reminders[0].documentType]}
                          </a> sur {reminders[0].companyName} le {moment(reminders[0].createdAt).format('DD/MM/YYYY')}
                        </>
                      )
                      : '-'}
                  </strong>
                  <div style={{textAlign: 'right'}}>
                    <Button type='ghost' href='/dashboard' size='large' title='Voir toutes les relances'>Voir toutes les relances</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row gutter={16} style={{justifyContent: "space-around", width: '95%',marginLeft: '85px'}}>
          <Col span={24} className="shadowed-block" style={{paddingBottom: 40}}>
            <div>
              <a href='https://recouvrup.zendesk.com/hc/fr/articles/360020840819-Comment-%C3%A7a-fonctionne-' rel="noreferrer" target='_blank'>
                <strong style={{color: `${colors.textGrey}`, textDecoration: 'underline'}}>Comment ça fonctionne ?</strong>
              </a>
              <h1 style={{color: colors.textGrey,  fontSize: '45px', textAlign: 'center'}}> Sélectionnez l'action à engager </h1>
            </div>
            <Row style={{ margin:'50px 85px'}}>
            {sousMenusData.map((sousMenu, index) => (
              <Col xs={16} sm={6} md={5} key={index} style={{ marginLeft: '12px' , marginBottom: '20px'}}>
                <Link to={sousMenu.link} style={{ textDecoration: 'none' }}>
              <Card
                style={{
                  border: `1px solid ${colors.primaryGreen}`,
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                className={`hover-card ${ activeCardIndex === index  ? ' active-card' : ''} ${isNavLinkHovered && activeCardIndex === index ? 'card' : ""}` }
                onMouseEnter={() => this.handleSousMenuHover(index)}
                onMouseLeave={() => this.handleNavLinkHover(null)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <div
                    style={{
                      color: colors.primaryGreen,
                      textDecoration: 'underline',
                      fontSize: '22px',
                      textAlign: 'left',
                    }}
                  >
                    <strong>{sousMenu.title}</strong>
                    </div>
                    <div style={{textAlign: 'right' , marginTop: '5px' }}>
                      <img
                        src={sousMenu.logo}
                        alt={`Logo ${sousMenu.title}`}
                        style={{
                          width: '48px',  // Ajustez la taille du logo selon vos besoins
                          height: '48px',  // Ajustez la taille du logo selon vos besoins
                        }}
                      />
                    </div>
                </div>

                <br />
                <strong>
                  {sousMenu.subtitle && (
                    <div style={{ fontSize: '21px', padding: '20px 0', margin: 0, height: 'auto'}}>
                      <span style={{ textTransform: 'uppercase' }}>{sousMenu.subtitle}</span>
                    </div>
                  )}
                </strong>
                <Card.Meta
                  description={<div style={{ color: 'black', textAlign: 'left', padding: '25px' , flex: 1}}>{sousMenu.content}</div>}
                />
              </Card>
              </Link>
            </Col>
          ))}
          </Row>
          </Col>
        </Row>
        <CustomerHeader activeSousMenuIndex={activeCardIndex}   sousMenusData={sousMenusData} onNavLinkHover={this.handleNavLinkHover} onSousMenuHover={this.handleSousMenuHover}/>
      </StyleWrapper>
    )
  }
}

export default withRouter(connect(
  state => ({
    token: state.user.token,
    userInfo: state.user.info,
    reminders: state.reminders.reminders
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    fetchReminders: reminderActions.reqFetchReminders,
  }
)(CustomerLanding));
