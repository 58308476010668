import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  
  h2{
    font-weight: bold;
    color: ${colors.textGrey};
    border-bottom: solid ${colors.primaryGreen} 1px;
    padding: 10px;
    span{
      color: ${colors.primaryGreen};
    }
  }
  
  h3{
    text-align: center;
    font-size: 32px;
    margin-top: 40px;
    color: ${colors.textGrey};
  }
  
  h4{
    color: white;
    font-size: 22px;
  }
  
  .text-center{
    text-align: center;
  }
  .option-block{
    margin-top : 20px;
    color : ${colors.primaryBlack};
    font-size : 14px;
    line-height : 1.2!important;
  }

  .option-bloc__text{
    margin: 15px 50px 0px 40px;
    font-size : 13px;
    color : ${colors.textGrey}
  }
  
`;

export default StyleWrapper;