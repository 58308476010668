export default  {
  SERVER_ISSUE: `Une erreur est survenue. Veuillez contacter l'administrateur du site`,
  MISSING_INPUT: `Veuillez compléter tous les champs requis`,
  UNSECURED_PWD: `Veuillez saisir un mot de passe plus robuste`,
  WRONG_EMAIL: `Veuillez saisir un email valide`,
  EMAIL_ALREADY_EXISTS: `Un compte existe déjà avec cette adresse email`,
  EMAIL_NOT_SENT: `Une erreur est survenue durant l'envoie du message`,
  DIFFERENT_CONFIRMATION: `Votre mot passe et la confirmation ne sont pas les mêmes`,
  CONFIRMATION_ALREADY_VALIDATED: `Votre adresse a déjà été confirmée`,
  WRONG_RCS: `Veuillez saisir un numéro SIREN valide`,
  WRONG_IDENTIFIER: `Veuillez vérifier vos identifiants`,
  NO_COMPANY_FOUND: `Aucun établissement n'a été trouvé avec ce numéro SIREN`,
  WRONG_ZIP: `Veuillez saisir un code postal valide`,
  INVOICE_LINKED_TO_ORDER: `Certaine factures sont encore liées à des relances`,
  INSUFFICIENT_PREPAID_AMOUNT: `Le solde de votre compte prépayé est insuffisant`,
  DIFFERENT_COMPANIES: `Veuillez sélectionner des factures d'une seule et même société`,
  RECIPIENT_ADDRESS_TOO_LONG: `Veuillez saisir une adresse contenant moins de 45 caractères`,
  ONLY_REMINDER:'Veuillez sélectionner une seule ligne de relance',
  MISSING_INPUT_PENALTY: 'Veuillez sélectionner un type de client et choisir un taux si vous voulez calculer des pénalités de retard',
  REQUIRED_OPTIONS : 'Choissiez un option',
  REFERENCE_NOT_FOUND : 'Cette référence n’existe pas. Veuillez vérifier.',
  MAX_FILE_SIZE : 'Votre pièce jointe est trop lourde. Elle ne doit pas dépasser 8 Mo',
  WRONG_FILE : 'Type de fichier invalide',
  NO_FACTURES : 'Aucun pdf téléchargé. Veuillez prendre contact avec votre créancier pour récupérer les factures si nécessaire',
  ONLY:'Veuillez sélectionner une seule ligne',
  UPLOAD_FAILED : "Erreur lors du téléchargement du fichier. Veuillez réessayer, s'il vous plaît."
};
