import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button, Form } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import {FormInput} from "../../../helpers/forms";
import adminActions from "../../../Redux/admin/actions";


class AddUserModal extends Component {

  handleCancel = () => {
    this.props.hideModal('adminAddUser');
  };

  onFinish = values => {
    const {email, pwd} = values;
    this.props.addUser(email, pwd);
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
    };

    return (
      <Modal
        title={`Ajouter un utilisateur`}
        visible={this.props.adminAddUserVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >

        <Form onFinish={this.onFinish}>
          <FormInput
            label={`Email`}
            name={`email`}
            rules={[{required: true, message: 'Veuillez remplir ce champ'}]}
          />
          <FormInput
            label={`Mot de passe`}
            name={`pwd`}
            rules={[{required: true, message: 'Veuillez remplir ce champ'}]}
          />
          <em>(*) 5 caractères minimum (lettres et chiffres uniquement)</em>
          <br/>
          <br/>
          <Button type='primary' htmlType={`submit`}>Ajouter</Button>
        </Form>


      </Modal>
    )
  }
}

export default connect(
  state => ({
    adminAddUserVisible: state.modals.adminAddUserVisible,
  }),
  {
    hideModal: modalsActions.hideModal,
    addUser: adminActions.reqAddUser
  }
)(AddUserModal);