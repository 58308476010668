import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  pdfUrl: null,
  isInLoading: false,
  removeSuccess: false,
  error: null,
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.UPLOAD_PDF_SUCCESS:
      newState.pdfUrl = action.payload.pdfUrl;
      return newState;

    case actions.IS_IN_LOADING:
      newState.isInLoading = action.payload;
      return newState;

    case actions.UPLOAD_PDF_ERROR:
      newState.error = action.payload.error;
      return newState;

    case actions.REMOVE_PDF_SUCCESS:
      newState.removeSuccess = action.payload;
      return newState;

    case actions.REMOVE_PDF_ERROR:
      newState.error = action.payload.error;
      return newState;

    case actions.REMOVE_FILE_SUCCESS:
      newState.removeSuccess = action.payload;
      return newState;

    default:
      return state;
  }
}
