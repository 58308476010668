import { cloneDeep } from "lodash";
import actions from './actions';

const initState = {
  globalBalance:0,
  globalPenalties: 0,
  myCompanies: [],
  defaultFormValues: null,
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {

    case actions.UPDATE_PENALTIES_INFO:
      const {globalBalance, globalPenalties} = action.payload;
      newState.globalBalance = globalBalance;
      newState.globalPenalties = globalPenalties;
      return newState;

    case actions.UPDATE_MY_COMPANIES:
      newState.myCompanies = action.payload;
      return newState;

    case actions.UPDATE_DEFAULT_FORM_VALUES:
      newState.defaultFormValues = action.payload;
      return newState;

    default:
      return state;
  }
}
