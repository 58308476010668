import styled from 'styled-components';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  #step-menu{
    margin-top: 30px;
    justify-content: center;
  }
  
  #bbp-button{
    margin: 0;
    padding: 0;
    position: absolute;
    top: 24%;
    left: 8%;
  }
  
  #bbp-button:hover{
    background: none;
  }
  
  #inner-bbp-button{
    width: 100px;
    height: 70px;
    background: none;
  }
  
  #illustration{
    width: 100%;
  }
  
  #start-button{
    position: absolute;
    left: 50%;
    margin-left: -73px;
    bottom: 10px;
  }
`;

export default StyleWrapper;