import React, { Component } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from '../../Redux/user/actions';
import adminActions from '../../Redux/admin/actions';
import modalActions from '../../Redux/modals/actions';
import StyleWrapper from './StyleWrapper';

import AdminHeader from './Header/AdminHeader';
import AdminUsers from "./Users/AdminUsers";
import AdminInterest from "./Interest/AdminInterest";
import AdminBce from "./Bce/AdminBce";
import AdminLabel from "./Label/AdminLabel";
import AdminDiscount from "./Discount/AdminDiscount";
import AdminDocuments from "./Documents/AdminDocuments";
import AdminPdf from "./Pdf/AdminPdf";

class AdminMain extends Component{

  componentDidMount(){
    if(this.props.token){
      this.props.fetchUser();
      this.props.fetchConfig();
    }
  }

  render(){
    return (
      <StyleWrapper>
        <AdminHeader/>
        <section id='admin-main'>
          <Switch>
            <Route path='/admin/interest'>
              <AdminInterest/>
            </Route>
            <Route path='/admin/bce'>
              <AdminBce/>
            </Route>
            <Route path='/admin/label'>
              <AdminLabel/>
            </Route>
            <Route path='/admin/discount'>
              <AdminDiscount/>
            </Route>
            <Route path='/admin/documents'>
              <AdminDocuments/>
            </Route>
            <Route path='/admin/pdf'>
              <AdminPdf/>
            </Route>
            <Route path='/admin'>
              <AdminUsers/>
            </Route>
          </Switch>
        </section>
      </StyleWrapper>
    )
  }

}

export default withRouter(connect(
  state => ({
    token: state.user.token
  }),
  {
    fetchUser: userActions.fetchUser,
    fetchConfig: adminActions.fetchConfig,
    showModal: modalActions.showModal
  }
)(AdminMain));