import { cloneDeep } from "lodash";
import actions from './actions';

const initState = {
  isLoading: false
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {

    case actions.TOGGLE_SUBSCRIPTION_LOADER:
      newState.isLoading = action.payload;
      return newState;

    default:
      return state;
  }
}
