import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import NumberFormat from "react-number-format";
import { Button, Row, Col, Divider, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import modalsActions from '../../../Redux/modals/actions';
import PricingTag from "../Helpers/PricingTag";
import {FormInput, FormUpload} from "../../../helpers/forms";
import {colors} from "../../../helpers/appColors";
import _ from "lodash";
import CustomModal from "./CustomModal.style";

class InjonctionModal extends Component {

  state = {
    fileInjonctionOrderQuote: null,
    fileInjonctionInvoices: null,
    fileInjonctionMiseEnDemeure: null,
    fileInjonctionOthers: null,
    myCompanies: [],
    maxLength:45
  };

  componentDidUpdate(prevProps, prevState, snapshot){
    if(
      (
        this.props.customerInjonctionVisible !== prevProps.customerInjonctionVisible
        || this.props.myCompanies !== prevProps.myCompanies
      )
      && this.props.customerInjonctionVisible) {
      if (this.props.formRef.current) {
        const {
          companyId,
          injonctionCompanyName,
          injonctionAddress,
          injonctionZip,
          injonctionCity,
          injonctionSiren
        } = this.props.formRef.current.getFieldsValue();
        if(companyId){

          const company = _.find(this.props.myCompanies, {id: parseInt(companyId)});

          if(company) {
            for(const key in company){
              if(company[key] === 'null') {
                company[key] = null
              }
            }
            this.props.formRef.current.setFieldsValue({
              injonctionCompanyName: injonctionCompanyName ? injonctionCompanyName : company.name,
              injonctionAddress: injonctionAddress ? injonctionAddress : company.address,
              injonctionZip: injonctionZip ? injonctionZip : company.zip,
              injonctionCity: injonctionCity ? injonctionCity : company.city,
              injonctionSiren: injonctionSiren ? injonctionSiren : company.siren
            })
          }
        }
      }
    }
  }

  onFinish = () => {
    this.props.formRef.current.setFieldsValue({
      submitType: 'injonction',
      fileInjonctionOrderQuote: this.state.fileInjonctionOrderQuote,
      fileInjonctionInvoices: this.state.fileInjonctionInvoices,
      fileInjonctionMiseEnDemeure: this.state.fileInjonctionMiseEnDemeure,
      fileInjonctionOthers: this.state.fileInjonctionOthers
    });
    this.props.formRef.current.submit();
  };

  handleFileOrderQuote = file => {
    this.setState({ fileInjonctionOrderQuote: file });
    return false;
  };

  handleFileInvoices = file => {
    this.setState({ fileInjonctionInvoices: file });
    return false;
  };

  handleFileMiseEnDemeure = file => {
    this.setState({ fileInjonctionMiseEnDemeure: file });
    return false;
  };

  handleFileOthers = file => {
    this.setState({ fileInjonctionOthers: file });
    return false;
  };

  render(){

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    const { user, appPrices } = this.props;

    if(!user) return <></>;

    const pricing = appPrices.injonctionPricing;

    return (
      <CustomModal
        title={`Déclenchez la procédure avec un huissier en 1 clic`}
        visible={this.props.customerInjonctionVisible}
        onCancel={() => this.props.hideModal('customerInjonction')}
        footer={null}
        style={style.modal}
      >

        <Row gutter={16} style={{textAlign: 'center', margin: '0', background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={`${pricing.free === 0 ? 'Gratuit' : pricing.free}`} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={`${pricing.starter === 0 ? 'Gratuit' : pricing.starter}`} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={`${pricing.premium === 0 ? 'Gratuit' : pricing.premium}`} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={`${pricing.privilege === 0 ? 'Gratuit' : pricing.privilege}`} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 20, color: 'white'}}>
            +<br/>
            <strong>Frais de greffe</strong><br/>
            (<NumberFormat
            displayType={'text'}
            value={pricing.fees}
            decimalSeparator={','}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={' '}
            suffix={'€ HT'}
          />)
          </Col>
        </Row>

        <br/>

        <p style={{textAlign: 'center'}}>
          <em>
            Veuillez identifier votre client (n° Siren ou coordonnées) et joindre les pièces justificatives
          </em>
        </p>

        <Row gutter={16} style={{margin: 0, padding: 20}}>
          <Col span={12} offset={6}>
            <FormInput
              label={<span>N° identification Siren à 9 chiffres du débiteur <Tooltip placement="top" title={<div style={{textAlign: 'center'}}>Vous pouvez retrouver ce numéro SIREN sur le site societe.com ou infogreffe.fr en recherchant par le nom de votre client</div>}><QuestionCircleOutlined /></Tooltip></span>}
              alignLabel='center'
              placeholder={`N° identification Siren à 9 chiffres du débiteur`}
              name={`injonctionSiren`}
              type='text'
            />
          </Col>
          <Col span={24} style={{textAlign: 'center'}}>
            <strong>OU</strong>
          </Col>
          <Col span={12}>
            <FormInput
              label={`Raison sociale du débiteur`}
              alignLabel='center'
              placeholder={`Raison sociale du débiteur`}
              name={`injonctionCompanyName`}
              type='text'
              disabled={true}
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={`Adresse du débiteur`}
              alignLabel='center'
              placeholder={`Adresse du débiteur (max 45 caractères)`}
              maxLength={this.state.maxLength}
              name={`injonctionAddress`}
              type='text'
              rules={[{max : this.state.maxLength-1 ,  message:`Maximum ${this.state.maxLength} caractères`, warningOnly: true}]}
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={`Code postal du débiteur`}
              alignLabel='center'
              placeholder={`Code postal du débiteur`}
              name={`injonctionZip`}
              type='text'
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={`Ville du débiteur`}
              alignLabel='center'
              placeholder={`Ville du débiteur`}
              name={`injonctionCity`}
              type='text'
            />
          </Col>
        </Row>

        <div style={{border: 'solid lightgrey 2px', borderRadius: 5, margin: 20, padding: 20, textAlign: 'center'}}>
          <strong>Pièces à joindre à la requête</strong> <Tooltip placement="top" title={<div style={{textAlign: 'center'}}>Pour que l’ordonnance vous soit favorable, joindre à minima une copie de la commande validée par le client, les factures impayées, et une copie d’une mise en demeure avec la preuve de réception</div>}><QuestionCircleOutlined /></Tooltip>
          <br/>
          <em style={{color: colors.primaryGreen}}>Sélectionnez plusieurs fichiers en restant appuyé sur la touche "Ctrl" de votre clavier. Taille maximale par fichier : 2Mo</em>
          <Row gutter={16} style={{marginTop: 20}}>
            <Col span={12}>
              <FormUpload
                buttonLabel='Commandes, devis signés'
                name={`injonctionOrderQuote`}
                valuePropName={`file`}
                accept={`.pdf`}
                action={'#'}
                multiple={true}
                maxCount={100}
                beforeUpload={this.handleFileOrderQuote}
              />
            </Col>
            <Col span={12}>
              <FormUpload
                buttonLabel='Factures impayées'
                name={`injonctionInvoices`}
                valuePropName={`file`}
                accept={`.pdf`}
                action={'#'}
                multiple={true}
                maxCount={100}
                beforeUpload={this.handleFileInvoices}
              />
            </Col>
            <Col span={12}>
              <FormUpload
                buttonLabel='Mise en demeure et son AR'
                name={`injonctionMiseEnDemeure`}
                valuePropName={`file`}
                accept={`.pdf`}
                action={'#'}
                multiple={true}
                maxCount={100}
                beforeUpload={this.handleFileMiseEnDemeure}
              />
            </Col>
            <Col span={12}>
              <FormUpload
                buttonLabel='Autres'
                name={`injonctionOthers`}
                valuePropName={`file`}
                accept={`.pdf`}
                action={'#'}
                multiple={true}
                maxCount={100}
                beforeUpload={this.handleFileOthers}
              />
            </Col>
          </Row>
        </div>

        <p style={{textAlign: 'center', marginTop: 20}}>
          <em>Montant en principal:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalBalance}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong><br/>
          <em>Pénalités de retard calculées:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalPenalties}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong>
        </p>

        <div style={{textAlign: 'center'}}>
          <Button type='primary' onClick={this.onFinish}>Lancer l'injonction de payer</Button>
        </div>
        <Divider/>
        <div style={{textAlign: 'center', padding: 20}}>
          <em>Nous accuserons réception de cette demande immédiatement et adresserons la requête au Tribunal sous 48h ouvrées. Si des pièces ou informations venaient à manquer, nous reprendrions contact avec vous dans ce délai</em>
        </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    customerInjonctionVisible: state.modals.customerInjonctionVisible,
    appPrices: state.app.prices,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance,
    myCompanies: state.calculator.myCompanies
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)(InjonctionModal);