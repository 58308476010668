import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Row, Col, Button, Form } from 'antd';
import {FormInput} from "../../../helpers/forms";
import adminActions from "../../../Redux/admin/actions";


class UpdateUserModal extends Component {

  formRef = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.user !== prevProps.user){
      if(this.formRef.current) {
        this.formRef.current.setFieldsValue({
          prepaidAmount: this.props.user.prepaidAmount ? this.props.user.prepaidAmount.toFixed(2) : 0
        });
      }
    }
  }

  onFinish = values => {
    const {prepaidAmount} = values;
    this.props.updateUserPrepaidAmount(this.props.user.id, prepaidAmount);
  };

  render(){

    if(!this.props.user) return <></>

    const style = {
      modal: {
       textAlign: 'center',
      },
    };

    let {user} = this.props;

    return (
      <Modal
        title={`Modifier un compte prépayé`}
        visible={this.props.adminUpdateUserVisible}
        onCancel={this.props.onCancel}
        footer={null}
        style={style.modal}
      >
        <h3>{user.companyName}</h3>
        <em>{user.email}</em>
        <br/>
        <br/>
        <Row>
          <Col span={12} offset={6}>
            <Form onFinish={this.onFinish} ref={this.formRef}>
              <FormInput
                label={`Montant`}
                name={`prepaidAmount`}
                type={`currency`}
                rules={[{required: true, message: 'Veuillez remplir ce champ'}]}
              />
              <br/>
              <Button type='primary' htmlType={`submit`}>Enregistrer</Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    adminUpdateUserVisible: state.modals.adminUpdateUserVisible,
  }),
  {
    updateUserPrepaidAmount: adminActions.reqUpdateUserPrepaidAmount
  }
)(UpdateUserModal);