import styled from 'styled-components';
import { device } from '../../../helpers/deviceBreakpoints';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`

  padding: 25px 40px 20px 150px;

  .link-file{
    color: black;
    text-decoration: underline;
    margin-right: 10px;
  }

  .label-upload{
    font-weight: bold;
    margin-right: 10px;
  }
  
  .remove-btn-file{
    background: transparent;
    color: red;
    border: 0px;  
  }
  .remove-btn-file span{
    text-decoration: underline;
  }

  .upload-btn{
    color: ${colors.textLightGrey};
  }

  h3 {
    font-size: 1.7em;
    color: ${colors.textGrey};
    text-align: left;
    border-bottom: 1px lightgrey solid;
    padding-bottom: 20px;
  }
  
  h4{
    font-size: 1.1em;
  }
  
  #step-menu-bar {
    border-bottom: solid grey 2px;
    height: 1px;
    width: 70%;
    margin-top: -55px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .circle-btn-active {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin: auto;
    padding-top: 25px;
    background-color: ${colors.primaryGreen};
    color: white;
    font-weight: bold;
  }
  .circle-btn {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin: auto;
    padding-top: 23px;
    border: ${colors.primaryGreen} solid 2px;
    background-color: white;
    color: ${colors.textGrey};
    font-weight: bold;
  }
  
  .bordered-block{
    border: solid #F0F0F0 2px;
    padding: 20px;
    border-radius: 4px;
    width: 80%;
    background: white;
  }
  
  .green-text{
    color: ${colors.primaryGreen};
  }
  
  .step3-block{
    background: #f9f9f9;
    border-radius: 5px;
    padding: 40px 40px !important;
    margin-bottom: 20px;
  }
  
  .step3-block h4{
    font-style: italic;
    font-weight: bold;
    font-size: 1.4em;
    color: ${colors.textGrey};
    // border-bottom: solid 1px ${colors.primaryGreen};
    padding-bottom: 0px;
    margin : 0px ;
  }
  
  .step3-block h4 span{
    font-size: 1em;
    font-weight: 500;
    color: ${colors.primaryGreen};
  }
  
  .step3-mini-icon{
    width: 50px;
    margin-right: 20px;
    vertical-align: bottom;
  }
  
  .step3-choices{
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    text-align: center;
    Button {
      margin-bottom: 10px;
      font-size: 0.85em;
    }
    em{
      color: ${colors.textGrey};
      font-weight: bold;
      font-size: 11px;
      padding-bottom: 7px
    }
    a {
      color: ${colors.primaryBlue};
      margin-top: 20px;
    }
  }
  
  label{
    font-size: 14px;
    @media ${device.desktop} {
    font-size: 12px;
    }
  }
  
  .ant-tooltip-disabled-compatible-wrapper{
    display: initial !important;
  }

  #step3 .t-h4{
    padding: 5px 0px;
    margin: 0px;
  }

  @media (max-width: 1500px) {
    .step3-choices button {
      padding: 0 10px !important; 
    }

    .btn button{
      padding: 0 25px !important; 
    }

    .step3-choices span {
      font-size: 10px !important;
    }
  
    .step3-choices em {
      font-size: 8px !important;
    }
  }
  
`;

export default StyleWrapper;