const actions = {
  REQ_FETCH_REMINDER: "REQ_FETCH_REMINDER",
  UPDATE_REMINDER: "UPDATE_REMINDER",
  PAY_WITH_BRIDGE: "PAY_WITH_BRIDGE",
  REQ_FILE_UPLOAD: "REQ_FILE_UPLOAD",
  UPDATE_FILE_UPLOAD: "UPDATE_FILE_UPLOAD",
  REQ_COMPUTE_STATUS: "REG_COMPUTE_STATUS",
  IS_LOADING: "IS_LOADING",
  DOWNLOAD_FACTURE : "DOWNLOAD_FACTURE",

  reqFetchReminder: (reference) => ({
    type: actions.REQ_FETCH_REMINDER,
    payload : reference
  }),

  updateReminder: (reminder) => ({
    type: actions.UPDATE_REMINDER,
    payload: reminder,
  }),

  reqFileUpload: (data) => ({
    type: actions.REQ_FILE_UPLOAD,
  }),

  updateFileUpload: (url) => ({
    type: actions.UPDATE_FILE_UPLOAD,
    payload: url,
  }),

  reqComputeStatus: (data) => ({
    type: actions.REQ_COMPUTE_STATUS,
    payload: data
  }),

  isLoading : (isLoading) => ({
    type: actions.IS_LOADING,
    payload: isLoading
  }),

  downloadFactures : (factures) => ({
    type : actions.DOWNLOAD_FACTURE,
    payload : factures 
  }),

  payWithBridge: (data) => ({
    type: actions.PAY_WITH_BRIDGE,
    payload: data,
  }),
};


export default actions;