import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Spin, Button, Popconfirm } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import connect from "react-redux/es/connect/connect";

import subscriptionActions from '../../../Redux/subscription/actions';
import userActions from '../../../Redux/user/actions';
import { FormInput } from '../../../helpers/forms';
import { colors } from '../../../helpers/appColors';
// import logoQuickBooks from '../../../resources/images/general/logo-quickbooks.png';
// import logoAxonaut from '../../../resources/images/general/logo-axonaut.png';
// import logoSellsy from '../../../resources/images/general/logo-sellsy.png';
// import logoEvoliz from '../../../resources/images/general/logo-evoliz.png';

class TabAccount extends Component{

  onFinish = (values) => {
    this.props.requestUpdateUser(values);
  };

  render(){
    const { user } = this.props;
    if(!user){
      return (<></>);
    }

    return (
      <Spin spinning={this.props.userIsLoading}>
        <div className='grey-block'>
          <h3>Mon compte</h3>
          <p><strong>Mon adresse e-mail :</strong> {user.email }</p>
          <p><strong>Statut :</strong> {user.subscriptionType } <a href='https://billing.stripe.com/p/login/cN24iqer993h6SAdQQ' target='_blank' title='Modifier mon moyen de paiement' style={{fontSize: 12, marginLeft: 10}}><CreditCardOutlined /> Modifier mon moyen de paiement</a>
            <Popconfirm placement="top" title={`Voulez-vous vraiment résilier votre abonnement ?`} onConfirm={()=> this.props.cancelSubscription()} okText="Oui" cancelText="Non">
              {" "}<Button style={{marginLeft: 10}} type='button' disabled={!(user.subscriptionType !== 'Free' && !user.subscriptionCanceledAt)}>Résilier mon abonnement</Button>
            </Popconfirm>
          </p>
          <p>
            <strong>Prochaine échéance :</strong> {
              user.subscriptionEndAt && !user.subscriptionCanceledAt ? moment(user.subscriptionEndAt).format('DD/MM/YYYY') : ''}
              {user.subscriptionCanceledAt ? <em style={{color: colors.primaryRed}}>Résilié - Offre active jusqu'au {moment(user.subscriptionEndAt).format('DD/MM/YYYY')}</em> : ''}
          </p>
          <Form onFinish={this.onFinish}>
            <Row gutter={16}>
              <Col span={24}>
                <strong>Changer votre mot de passe : <br/><em style={{fontSize: '0.7em'}}>5 caractères minimum (lettres et chiffres seulement)</em></strong>
                <br/><br/>
              </Col>
              <Col span={7}>
                <FormInput
                  type='password'
                  name='password'
                  label='Ancien mot de passe'
                  placeholder='Ancien mot de passe'
                />
              </Col>
              <Col span={7}>
                <FormInput
                  type='password'
                  name='newPassword'
                  label={`Nouveau mot de passe`}
                  placeholder='Nouveau mot de passe' />
              </Col>
              <Col span={10} style={{paddingTop: 26}}>
                <Button type='default' className='blue-btn' htmlType='submit'>Modifier</Button>
              </Col>
            </Row>
          </Form>

          <br/>
          <div>
            <strong>Questions fréquentes :</strong>
            <ul>
              <li><a
                href="https://recouvrup.zendesk.com/hc/fr/articles/360011021260-Quels-sont-les-diff%C3%A9rents-abonnements-propos%C3%A9s-"
                title="Quels sont les différents abonnements proposés ?" target="_blank" rel="noreferrer">Quels sont les différents abonnements proposés ?
              </a></li>
              <li><a
                href="https://recouvrup.zendesk.com/hc/fr/articles/360011021340-Peut-on-r%C3%A9silier-ou-changer-d-abonnement-%C3%A0-tout-moment-"
                title="Peut-on résilier ou changer d’abonnement à tout moment ?" target="_blank" rel="noreferrer">Peut-on résilier ou changer d’abonnement à tout moment ?
              </a></li>
              <li><a
                href="https://recouvrup.zendesk.com/hc/fr/articles/360011139919-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-"
                title="Quels sont les moyens de paiement acceptés ?" target="_blank" rel="noreferrer">Quels sont les moyens de paiement acceptés ?
              </a></li>
            </ul>
          </div>
        </div>
        {/*<div className='grey-block'>*/}
          {/*<h3>Synchronisation</h3>*/}
          {/*<strong>Aucun compte synchronisé avec Recouvr'Up</strong>*/}
          {/*<br/><br/>*/}
          {/*<p style={{fontSize: '1em'}}>*/}
            {/*Synchronisez votre compte Recouvr'Up avec un des logiciels partenaires ci-dessous. Les factures impayées de votre CRM seront automatiquement intégrées chaque jour dans Recouvr'Up.<br/>*/}
            {/*Suivez ensuite vos encours clients depuis Recouvr'Up, sélectionnez vos factures et déclenchez vos actions de recouvrement.*/}
          {/*</p>*/}
          {/*<div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>*/}
            {/*<img alt='' src={logoQuickBooks} style={{width: 200, background: 'white', borderRadius: 5, padding: 5, border: 'solid lightgrey 1px'}}/>*/}
            {/*<img alt='' src={logoAxonaut} style={{width: 200, background: 'white', borderRadius: 5, padding: 5, border: 'solid lightgrey 1px'}}/>*/}
            {/*<img alt='' src={logoSellsy} style={{width: 200, background: 'white', borderRadius: 5, padding: 5, border: 'solid lightgrey 1px'}}/>*/}
            {/*<img alt='' src={logoEvoliz} style={{width: 200, background: 'white', borderRadius: 5, padding: 5, border: 'solid lightgrey 1px'}}/>*/}
          {/*</div>*/}
          {/*<div style={{textAlign: 'center', marginTop: 20}}>*/}
            {/*Votre CRM ne figure pas dans la liste ? <a href='#' title='Contactez-nous !'>Contactez-nous !</a>*/}
          {/*</div>*/}
          {/*<br/>*/}
          {/*<p style={{fontSize: '1em'}}>*/}
            {/*<strong>Conseil : </strong> Pour les entreprises qui apposent notre label BBP sur leurs factures,*/}
            {/*nous vous conseillons d'activer l'option "Protégez vos factures automatiquement".*/}
            {/*Un mail de rappel sera adressé à vos clients 4 jours avant l'échéance afin de leur indiquer que votre*/}
            {/*entreprise protège ses factures sur Recouvr'Up et que le paiemnt est attendu prochainement.*/}
          {/*</p>*/}
        {/*</div>*/}
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info,
    userIsLoading: state.user.userInfoIsLoading,
  }),
  {
    requestUpdateUser: userActions.requestUpdateUser,
    cancelSubscription: subscriptionActions.cancelSubscription
  }
)(TabAccount));