import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {Modal} from 'antd';
import modalsActions from '../../../../Redux/modals/actions';


class PricingModal extends Component {

  handleCancel = () => {
    this.props.hideModal('customerPricing');
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
        marginTop: '-80px'
      },
    };

    return (
      <Modal
        title={`Tarifs`}
        visible={this.props.customerPricingVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
        width={'80%'}
      >
        <div style={{margin: 50}} className="elfsight-app-e34b1ed2-d434-43f9-8a3a-2a8ee29321c6"/>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    customerPricingVisible: state.modals.customerPricingVisible
  }),
  {
    hideModal: modalsActions.hideModal
  }
)(PricingModal);