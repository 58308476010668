import React, { Component } from 'react';
import { connect, Provider } from "react-redux";
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from "connected-react-router";
import jwt from 'jsonwebtoken';
import { store, history } from './Redux/store';
import HomePage from './Components/public/HomePage';
import CustomerMain from './Components/customers/CustomerMain';
import userActions from './Redux/user/actions';
import './resources/fonts/Pro-Light/font-css/LinIconsPro-Light.css';
import AdminMain from "./Components/admin/AdminMain";
import 'antd/dist/antd.less';
import EspaceDebiteur from './Components/public/Debiteur/EspaceDebiteur';
import ReferenceDossier from './Components/public/Debiteur/ReferenceDossier';

const routes = [
  { path: "/penalites", label: "Pénalités" },
  { path: "/Amiable", label: "Amiable" },
  { path: "/Contentieux", label: "Contentieux" },
  { path: "/Judiciaire", label: "Judiciaire" },
];
class App extends Component {

  componentDidMount(){
    const script = document.createElement("script");
    script.src = "https://apps.elfsight.com/p/platform.js";
    script.defer = true;
    document.body.appendChild(script);

    const token = this.props.token || localStorage.recouvrup_token;
    if(token){
      this.props.logUser(token);
    }
  }

  render() {

    const token = this.props.token || localStorage.recouvrup_token;
    const userData = jwt.decode(token);

    const RestrictedRoute = ({exact, path, component, neededRole}) => {
      if(userData && neededRole.includes(userData.role)){
        return (
          <Route
            exact={exact}
            path={path}
            component={component}
          />
        )
      }else{
        return <Redirect to='/'/>
      }
    };

    const PublicRoute = ({exact, path, component}) => {
      if(userData){
        if(userData.role === 'ADMIN'){
          return (<Redirect to='/admin'/>);
        }else if(userData.role === 'CUSTOMER'){
          return (<Redirect to='/main'/>);
        }else{
          return (
            <Route
              exact={exact}
              path={path}
              component={component}
            />
          )
        }
      }else{
        return (
          <Route
            exact={exact}
            path={path}
            component={component}
          />
        )
      }
    };

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <Switch>
              <RestrictedRoute exact path="/admin" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/interest" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/bce" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/label" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/discount" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/documents" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/admin/pdf" component={AdminMain} neededRole={['ADMIN']}/>
              <RestrictedRoute exact path="/main" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/dashboard" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/bbp" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/penalites" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/amiable" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/contentieux" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/judiciaire" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/profile" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/subscribe" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <RestrictedRoute exact path="/order/:orderId" component={CustomerMain} neededRole={['CUSTOMER']}/>
              <PublicRoute exact path="/confirmation/:hash" component={CustomerMain}/>
              <PublicRoute exact path="/debiteur" component={EspaceDebiteur}/>
              <PublicRoute exact path="/reference-dossier" component={ReferenceDossier}/>
              <PublicRoute exact path="/" component={HomePage}/>
              <PublicRoute exact path="" component={HomePage}/>
              <Redirect to='/'/>
            </Switch>
          </div>
        </ConnectedRouter>
        <div className="elfsight-app-2d33b5f4-2792-467e-b96d-277324187778"/>
      </Provider>
    );
  }
}

export default connect(
  state => ({
    token: state.user.token
  }),
  {
    logUser: userActions.logUser
  }
)(App);