import React from 'react';
import moment from "moment";

/**
 * Check if a user is in a trial period
 * @param user
 * @return {boolean}
 */
export const trialExpired = user => {
  return (user.subscriptionType === 'Free' && moment(user.createdAt).add(7, 'days') < moment());
};

/**
 * @param user
 * @returns {boolean}
 */
export const hasAccessToSticker = user => {
  return (!trialExpired(user) && user.chartAcceptedAt !== null);
}

/**
 * Check if the user can use to the giver sticker
 * @param user
 * @param stickerType
 * @return {boolean|*}
 */
export const canUseSticker = (user, stickerType) => {
  const rules = {
    green: ['Privilege', 'Premium', 'Starter'],
    orange: ['Privilege', 'Premium'],
    red: ['Privilege']
  }

  if(user.subscriptionType === 'Free' && !trialExpired(user)){
    return true;
  }else if(user.subscriptionType !== 'Free'){
    return rules[stickerType].includes(user.subscriptionType)
  }else{
    return false;
  }
}

/**
 * Get the vat according to a region
 * @param region
 * @param vatObj = {france:1.2, domtom:1.085, ue:1}
 * @return {*|number}
 */
export const getVat = (region, vatObj) => {

  let vat = 1;

  switch(region){
    case `France métropolitaine`:
      vat = vatObj.france;
      break;
    case `Martinique - Guadeloupe - Réunion`:
      vat = vatObj.domtom;
      break;
    default:
      vat = 1;
      break;
  }

  return vat;
};