import user from './user/reducer';
import homeNavbar from './homeNavbar/reducer';
import modals from './modals/reducer';
import reminders from './reminders/reducer';
import invoices from './invoices/reducer';
import admin from './admin/reducer';
import app from './app/reducer';
import calculator from './calculator/reducer';
import order from './order/reducer';
import subscription from './subscription/reducer';
import invoicesPdf from './invoicesPdf/reducer';
import espaceDebiteur from './espaceBebiteur/reducer';
import clients from './clients/reducer';

export default {
  user,
  homeNavbar,
  modals,
  reminders,
  invoices,
  admin,
  app,
  calculator,
  order,
  subscription,
  invoicesPdf,
  espaceDebiteur,
  clients
}