import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';

import {Row, Col, Button, Divider, Table, Form} from 'antd';
import {FormInput, FormSelect} from "../../../helpers/forms";
import adminActions from '../../../Redux/admin/actions';

class AdminPdf extends Component{

  onFinish = (values) => {
    const {type, footer} = values;
    this.props.addConfigPdf(type, footer);
  };

  render(){

    const columns = [
      {
        title: `Entité`,
        dataIndex: 'type',
        sorter: (a,b) => a.type < b.type ? -1:1,
        showSorterTooltip: false
      },
      {
        title: `Texte`,
        dataIndex: 'footer',
        sorter: (a,b) => a.footer < b.footer ? -1:1,
        showSorterTooltip: false
      },
    ];

    let data = [];

    if(this.props.config.pdfConfigs) {
      this.props.config.pdfConfigs.map((row, idx) => {
        data.push(row);
      });
    }

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center'}}>
          <Col span={18} className={'shadowed-block'}>

            <h3>Pieds de page PDF</h3>

            <h4>Modifier un texte</h4>

            <Form onFinish={this.onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormSelect
                    name={`type`}
                    placeholder={`Sélectionner un texte à modifier`}
                    options={[
                      {text: `Entreprise`, value: `entreprise`},
                      {text: `Administration`, value: `administration`},
                      {text: `Particulier`, value: `particulier`},
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    type={`textArea`}
                    rules={[{required: true, message: `Ce champ est obligatoire`}]}
                    name={`footer`}
                    label={`Texte de remplacement`}
                    placeholder={`Contenu du pied de page`}
                  />
                </Col>
                <Col span={5}>
                  <Button htmlType={'submit'} type={'default'} style={{background: colors.primaryBlue, borderColor: colors.primaryBlue, color: 'white', marginTop: 25}}>Sauvegarder</Button>
                </Col>
              </Row>
            </Form>

            <Divider/>

            <Table
              rowKey={'id'}
              size={'small'}
              bordered={true}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    config: state.admin.config
  }),
  {
    addConfigPdf: adminActions.reqAddConfigPdf
  }
)(AdminPdf));