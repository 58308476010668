const actions = {
  REQ_CREATE_SUBSCRIPTION_CHECKOUT_SESSION: 'REQ_CREATE_SUBSCRIPTION_CHECKOUT_SESSION',
  TOGGLE_SUBSCRIPTION_LOADER: 'TOGGLE_SUBSCRIPTION_LOADER',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',

  toggleLoader: (isLoading) => ({
    type: actions.TOGGLE_SUBSCRIPTION_LOADER,
    payload: isLoading
  }),

  reqCreateSubscriptionCheckoutSession: (subscriptionType) => ({
    type: actions.REQ_CREATE_SUBSCRIPTION_CHECKOUT_SESSION,
    payload: {subscriptionType}
  }),

  cancelSubscription: () => ({
    type: actions.CANCEL_SUBSCRIPTION
  })

};

export default actions;
