import styled from 'styled-components';
import { device } from '../../../helpers/deviceBreakpoints';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px ${colors.primaryGreen};
  
  ul{
    margin: 0;
    padding: 0;
  }
  
  li {
    text-align: center;
    display: inline-block;
    margin: 20px 25px;
    @media ${device.laptopL}{
      margin: 10px 15px;
    }
  }
  
  .main-logo{
    min-width: 150px;
    width: 100%;
  }
  
  .menu-icon{
    font-size: 24px;
    color: ${colors.textGrey};
    @media ${device.laptopL}{
      font-size: 20px;
    }
  }
  
  .menu-title{
    font-weight: bold;
    color: ${colors.textGrey};
    @media ${device.laptopL}{
      font-size: 0.9em;
    }
  }
  
  .menu-button:hover, .menu-button.active{
    .menu-icon{
      color: ${colors.primaryGreen} !important;
    }
    .menu-title{
      color: ${colors.primaryGreen} !important;
    }
  }
  
`;

export default StyleWrapper;