import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  prices: null,
  documents: null
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.UPDATE_PRICE_CONFIG:
      newState.prices = action.payload;
      return newState;

    case actions.UPDATE_DOCUMENT_CONFIG:
      newState.documents = action.payload;
      return newState;

    default:
      return state;
  }
}