import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button, Form } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import usersActions from '../../../Redux/user/actions';
import { colors } from '../../../helpers/appColors';
import { FormInput } from '../../../helpers/forms';
import RecoverPwd from "./RecoverPwd";


class LoginModal extends Component {

  handleCancel = () => {
    this.props.hideModal('homeLogin');
  };

  onFinish = (values) => {
    this.props.requestLogin(values);
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
      h2: {
        borderBottom: `solid 1px ${colors.primaryGreen}`,
        fontFamily: 'helvetica neue',
        fontWeight: 200,
        fontSize: 26,
        color: '#4b5258 !important',
        paddingBottom: 10
      },
      em: {
        fontSize: 12,
        marginBottom: 10,
        display: 'block'
      },
      input: {
        textAlign: 'center',
        marginTop: 5
      }
    };

    return (
      <Modal
        title={`Connexion`}
        visible={this.props.homeLoginVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >
        <RecoverPwd/>
        <h2 style={style.h2}>J'ai déjà un compte</h2>
        <Form onFinish={this.onFinish}>
          <FormInput
            name={'email'}
            rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
            label={'Mon adresse email :'}
            placeholder={'Email'}
            style={style.input}
          />
          <FormInput
            name={'password'}
            rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
            label={'Mon mot de passe :'}
            placeholder={'Mot de passe'}
            style={style.input}
            type={'password'}
          />

          <a onClick={() => this.props.showModal('homeRecoverPwd')}>J'ai oublié mon mot de passe</a>

          <br/>
          <br/>
          <Button type='primary' htmlType={'submit'}>Je me connecte</Button>
        </Form>

      </Modal>
    )
  }
}

export default connect(
  state => ({
    homeLoginVisible: state.modals.homeLoginVisible
  }),
  {
    hideModal: modalsActions.hideModal,
    showModal: modalsActions.showModal,
    requestLogin: usersActions.requestLogin
  }
)(LoginModal);