export const colors = {
  primaryGreen: `#3db27a`,
  primaryOrange: `#f0ad4e`,
  primaryBlue: `#428bca`,
  secondaryBlue: `#7fbff7`,
  primaryRed: `#ea5e76`,
  textGrey: `#54555a`,
  textRed: `#d24e33`,
  textLightGrey: `#b2b2b2`,
  debtorBtn: `#f1201b`,
  backegroundGrey: `#f0f0f0`,
  primaryBlack : `#000000`,
  textColor : "#000000d9",
  greenHover : `#e1f5e7`,
  pending : '#ffecde',
  paid : '#e6fbec',
  abandoned: '#fde0e2',
};