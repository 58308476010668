import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import userActions from '../user/actions';
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";


export function* createSubscriptionCheckoutSession() {

  yield takeLatest(actions.REQ_CREATE_SUBSCRIPTION_CHECKOUT_SESSION, function*({payload}) {

    try {

      yield put(actions.toggleLoader(true));

      const {subscriptionType} = payload;

      const response = yield call(ApiProvider.axiosWithToken.post, `/orders/create-subscription-checkout-session`, {
        subscriptionType
      });

      const {sessionUrl} = response.data;

      if(sessionUrl){
        window.location.href = sessionUrl;
      }else{
        yield put(actions.toggleLoader(false));
        notificationHelper.showError('SERVER_ISSUE');
      }


    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqCancelSubscription() {

  yield takeLatest(actions.CANCEL_SUBSCRIPTION, function*({payload}) {

    try {

      yield call(ApiProvider.axiosWithToken.post, `/users/cancel-subscription`);

      yield put(userActions.fetchUser());

      notificationHelper.showSuccess('Nickel !', `Votre demande de résiliation a bien été prise en compte. Votre offre sera résilié à la date anniversaire de votre abonnement.`, 3);


    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createSubscriptionCheckoutSession),
    fork(reqCancelSubscription),
  ])
}
