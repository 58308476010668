const actions = {
  FETCH_USERS: "FETCH_USERS",
  UPDATE_USERS: 'UPDATE_USERS',
  TOGGLE_USERS_LOADER: 'TOGGLE_USERS_LOADER',
  FETCH_CONFIG: "FETCH_CONFIG",
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  TOGGLE_CONFIG_LOADER: 'TOGGLE_CONFIG_LOADER',
  REQ_ADD_RATE: 'REQ_ADD_RATE',
  REQ_DELETE_RATE: 'REQ_DELETE_RATE',
  REQ_EDIT_RATE: 'REQ_EDIT_RATE',
  REQ_ADD_CONFIG_DOCUMENT: 'REQ_ADD_CONFIG_DOCUMENT',
  REQ_DELETE_CONFIG_DOCUMENT: 'REQ_DELETE_CONFIG_DOCUMENT',
  REQ_ADD_DISCOUNT_CODE: 'REQ_ADD_DISCOUNT_CODE',
  REQ_DELETE_DISCOUNT_CODE: 'REQ_DELETE_DISCOUNT_CODE',
  REQ_EDIT_DISCOUNT_CODE:'REQ_EDIT_DISCOUNT_CODE',
  REQ_ADD_CONFIG_PDF:'REQ_ADD_CONFIG_PDF',
  REQ_ADD_USER: 'REQ_ADD_USER',
  REQ_DELETE_USER: 'REQ_DELETE_USER',
  REQ_CHANGE_USER: 'REQ_CHANGE_USER',
  REQ_UPDATE_USER_PREPAID_AMOUNT: 'REQ_UPDATE_USER_PREPAID_AMOUNT',

  fetchUsers: () => ({
    type: actions.FETCH_USERS
  }),

  updateUsers: users => ({
    type: actions.UPDATE_USERS,
    payload: users
  }),

  toggleUsersLoader: visible => ({
    type: actions.TOGGLE_USERS_LOADER,
    payload: visible
  }),

  fetchConfig: () => ({
    type: actions.FETCH_CONFIG
  }),

  updateConfig: adminData => ({
    type: actions.UPDATE_CONFIG,
    payload: adminData
  }),

  toggleConfigLoader: visible => ({
    type: actions.TOGGLE_CONFIG_LOADER,
    payload: visible
  }),

  reqAddRate: (type, value1, value2, beginAt) => ({
    type: actions.REQ_ADD_RATE,
    payload: {type, value1, value2, beginAt}
  }),

  reqDeleteRate: (id) => ({
    type: actions.REQ_DELETE_RATE,
    payload: {id}
  }),

  reqEditRate: (value1, value2, beginAt, id) => ({
    type: actions.REQ_EDIT_RATE,
    payload: {value1, value2, beginAt, id}
  }),

  reqAddDocument: (name, file, type) => ({
    type: actions.REQ_ADD_CONFIG_DOCUMENT,
    payload: {name, file, type}
  }),

  reqAddDiscountCode: (beginAt, endAt, code, value, referralEmail) => ({
    type: actions.REQ_ADD_DISCOUNT_CODE,
    payload: {beginAt, endAt, code, value, referralEmail}
  }),

  reqDeleteDiscountCode: (id) => ({
    type: actions.REQ_DELETE_DISCOUNT_CODE,
    payload: {id}
  }),

  reqEditDiscountCode: (beginAt, endAt, code, value, referralEmail, id) => ({
    type: actions.REQ_EDIT_DISCOUNT_CODE,
    payload: {id, beginAt, endAt, code, value, referralEmail}
  }),

  reqAddConfigPdf: (type, footer) => ({
    type: actions.REQ_ADD_CONFIG_PDF,
    payload: {type, footer}
  }),

  reqAddUser: (email, pwd) => ({
    type: actions.REQ_ADD_USER,
    payload: {email, pwd}
  }),

  reqDeleteUser: (id) => ({
    type: actions.REQ_DELETE_USER,
    payload: {id}
  }),

  reqChangeUser: (id) => ({
    type: actions.REQ_CHANGE_USER,
    payload: {id}
  }),

  reqUpdateUserPrepaidAmount: (id, prepaidAmount) => ({
    type: actions.REQ_UPDATE_USER_PREPAID_AMOUNT,
    payload: {id, prepaidAmount}
  })
};

export default actions;
