import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import NumberFormat from "react-number-format";
import StyleWrapper from './StyleWrapper';
import { colors } from '../../../helpers/appColors';

import {Row, Col, Button, Divider, Table, Form} from 'antd';
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import moment from "moment";
import {DeleteButton, FormInput} from "../../../helpers/forms";
import adminActions from '../../../Redux/admin/actions';

class AdminDiscount extends Component{
  state = {
    editingKey: null,
    beginAt: null,
    endAt: null,
    code: null,
    value: null,
    referralEmail: null
  };

  tableFormRef = React.createRef();

  saveRow = (values) => {
    const { beginAt, endAt, code, value, referralEmail, discountCodeId } = values;
    this.props.editDiscountCode(beginAt, endAt, code, value, referralEmail, discountCodeId);
    this.setState({
      editingKey: null,
      beginAt: null,
      endAt: null,
      code: null,
      value: null,
      referralEmail: null
    });
  };

  setEditingValues = (row) => {
    this.setState({
      editingKey: row.id,
      beginAt: moment(row.beginAt),
      endAt: moment(row.endAt),
      code: row.code,
      value: row.value,
      referralEmail: row.referralEmail
    },this.tableFormRef.current.setFieldsValue({
      beginAt: moment(row.beginAt),
      endAt: moment(row.endAt),
      code: row.code,
      value: row.value,
      referralEmail: row.referralEmail,
      discountCodeId: row.id
    }));

  };

  cancelEdition = () => {
    this.setState({
      editingKey: null,
      beginAt: null,
      endAt: null,
      code: null,
      value: null,
      referralEmail: null
    });
  };

  onFinish = (values) => {
    const {beginAt, endAt, code, value, referralEmail} = values;
    this.props.addDiscountCode(beginAt, endAt, code, value, referralEmail)
  };

  render(){

    const columns = [
      {
        title: `Date de début`,
        dataIndex: 'beginAt',
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <FormInput
            name={`beginAt`}
            type={`datePicker`}
            rules={[{ required: true, message: `Ce champ est obligatoire` }]}
          />
        ):(
          <span>{moment(record.beginAt).format('DD/MM/YYYY')}</span>
        ),
        sorter: (a,b) => a.beginAt < b.beginAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: `Date de fin`,
        dataIndex: 'endAt',
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <FormInput
            name={`endAt`}
            type={`datePicker`}
            rules={[{ required: true, message: `Ce champ est obligatoire` }]}
          />
        ):(
          <span>{moment(record.endAt).format('DD/MM/YYYY')}</span>
        ),
        sorter: (a,b) => a.endAt < b.endAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Code',
        dataIndex: 'code',
        sorter: (a,b) => a.code < b.code ? -1:1,
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <FormInput
            name={`code`}
            type={`text`}
            rules={[{ required: true, message: `Ce champ est obligatoire` }]}
          />
        ):(
          <span>{text}</span>
        ),
        showSorterTooltip: false
      },
      {
        title: 'Taux',
        dataIndex: 'value',
        sorter: (a,b) => a.value < b.value ? -1:1,
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <span>
            <FormInput
              name='value'
              rules={[{required: true, message: `Ce champ est obligatoire`}]}
              suffix={'%'}
              size='small'
            />
          </span>
        ):(
          <span>
            <NumberFormat
              displayType={'text'}
              value={text}
              decimalSeparator={','}
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator={' '}
              suffix={'%'}
            />
          </span>
        ),
        showSorterTooltip: false
      },
      {
        title: `Mail (en Cci)`,
        dataIndex: 'referralEmail',
        sorter: (a,b) => a.referralEmail < b.referralEmail ? -1:1,
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <FormInput
            name={`referralEmail`}
            type={`text`}
            rules={[{ required: true, message: `Ce champ est obligatoire` }]}
          />
        ):(
          <span>{text}</span>
        ),
        showSorterTooltip: false
      },
      {
        title: (
          <span>
            Actions
          </span>
        ),
        dataIndex: 'id',
        width: 100,
        render: (text, record, idx) =>  this.state.editingKey === record.id ? (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              type='default'
              style={{
                backgroundColor: colors.primaryGreen,
                borderColor: colors.primaryGreen,
                color: 'white'
              }}
              size='small'
              htmlType={`submit`}
            >
              <CheckOutlined />
            </Button>
            <Button
              type='default'
              href="#"
              size='small'
              onClick={() => this.cancelEdition()}
            >
              <CloseOutlined />
            </Button>
          </div>
        ):(
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              type='default'
              style={this.state.editingKey === null ?{
                backgroundColor: colors.primaryBlue,
                borderColor: colors.primaryBlue,
                color: 'white'
              }:null}
              size='small'
              href='javascript:void(0);'
              onClick={() => this.setEditingValues(record)}
              disabled={this.state.editingKey !== null}
            >
              <EditOutlined/>
            </Button>
            <DeleteButton
              disabled={this.state.editingKey !== null}
              onCancel={() => false}
              onConfirm={() => this.props.deleteDiscountCode(record.id)}
              component={
                <Button
                  type='default'
                  style={this.state.editingKey === null ? {
                    backgroundColor: colors.primaryRed,
                    borderColor: colors.primaryRed,
                    color: 'white'
                  }:null}
                  size='small'
                  disabled={this.state.editingKey !== null}
                >
                  <DeleteOutlined />
                </Button>
              }
            />
          </div>
        ),
      },
    ];

    let data = [];


    if(this.props.config.discountCodes) {
      this.props.config.discountCodes.map((code, idx) => {
        data.push(code);
      });
    }

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center'}}>
          <Col span={18} className={'shadowed-block'}>

            <h3>Codes promotion</h3>

            <h4>Ajouter un code</h4>

            <Form onFinish={this.onFinish}>
              <Row gutter={16}>
                <Col span={4}>
                  <FormInput label={`Date de début`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Date de début`} type='datePicker' name='beginAt'/>
                </Col>
                <Col span={4}>
                  <FormInput label={`Date de fin`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Date de fin`} type='datePicker' name='endAt'/>
                </Col>
                <Col span={4}>
                  <FormInput label={`Code`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Code`} type='text' name='code' />
                </Col>
                <Col span={4}>
                  <FormInput label={`Taux`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Taux`} type='number' name='value' suffix={'%'} style={{marginTop: 4}} size={'small'}/>
                </Col>
                <Col span={4}>
                  <FormInput label={`Mail (en Cci)`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Mail (en Cci)`} type='text' name='referralEmail' />
                </Col>
                <Col span={4}>
                  <Button htmlType={'submit'} type={'default'} style={{background: colors.primaryBlue, borderColor: colors.primaryBlue, color: 'white', marginTop: 25}}>Sauvegarder</Button>
                </Col>
              </Row>
            </Form>

            <Divider/>

            <Form onFinish={this.saveRow} ref={this.tableFormRef}>
              <Form.Item name={'discountCodeId'} hidden={true}/>
              <Table
                rowKey={'id'}
                size={'small'}
                bordered={true}
                columns={columns}
                dataSource={data}
              />
            </Form>
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    config: state.admin.config
  }),
  {
    addDiscountCode: adminActions.reqAddDiscountCode,
    editDiscountCode: adminActions.reqEditDiscountCode,
    deleteDiscountCode: adminActions.reqDeleteDiscountCode
  }
)(AdminDiscount));