import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal, Input, Button, Row, Col, Divider, Select, Checkbox, Form } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import usersActions from '../../../Redux/user/actions';
import { FormInput, FormCheckbox, FormSelect } from '../../../helpers/forms';


class NewProfileModal extends Component {

  state = {
    siren: null
  };

  formRef = React.createRef();

  handleOnSirenChange = (e) => {
    const {value} = e.target;

    if(value.length === 9){
      this.props.fetchCompanyInfo(value);
    }
  };

  componentDidUpdate(prevProps, prevState){
    if(prevProps.companyInfo !== this.props.companyInfo){
      this.formRef.current.setFieldsValue(this.props.companyInfo);
    }
  }


  onFinish = (values) => {
    this.props.requestInitialUpdateUser(values)
  };

  render(){

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    const { user } = this.props;

    const defaultValues = {
      companyRcs: user ? user.companyRcs : null,
      companyName: user ? user.companyName : null,
      companyAddress: user ? user.companyAddress : null,
      companyZip: user ? user.companyZip : null,
      companyCity: user ? user.companyCity : null,
      companyVatNumber: user ? user.companyVatNumber : null,
      lastname: user ? user.lastname : null,
      firstname: user ? user.firstname : null,
      companyPhone: user ? user.companyPhone : null,
      region: user ? user.region : 'France métropolitaine',
      newsletterAccepted: user ? user.newsletterAccepted : false
    };

    const regions = [
      {text: 'France métropolitaine', value: 'France métropolitaine'},
      {text: 'Martinique - Guadeloupe - Réunion', value: 'Martinique - Guadeloupe - Réunion'},
      {text: 'UE - Hors France', value: 'UE - Hors France'},
      {text: 'Hors UE', value: 'Hors UE'},
    ];
    return (
      <Modal
        title={`Veuillez renseigner vos coordonnées`}
        visible={this.props.customerNewProfileVisible}
        footer={null}
        style={style.modal}
        closable={false}
      >

        <Form
          name="update-profile"
          initialValues={defaultValues}
          onFinish={this.onFinish}
          ref={this.formRef}
        >

          <Row gutter={16}>
            <Col span={24}>
              <FormInput
                name={'companyRcs'}
                label={'SIREN (9 chiffres) :'}
                placeholder={'SIREN (9 chiffres)'}
                style={{width: 200}}
                onChange={this.handleOnSirenChange}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={23} offset={1} style={{borderLeft: 'solid 2px lightgrey'}}>
              <FormInput
                name={'companyName'}
                rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                label={'Raison sociale :'}
                placeholder={'Raison sociale'}
              />
              <FormInput
                name={'companyAddress'}
                rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                label={'Addresse :'}
                placeholder={'Adresse (Maximum 45 caractères)'}
                maxLength={44}
                type={'textArea'}
              />

              <Row gutter={8}>
                <Col span={12}>
                  <FormInput
                    name={'companyZip'}
                    rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                    label={'Code postal :'}
                    placeholder={'Code postal'}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    name={'companyCity'}
                    rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                    label={'Localité :'}
                    placeholder={'Localité'}
                  />
                </Col>
              </Row>
              <FormInput
                name={'companyVatNumber'}
                label={'N° de TVA intra communautaire :'}
                placeholder={'N° de TVA intra communautaire'}
              />
            </Col>
          </Row>

          <Row gutter={16} style={{marginTop: 10}}>
            <Col span={12}>
              <FormInput
                name={'lastname'}
                rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                label={'Nom :'}
                placeholder={'Nom'}
              />
            </Col>
            <Col span={12}>
              <FormInput
                name={'firstname'}
                rules={[{ required: true, message: 'Veuillez saisir une valeur' }]}
                label={'Prénom :'}
                placeholder={'Prénom'}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormInput
                name={'companyPhone'}
                label={'Téléphone :'}
                placeholder={'Téléphone'}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <FormSelect
                name={'region'}
                options={regions}
                placeholder={'Zone géographique'}
                label={'Zone géographique :'}
                rules={[{ required: true, message: 'Veuillez sélectionner une valeur' }]}
                style={{width: '100%', marginTop: 5}}
              />
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              <FormCheckbox
                name={'newsletterAccepted'}
                id={'newsletterAccepted'}
                label={`J'accepte de recevoir les newsletters`}
              />
            </Col>
          </Row>

          <Divider/>

          <div style={{textAlign: 'center'}}>
            <Button type='default' onClick={() => this.props.logout()} style={{marginRight: 5}}>Se déconnecter</Button>
            <Button type='primary' htmlType="submit">Enregistrer</Button>
          </div>

        </Form>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    customerNewProfileVisible: state.modals.customerNewProfileVisible,
    user: state.user.info,
    companyInfo: state.user.companyInfo
  }),
  {
    hideModal: modalsActions.hideModal,
    requestInitialUpdateUser: usersActions.requestInitialUpdateUser,
    logout: usersActions.logout,
    fetchCompanyInfo: usersActions.reqFetchCompanyInfo
  }
)(NewProfileModal);