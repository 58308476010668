import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import Wrapper from './Wrapper';
import appActions from "../../../Redux/app/actions";
import _ from "lodash";

class FooterNavbar extends Component{

  componentDidMount() {
    this.props.fetchAppConfig();
  }

  render(){
    if(!this.props.documents) return <></>
    const cgu = _.find(this.props.documents, {name:'cgu'});
    const mentionsLegales = _.find(this.props.documents, {name:'mentions_legales'});
    return (
      <Wrapper>
        <ul id="menu">
          <li className="onglet"><a href={mentionsLegales.url} target='_blank' title='Mentions légales'>Mentions légales</a></li>
          <li className="onglet"><a href={cgu.url} target='_blank' title="Conditions générales d'utilisation">Conditions générales d'utilisation</a></li>
        </ul>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    documents: state.app.documents
  }),
  {
    fetchAppConfig: appActions.fetchAppConfig,
  }
)(FooterNavbar);