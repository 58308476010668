import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.header`
  z-index:999;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y:scroll;
  overflow-x:hidden;
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items : center;
  border-right: 0.5px solid;
  border-right: 1px solid lightgray;
  
  ul{
    margin: 0;
    padding: 0;
  }
  
  .logo{
      width: auto;
      padding: 20px 10px;
      text-align: center;
      margin-top: 18px;

  }

  hr{
    width: 65%;
  }

  li {
    text-align: center;
    display: inline-block;
    margin: 19px 25px;
  }
  
  .main-logo{
    min-width: 65px;
    width: 90%;
  }
  
  .menu-icon{
    font-size: 24px;
    color: ${colors.textGrey};
  }
  
  .menu-title{
    font-weight: bold;
    color: ${colors.textGrey};
  }
  
  .menu-button:hover, .menu-button.active{
    .menu-icon{
      color: ${colors.primaryGreen} !important;
    }
    .menu-title{
      color: ${colors.primaryGreen} !important;
    }
  }

  .active{
    color: ${colors.primaryGreen};
  }
  
`;

export default StyleWrapper;