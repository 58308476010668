const actions = {
  REQ_COMPUTE: 'REQ_COMPUTE',
  UPDATE_PENALTIES_INFO: 'UPDATE_PENALTIES_INFO',
  FETCH_MY_COMPANIES: 'FETCH_MY_COMPANIES',
  UPDATE_MY_COMPANIES: 'UPDATE_MY_COMPANIES',
  UPDATE_DEFAULT_FORM_VALUES: 'UPDATE_DEFAULT_FORM_VALUES',
  REQ_GENERATE_ORDER: 'REQ_GENERATE_ORDER',
  REQ_INVOICES: 'REQ_INVOICES',

  reqInvoices: (data) => ({
    type: actions.REQ_INVOICES,
    payload: data
  }),

  reqCompute: (formValues) => ({
    type: actions.REQ_COMPUTE,
    payload: formValues
  }),

  updatePenaltiesInfo: (globalBalance, globalPenalties) => ({
    type: actions.UPDATE_PENALTIES_INFO,
    payload: {globalPenalties, globalBalance}
  }),

  fetchMyCompanies: () => ({
    type: actions.FETCH_MY_COMPANIES,
  }),

  updateMyCompanies: (companies) => ({
    type: actions.UPDATE_MY_COMPANIES,
    payload: companies
  }),

  updateDefaultFormValues: (formValues) => ({
    type: actions.UPDATE_DEFAULT_FORM_VALUES,
    payload: formValues
  }),

  reqGenerateOrder: (formValues) => ({
    type: actions.REQ_GENERATE_ORDER,
    payload: {formValues}
  })

};

export default actions;
