import React, { Component } from "react";
import { Button, Anchor } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import connect from "react-redux/es/connect/connect";
import Wrapper from './Wrapper';
import BurgerMenu from './BurgerMenu';
import mainLogo from '../../../resources/images/logo-recouvrup.png';
import homeNavbarActions from "../../../Redux/homeNavbar/actions";
import modalsActions from '../../../Redux/modals/actions';
import appActions from "../../../Redux/app/actions";
import _ from "lodash";

const { Link } = Anchor;


class HomeNavbar extends Component{

  componentDidMount() {
    this.props.fetchAppConfig();
  }

  render(){
    if(!this.props.documents) return <></>

    const charte = _.find(this.props.documents, {name:'charte_bbp'});

    return (
      <Wrapper>
        <BurgerMenu />
        <a href='/'><img alt='' src={mainLogo} title="RECOUVR'UP" id='main-logo'/></a>
        <Anchor affix={false} showInkInFixed={true}>
          <ul id="top-menu">
            <li className="onglet"><Link href="/#bloc-accompagne" title='NOTRE CABINET'/></li>
            <li className="onglet"><Link href="/#bloc-solution" title='SOLUTIONS'/></li>
            <li className="onglet">
              <a target="_blank" rel="noreferrer" href={charte.url}>
                LA CHARTE BBP®
              </a>
            </li>
            <li className="onglet"><Link href="/#bloc-tarif" title='TARIFS'/></li>
            <li className="onglet"><a target="_blank" rel="noreferrer" href="https://recouvrup.zendesk.com/hc/fr">CENTRE D'AIDE</a>
            </li>
            <li className="onglet rm-border"><Link className="debtor-btn" href="/debiteur" title='DÉBITEUR'/></li>
          </ul>
        </Anchor>
        <MenuOutlined id="burger-menu" onClick={() => {this.props.toggleBurger(!this.props.burgerVisible)}}/>
        <div style={{position: 'absolute', right: 10}}>
          <Button type='primary' id='btn-connection' onClick={() => {this.props.showModal('homeLogin')}}>SE CONNECTER</Button><br/>
          <Button type='ghost' id='btn-inscription'  onClick={() => {this.props.showModal('homeSignup')}}>INSCRIPTION</Button>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({
    documents: state.app.documents,
    burgerVisible: state.homeNavbar.burgerVisible
  }),
  {
    fetchAppConfig: appActions.fetchAppConfig,
    toggleBurger: homeNavbarActions.toggleBurger,
    showModal: modalsActions.showModal
  }
)(HomeNavbar);