import styled from 'styled-components';

import backgroundImage from '../../resources/images/general/bg.svg';

const StyleWrapper = styled.div`
  min-width: 1350px;
  
  #customer-main{
    min-height: 81vh;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .shadowed-block{
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 5px;
    background-color: white;
    border: solid rgba(0,0,0,0.11) 1px;
    -webkit-box-shadow: 5px 4px 8px 1px rgb(0 0 0 / 7%);
    -moz-box-shadow: 5px 4px 8px 1px rgba(0,0,0,0.07);
    box-shadow: 5px 4px 8px 1px rgb(0 0 0 / 7%);
  }
  
  .grey-block{
    background: #f9f9f9;
    border-radius: 5px;
    padding: 20px 40px !important;
    margin-bottom: 20px;
  }
`;

export default StyleWrapper;