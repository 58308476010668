import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Modal } from 'antd';
import modalsActions from '../../../../Redux/modals/actions';


class RdvModal extends Component {

  handleCancel = () => {
    this.props.hideModal('customerRdv');
  };

  render(){

    const style = {
      modal: {
       textAlign: 'center',
      },
    };

    return (
      <Modal
        title={`Plannifier un RDV téléphonique`}
        visible={this.props.customerRdvVisible}
        onCancel={this.handleCancel}
        footer={null}
        style={style.modal}
      >

      </Modal>
    )
  }
}

export default connect(
  state => ({
    customerRdvVisible: state.modals.customerRdvVisible
  }),
  {
    hideModal: modalsActions.hideModal
  }
)(RdvModal);