import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import invoicesActions from "./../invoices/actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import moment from "moment";

function* removeFile(action) {
  try {
    const path = action.payload;
    const formData = new FormData();
    formData.append("path", path);

    yield all([
      put(actions.isInLoading(true)),
      put(actions.removePDFSuccess(false)),
      call(
        ApiProvider.axiosWithToken.post,
        "/invoices-file/remove",formData
      ),
      put(actions.removePDFSuccess(true)),
      put(actions.isInLoading(false))
    ]);

  } catch (error) {
    const errorMessage = error.response.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
    yield put(actions.isInLoading(false));
  }
}

function* removePdf(action) {
  try {
    yield all([
      put(actions.isInLoading(true)),
      put(actions.removePDFSuccess(false)),
      call(
        ApiProvider.axiosWithToken.delete,
        `/invoices-facture/remove/${action.payload.data.id}`
      ),
     
    ]);
    yield all([
      put(actions.removePDFSuccess(true)),
      put(invoicesActions.fetchInvoices()),
      put(actions.isInLoading(false))
    ])
  } catch (error) {
    const errorMessage = error.response.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
    yield put(actions.isInLoading(false));
  }
}

function* uploadPDF(action) {
  try {
    yield put(actions.isInLoading(true));
    const { pdf, invoiceId, number, isDashboard } = action.payload.data;
    const formData = new FormData();

    formData.append("pdf", pdf.originFileObj);
    formData.append("invoiceId", invoiceId || "");
    formData.append("number", number || "");

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const response = yield call(
      ApiProvider.axiosWithToken.post,
      "/invoices-facture/upload",
      formData,
      config
    );

    if (isDashboard) {
      yield put(invoicesActions.fetchInvoices());
    } else {
      yield put(actions.uploadPDFSuccess(response.data.url));
    }
    yield put(actions.isInLoading(false));
  } catch (error) {
    const errorMessage = error.response.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
    yield put(actions.isInLoading(false));
  }
}

export function* uploadPDFRequest() {
  yield takeLatest(actions.UPLOAD_PDF_REQUEST, uploadPDF);
}

export function* removePDFRequest() {
  yield takeLatest(actions.REMOVE_PDF_REQUEST, removePdf);
}

export function* removeFileRequest() {
  yield takeLatest(actions.REMOVE_FILE_REQUEST, removeFile);
}

export default function* rootSaga() {
  yield all([
    fork(uploadPDFRequest),
    fork(removePDFRequest),
    fork(removeFileRequest),
  ]);
}
