/* eslint-disable require-yield */
import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import orderActions from "../order/actions";
import remindersActions from "../reminders/actions";
import userActions from "../user/actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";
import history from "../../helpers/history";

export function* fetchOrder() {

  yield takeLatest(actions.REQ_FETCH_ORDER, function*({payload}) {

    try {

      const response = yield call(ApiProvider.axiosWithToken.get, `/orders/${payload}`);

      const { order,documentToMail } = response.data;
      console.log('documentToMail',documentToMail);
      yield put(actions.updateOrder(order));
      yield put(actions.updatePdf(documentToMail));

    } catch (error) {

      console.error(error);

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* fetchMyOrders() {

  yield takeLatest(actions.REQ_FETCH_MY_ORDERS, function*() {
    try {
      yield put(actions.toggleLoader(true));
      const response = yield call(ApiProvider.axiosWithToken.get, `/orders`);
      const { myOrders } = response.data;
      yield put(actions.updateMyOrders(myOrders));
      yield put(actions.toggleLoader(false));
    } catch (error) {
      console.error(error);
      yield put(actions.toggleLoader(false));
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* finalizeOrder() {

  yield takeLatest(actions.REQ_FINALIZE_ORDER, function*({payload}) {

    try {

      yield put(actions.toggleLoader(true));

      const {type, orderId, hash , checkedMandateOption,isCredit} = payload;

      yield call(ApiProvider.axiosWithToken.post, `/orders/finalize`, {
        type,
        orderId,
        hash,
        checkedMandateOption
      });

      yield put(actions.toggleLoader(false));
      yield put(actions.toggleOrderSuccess(true));

      if(isCredit){
          notificationHelper.showSuccess("Success","Votre compte prépayé a bien été crédité.",4);
      }

      yield put(userActions.fetchUser());

    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}


export function* createCheckoutSession() {

  yield takeLatest(actions.REQ_CREATE_CHECKOUT_SESSION, function*({payload}) {

    try {

      yield put(actions.toggleLoader(true));

      const {orderId,switchChecked,isCredit} = payload;

      const response = yield call(ApiProvider.axiosWithToken.post, `/orders/create-checkout-session`, {
        orderId,
        switchChecked,
        isCredit
      });

      const {sessionUrl} = response.data;

      if(sessionUrl){
        window.location.href = sessionUrl;
      }else{
        yield put(actions.toggleLoader(false));
        notificationHelper.showError('SERVER_ISSUE');
      }


    } catch (error) {

      console.error(error);

      yield put(actions.toggleLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqCreateCreditOrder(){
  yield takeLatest(orderActions.REQ_CREATE_CREDIT_ORDER, function*({payload}) {

    try {
      yield put(actions.toggleLoader(true));
      const response = yield call(ApiProvider.axiosWithToken.post,
        "/orders/credit",
        {prepaidAmount: payload});

      const { order } = response.data;
      yield put(actions.reqCreateCheckoutSession(order.id,false,true))

    } catch (error) {

      console.error(error);
      yield put(actions.toggleLoader(false));
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqSaveOrder(){
  yield takeLatest(orderActions.SAVE_ORDER, function*({payload}) {

    try {
       const {file,data} = payload;
       const formData = new FormData();
       formData.append('data',JSON.stringify(data));
       if (file)
        formData.append("file", file.originFileObj);

       const config = { headers: { "Content-Type": "multipart/form-data" } };

       yield call(
          ApiProvider.axiosWithToken.post,
          "/reminder/save-order",
          formData,
          config
       )

       notificationHelper.showSuccess(
        "Success",
        "Le suivi a bien été enregistré.",
        4
       )
       yield put(actions._openModal(false));
       yield put(remindersActions.reqFetchReminders());

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* reqFetchHistory (){
  yield takeLatest(orderActions.REQ_FETCH_HISTORY, function*({payload}){
    try {
      const response = yield call(ApiProvider.axiosWithToken.get, `/history/${payload}`);
      const {histories} = response.data;

      yield put(actions.updateHistory(histories));
      
    } catch (error) {
      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else{
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  })
}


export default function* rootSaga() {
  yield all([
    fork(fetchOrder),
    fork(finalizeOrder),
    fork(createCheckoutSession),
    fork(fetchMyOrders),
    fork(reqCreateCreditOrder),
    fork(reqSaveOrder),
    fork(reqFetchHistory)
  ])
}
