import actions from "./actions";
import { cloneDeep } from "lodash";

const initState = {
  homeLoginVisible: false,
  homeSignupVisible: false,
  homeVideoVisible: false,
  customerRdvVisible: false,
  customerPricingVisible: false,
  customerContactVisible:false,
  customerNewProfileVisible:false,
  customerWrittenProofVisible: false,
  customerMailDirectRecouvrementVisible: false,
  customerCourrierDirectRecouvrementVisible: false,
  customerMiseEnDemeureDirectRecouvrementVisible: false,
  customerInjonctionVisible: false,
  customerReminderVisible: false,
  customerUnpaidNoticeVisible: false,
  customerMiseEnDemeureVisible: false,
  adminAddUserVisible: false,
  customerCustomInformationVisible: false,
  customerCustomText: 'Title',
  customerCustomTitle: 'Text',
  homeRecoverPwdVisible: false,
  adminUpdateUserVisible: false
};

export default function rootReducer(state = initState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case actions.SHOW_MODAL:
      for(const key of Object.keys(initState)){
        if(key.includes('Visible')) newState[key] = false;
      }
      newState[`${action.payload}Visible`] = true;
      return newState;
    case actions.HIDE_ALL_MODAL:
      for(const key of Object.keys(initState)){
        if(key.includes('Visible')) newState[key] = false;
      }
      return newState;
    case actions.SHOW_CUSTOM_MODAL:
      newState[`customerCustomInformationVisible`] = true;
      newState[`customerCustomText`] = action.payload.text;
      newState[`customerCustomTitle`] = action.payload.title;
      return newState;
    case actions.HIDE_MODAL:
      newState[`${action.payload}Visible`] = false;
      return newState;

    default:
      return state;
  }
}
