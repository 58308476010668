import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import NumberFormat from "react-number-format";

import {colors} from '../../../helpers/appColors';

class PricingTag extends Component {

  render(){

    const style = {
      tag : {
        width: '100%',
        color: 'white',
        padding: 10,
        textAlign: 'center'
      }
    };

    return (

      <div style={style.tag}>
        <strong style={{borderBottom: 'solid white 1px'}}>{this.props.title}</strong>
        <br/>
        <strong>{isNaN(this.props.subtitle) ? this.props.subtitle : <NumberFormat
          displayType={'text'}
          value={this.props.subtitle}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€ HT'}
        />}</strong>
        {
          this.props.active && (
            <span style={{color: '#c53232'}}><br/>Statut en cours</span>
          )
        }
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  {
  }
)(PricingTag);