import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import ApiProvider from "./../../helpers/api";
import * as notificationHelper from "../../helpers/notificationHelper";


export function* fetchAppPrices() {

  yield takeLatest(actions.FETCH_APP_CONFIG, function*() {
    try {

      const response = yield call(ApiProvider.axiosWithToken.get, "/config-prices");

      yield put(actions.updatePriceConfig(response.data));

      const response2 = yield call(ApiProvider.axiosWithToken.get, "/config-documents");

      yield put(actions.updateDocumentConfig(response2.data));

    } catch (error) {

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}


export default function* rootSaga() {
  yield all([
    fetchAppPrices()
  ])
}
