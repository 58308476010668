import styled from 'styled-components';
import { colors } from '../../../helpers/appColors';

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px 20px 150px;
  
  h2{
    font-weight: bold;
    color: ${colors.textGrey};
    border-bottom: solid ${colors.primaryGreen} 1px;
    padding: 10px;
    span{
      color: ${colors.primaryGreen};
    }
  }
  
  h4{
    font-weight: bold;
    color: ${colors.primaryGreen};
    text-align: center;
    padding: 10px;
  }
  
  .specimen-picture{
    width: 20%;
    margin: 20px 40px;
    -webkit-box-shadow: 3px 3px 10px -1px #8c8c8c;
    -moz-box-shadow: 3px 3px 10px -1px #8c8c8c;
    box-shadow: 3px 3px 10px -1px #8c8c8c;
  }
  
  .invoice-specimen{
    width: 40%;
    -webkit-box-shadow: 3px 3px 10px -1px #8c8c8c;
    -moz-box-shadow: 3px 3px 10px -1px #8c8c8c;
    box-shadow: 3px 3px 10px -1px #8c8c8c;
  }
  
  .macaron{
    border-radius: 5px;
    border: solid 1px lightgrey;
    margin: 10px 0px;
    background: white;
    padding: 10px;
  }
  
  td{
    text-align: center;
  }
  
`;

export default StyleWrapper;