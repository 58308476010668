const actions = {
  REQ_FETCH_REMINDERS: "REQ_FETCH_REMINDERS",
  UPDATE_REMINDERS: "UPDATE_REMINDERS",
  REMINDERS_IS_LOADING: "REMINDERS_IS_LOADING",
  BULK_DELETE_REMINDER: "BULK_DELETE_REMINDER",
  EXPORT_TO_EXCEL_REMINDER:"EXPORT_TO_EXCEL_REMINDER",
  OPEN_MODAL : 'OPEN_MODAL',

  reqFetchReminders: () => ({
    type: actions.REQ_FETCH_REMINDERS,
  }),

  updateReminders: data => ({
    type: actions.UPDATE_REMINDERS,
    payload: data
  }),

  toggleLoader: (isLoading) => ({
    type: actions.REMINDERS_IS_LOADING,
    payload: isLoading
  }),

  bulkDeleteReminder: references => ({
    type: actions.BULK_DELETE_REMINDER,
    payload: {references}
  }),

  exportToExcelReminders : ()=>({
      type : actions.EXPORT_TO_EXCEL_REMINDER
  }),

  _openModal : (openModal)=>({
    type : actions.OPEN_MODAL,
    payload : openModal
  })
};

export default actions;
