import styled from "styled-components";
import { colors } from "../../../helpers/appColors";
import { Modal } from "antd";

const StyledModal = styled(Modal)`

    .modal-content .m-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .text-principal{
        font-weight:bold;
    }

    .option-suivi span{
        margin-right:10px;
    }
    .form{
        margin-top: 20px;
        display: flex;
    }
    .date {
        margin-right: 30px;
    }
    .form-inputs {
        width: 60%;
    }

    .name-file{
        font-size: 12px;
        color : ${colors.primaryBlack};
        margin-right : 10px;
    }
    .remove-btn-file{
        border: 0px solid;
        color : ${colors.primaryRed};
    }
    .annotation button{
        margin-top: 20px;
        padding: 0;
        border: 0px;
        color: ${colors.primaryGreen};
        background-color: transparent;
        cursor: pointer;
    }
    .history-item{
        margin: 20px 0px;
        display: flex;
        align-items:center;
    }
    .history-date{
        margin-right: 30px;
    }
    .content-history hr{
        width: 20%;
        margin: inherit;
        border-color: #ffffff59;
    }
    .history-details{
        display: flex;
        align-items:center;
        flex-direction: row;
        gap: 50px;
    }

    .history-details p {
        margin: 0;
        padding: 0;
    }
    .mailing {
        text-align: center;
    }
    .mailing .m-status{
        font-weight: bold;
    }
    .mailing .m-numero{
        color: ${colors.primaryGreen};
    }
    .d-status {
            font-weight: bold;
    }
    .d-message {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .d-email {
        color: #000;
    }
    .d-document a{
        color: ${colors.primaryBlack};
        font-weight: bold;
        text-decoration : underline;
    }
    .company-name{
        color: black;
    }
`;

export default StyledModal
