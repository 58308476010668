const actions = {
  TOGGLE_BURGER: "TOGGLE_BURGER",

  toggleBurger: data => ({
    type: actions.TOGGLE_BURGER,
    payload: data
  }),

};

export default actions;
