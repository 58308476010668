import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';

import {Row, Col, Button, Divider, Table, Form} from 'antd';
import {EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import moment from "moment";
import { colors } from '../../../helpers/appColors';
import {DeleteButton, FormInput} from "../../../helpers/forms";
import NumberFormat from "react-number-format";
import adminActions from "../../../Redux/admin/actions";

class AdminBce extends Component{

  state = {
    editingKey: null,
    value1: null,
    value2: null,
    beginAt: null
  };

  tableFormRef = React.createRef();

  onFinish = (values) => {
    this.props.addRate('bce', values.value1, values.value2, values.beginAt);
  };

  saveRow = (values) => {
    const { value1, value2, rateId, beginAt } = values;
    this.props.editRate(value1, value2, moment(beginAt).format('YYYY-MM-DD'), rateId);
    this.setState({
      editingKey: null,
      value1: null,
      beginAt: null
    });
  };

  setEditingValues = (row) => {
    this.setState({
      editingKey: row.id,
      value1: row.value1,
      value2: row.value2,
      beginAt: row.beginAt
    },this.tableFormRef.current.setFieldsValue({
      value1: row.value1,
      value2: row.value2,
      beginAt: moment(row.beginAt),
      rateId: row.id
    }));

  };

  cancelEdition = () => {
    this.setState({
      editingKey: null,
      value1: null,
      value2: null,
      beginAt: null
    });
  };

  render(){

    const columns = [
      {
        title: `Date d'effet`,
        dataIndex: 'beginAt',
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <FormInput
            name={`beginAt`}
            type={`datePicker`}
            rules={[{ required: true, message: `Ce champ est obligatoire` }]}
          />
        ):(
          <span>{moment(record.beginAt).format('DD/MM/YYYY')}</span>
        ),
        sorter: (a,b) => moment(a.beginAt).format('YYYY-MM-DD') < moment(b.beginAt).format('YYYY-MM-DD') ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Taux majoré de 10 pts',
        dataIndex: 'value1',
        sorter: (a,b) => a.value1 < b.value1 ? -1:1,
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <span>
            <FormInput
              name='value1'
              rules={[{required: true, message: `Ce champ est obligatoire`}]}
              suffix={'%'}
              size='small'
            />
          </span>
        ):(
          <span>
            <NumberFormat
              displayType={'text'}
              value={text}
              decimalSeparator={','}
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator={' '}
              suffix={'%'}
            />
          </span>
        ),
        showSorterTooltip: false
      },
      {
        title: 'Taux majoré de 8 pts',
        dataIndex: 'value2',
        sorter: (a,b) => a.value2 < b.value2 ? -1:1,
        render: (text, record, idx) => this.state.editingKey === record.id ? (
          <span>
            <FormInput
              name='value2'
              rules={[{required: true, message: `Ce champ est obligatoire`}]}
              suffix={'%'}
              size='small'
            />
          </span>
        ):(
          <span>
            <NumberFormat
              displayType={'text'}
              value={text}
              decimalSeparator={','}
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator={' '}
              suffix={'%'}
            />
          </span>
        ),
        showSorterTooltip: false
      },
      {
        title: `Actions`,
        width: 100,
        showSorterTooltip: false,
        render: (text, record, idx) =>  this.state.editingKey === record.id ? (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              type='default'
              style={{
                backgroundColor: colors.primaryGreen,
                borderColor: colors.primaryGreen,
                color: 'white'
              }}
              size='small'
              htmlType={`submit`}
            >
              <CheckOutlined />
            </Button>
            <Button
              type='default'
              href="#"
              size='small'
              onClick={() => this.cancelEdition()}
            >
              <CloseOutlined />
            </Button>
          </div>
        ):(
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button
              type='default'
              style={this.state.editingKey === null ?{
                backgroundColor: colors.primaryBlue,
                borderColor: colors.primaryBlue,
                color: 'white'
              }:null}
              size='small'
              href='javascript:void(0);'
              onClick={() => this.setEditingValues(record)}
              disabled={this.state.editingKey !== null}
            >
              <EditOutlined/>
            </Button>
            <DeleteButton
              disabled={this.state.editingKey !== null}
              onCancel={() => false}
              onConfirm={() => this.props.deleteRate(record.id)}
              component={
                <Button
                  type='default'
                  style={this.state.editingKey === null ? {
                    backgroundColor: colors.primaryRed,
                    borderColor: colors.primaryRed,
                    color: 'white'
                  }:null}
                  size='small'
                  disabled={this.state.editingKey !== null}
                >
                  <DeleteOutlined />
                </Button>
              }
            />
          </div>
        )
      },
    ];

    let data = [];

    if(this.props.config.rates){
      this.props.config.rates.bce.map((rate, idx) => {
        data.push(rate);
      });
    }

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center'}}>
          <Col span={18} className={'shadowed-block'}>

            <h3>Taux BCE</h3>

            <h4>Ajouter un taux</h4>

            <Form onFinish={this.onFinish}>
              <Row gutter={16}>
                <Col span={5}>
                  <FormInput label={`Date d'effet`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Date d'effet`} type='datePicker' name='beginAt'/>
                </Col>
                <Col span={5}>
                  <FormInput label={`Taux majoré de 10 pts`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Taux majoré de 10 pts`} type='number' name='value1' suffix={'%'} style={{marginTop: 4}} size={'small'}/>
                </Col>
                <Col span={5}>
                  <FormInput label={`Taux majoré de 8 pts`} rules={[{required: true, message: 'Ce champ est obligatoire'}]} placeholder={`Taux majoré de 8 pts`} type='number' name='value2' suffix={'%'} style={{marginTop: 4}} size={'small'}/>
                </Col>
                <Col span={5}>
                  <Button htmlType={'submit'} type={'default'} style={{background: colors.primaryBlue, borderColor: colors.primaryBlue, color: 'white', marginTop: 25}}>Sauvegarder</Button>
                </Col>
              </Row>
            </Form>

            <Divider/>

            <Form onFinish={this.saveRow} ref={this.tableFormRef}>
              <Form.Item name={'rateId'} hidden={true}/>
              <Table
                rowKey={'id'}
                size={'small'}
                bordered={true}
                columns={columns}
                dataSource={data}
              />
            </Form>
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    config: state.admin.config
  }),
  {
    addRate: adminActions.reqAddRate,
    deleteRate: adminActions.reqDeleteRate,
    editRate: adminActions.reqEditRate
  }
)(AdminBce));