import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Row, Col, Form, Spin, Button} from 'antd';
import connect from "react-redux/es/connect/connect";
import {colors} from "../../../helpers/appColors";
import {FormSelect, FormUpload} from "../../../helpers/forms";
import userActions from '../../../Redux/user/actions';

class TabOptions extends Component{

  state = {
    searchValue: null,
    fileLogo: null,
    filePaper: null,
    fileLogoVisible: false,
    filePaperVisible: false
  };

  formRef = React.createRef();

  onFinish = (values) => {
    const dataToSent = {
      fileLogo: this.state.fileLogo ? this.state.fileLogo : null,
      filePaper: this.state.filePaper ? this.state.filePaper : null,
      ...values
    };
    this.props.requestUpdateOptions(dataToSent);
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      fileLogoVisible: (user.logoType === 'custom'),
      filePaperVisible: (user.paperType === 'custom')
    })
  }

  handleOnValuesChange = (changedValue, allValues) => {
    if(changedValue.logoType){
      this.setState({fileLogoVisible: (changedValue.logoType === 'custom')})
    }
    if(changedValue.paperType){
      this.setState({filePaperVisible: (changedValue.paperType === 'custom')})
    }
  }

  handleFileLogo = file => {
    this.setState({ fileLogo: file });
    return false;
  };

  handleFilePaper = file => {
    this.setState({ filePaper: file });
    return false;
  };

  render(){
    const { user } = this.props;

    if(!user) return (<Spin spinning={true}>Chargement...</Spin>);

    const logoOptions = [
      {text: 'Direct Recouvrement', value: 'direct_recouvrement'},
      {text: 'Mon logo', value: 'custom'},
      {text: 'Aucun logo', value: 'none'}
    ];

    const paperOptions = [
      {text: 'Aucun en-tête', value: 'none'},
      {text: 'Mon en-tête', value: 'custom'}
    ];

    return (
      <Spin spinning={!user}>
        <Form onFinish={this.onFinish} ref={this.formRef} onValuesChange={this.handleOnValuesChange}>
          <div className='grey-block'>
            <h3>
              Mes impressions
              <Button
                type='default'
                style={{marginLeft: 20}}
                size='small' className='blue-btn' htmlType='submit'>
                Enregistrer
              </Button>
              <Button
                type='default'
                style={{marginLeft: 10}}
                size='small'
                onClick={() => this.formRef.current.resetFields()}
              >
                Annuler
              </Button>
            </h3>
            <Row gutter={16}>
              <Col span={8} offset={1} style={{display: 'flex', alignItems: 'center'}}>
                <strong>Quel logo sur le justificatif comptable ?</strong>
              </Col>
              <Col span={6} style={{display: 'flex', alignItems: 'center'}}>
                <FormSelect name='logoType' options={logoOptions} initialValue={user.logoType ? user.logoType : 'direct_recouvrement'}/>
              </Col>
              {
                this.state.fileLogoVisible && (
                  <>
                    <Col span={6} offset={9}>
                      <FormUpload
                        buttonLabel='Votre logo (format .jpg ou .png)'
                        name={`logo`}
                        valuePropName={`file`}
                        accept={`.jpg,.png`}
                        action={'#'}
                        multiple={false}
                        maxCount={1}
                        beforeUpload={this.handleFileLogo}
                      />
                    </Col>
                    <Col span={5}>
                        <img src={user.logoPath} alt='' style={{width: '100%'}} />
                    </Col>
                  </>
                )
              }
            </Row>
            <Row gutter={16}>
              <Col span={8} offset={1} style={{display: 'flex', alignItems: 'center'}}>
                <strong>Quel en-tête pour vos relances ?</strong>
              </Col>
              <Col span={6} style={{display: 'flex', alignItems: 'center'}}>
                <FormSelect name='paperType' options={paperOptions} initialValue={user.paperType ? user.paperType : 'none'}/>
              </Col>
              {
                this.state.filePaperVisible && (
                  <>
                    <Col span={6} offset={9}>
                      <FormUpload
                        buttonLabel='Papier en-tête (format .jpg)'
                        name={`paper`}
                        valuePropName={`file`}
                        accept={`.jpg`}
                        action={'#'}
                        multiple={false}
                        beforeUpload={this.handleFilePaper}
                      />
                    </Col>
                    <Col span={5}>
                      <img src={user.paperPath} alt='' style={{width: '100%'}} />
                    </Col>
                  </>
                )
              }
            </Row>
            <Row gutter={16}>
              <Col offset={9} span={15}>
                <p style={{color: colors.textRed, fontSize: '0.9em'}}>
                  Vous devez télécharger votre papier en-tête (format .jpg), si vous voulez activer l'option{' '}
                  <span style={{textDecoration: 'underline'}}>envoi par nos services</span> de vos courriers "Rappel d'échéance", "Avis d'impayé" et "Mise en demeure".
                </p>

                <p style={{fontSize: '0.9em'}}>
                  <span style={{textDecoration: 'underline'}}>Aide</span> :  Pour convertir un papier en-tête pdf au format jpeg, cliquer sur le lien{' '}
                  <a href='https://www.ilovepdf.com/fr/pdf_en_jpg' target='_blank' rel="noreferrer">https://www.ilovepdf.com/fr/pdf_en_jpg</a>, sélectionnez votre papier en-tête et convertissez le en jpeg !
                </p>
              </Col>
            </Row>
          </div>
          {/*<div className='grey-block'>*/}
          {/*  <h3>Notifications</h3>*/}
          {/*  <Row gutter={16}>*/}
          {/*    <Col span={23} offset={1}>*/}
          {/*      <p>*/}
          {/*        Recevez par mail, sous forme de notifications, les suggestions de relance que notre algorithme évalue pour vos impayés (Mise en demeure, Courrier Direct Recouvrement, ...)*/}
          {/*      </p>*/}

          {/*      <p>*/}
          {/*        Décochez la case <em>notifications de relance</em> si vous ne souhaitez plus recevoir ces notifications.*/}
          {/*      </p>*/}

          {/*      <FormSwitch id='reminderNotification' name='reminderNotification' label={`Notifications de relance`} initialValue={user ? user.reminderNotification : true}/>*/}

          {/*      <br/>*/}

          {/*      <p>*/}
          {/*        "Destinataire inconnu à l'adresse", "Pli refusé", "AR reçu" ... Recevez par mail, en temps réel, les notifications de la Poste sur le suivi de nos courriers.*/}
          {/*      </p>*/}

          {/*      <p>*/}
          {/*        Décochez la case <em>notifications de la poste</em> si vous ne souhaitez plus recevoir ces notifications.*/}
          {/*      </p>*/}

          {/*      <FormSwitch id='laposteNotification' name='laposteNotification' label={`Notifications de la poste`} initialValue={user ? user.laposteNotification : true}/>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
          {/*<div className='grey-block'>*/}
            {/*<h3>Protégez vos factures automatiquement</h3>*/}
            {/*<Row gutter={16}>*/}
              {/*<Col span={24}>*/}
                {/*<ol>*/}
                  {/*<li style={{marginBottom: 10}}>Acceptez la charte Business Bonnes Pratiques sur votre page <a href='/bbp'>Label BBP</a></li>*/}
                  {/*<li style={{marginBottom: 10}}>Intégrez vos factures non échues dans le tableau "Factures" <a href='#' target='_blank' rel="noreferrer">Comment intégrer ses factures dans Recouvr'Up ?</a></li>*/}
                  {/*<li style={{marginBottom: 10}}>Activez la case <em>Envoi automatique</em> pour déclancher 4 jours avant l'échéance le mail Recouvr'Up de protection des factures*</li>*/}
                {/*</ol>*/}
              {/*</Col>*/}
              {/*<Col span={23} offset={1}>*/}
                {/*<FormSwitch id='automatedFollowing' name='automatedFollowing' label={`Envoi automatique J - 4 avant date d'échéance`} initialValue={user ? user.automatedFollowing : false}/>*/}
                {/*<br/>*/}
                {/*<em style={{fontSize: '0.9em'}}>* Assurez-vous d'avoir bien renseigné l'adresse mail de votre client pour chacune de vos factures intégrées</em>*/}
              {/*</Col>*/}
            {/*</Row>*/}
          {/*</div>*/}
        </Form>
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info
  }),
  {
    requestUpdateOptions: userActions.reqUpdateOptions
  }
)(TabOptions));