import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from "../../../Redux/user/actions";
import StyleWrapper from './StyleWrapper';

import { Row, Col, Tabs } from 'antd';
import CustomerReminders from './CustomerReminders';
import CustomerInvoices from './CustomerInvoices';
import { SendOutlined } from '@ant-design/icons';
import {colors} from "../../../helpers/appColors";
import CustomerClients from './CustomerClients';


const { TabPane } = Tabs;

class CustomerDashboard extends Component{



  render(){

    return (
      <StyleWrapper>
        <Row gutter={16} style={{justifyContent: 'center', width: '95%',marginLeft: '85px'}}>
          <Col span={24} className={'shadowed-block'}>
            <Tabs>
              <TabPane tab="Relances" key="1">
                <CustomerReminders/>
              </TabPane>
              <TabPane tab="Factures" key="2">
                <CustomerInvoices/>
              </TabPane>
              <TabPane tab="Clients" key="3">
                <CustomerClients/>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </StyleWrapper>
    )
  }
}


export default withRouter(connect(
  state => ({
    token: state.user.token
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
  }
)(CustomerDashboard));
