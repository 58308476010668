import { all } from "redux-saga/effects";
import userSaga from "./user/saga";
import homeNavbarSaga from "./homeNavbar/saga";
import adminSaga from './admin/saga';
import appSaga from './app/saga';
import calculatorSaga from './calculator/saga';
import invoiceSaga from './invoices/saga';
import reminderSaga from './reminders/saga';
import orderSaga from './order/saga';
import subscriptionSaga from './subscription/saga';
import invoicePdfSaga from './invoicesPdf/saga';
import espaceDebiteurSage from './espaceBebiteur/saga';
import clientsSaga from "./clients/saga";
export default function* devSaga() {
  yield all([
    userSaga(),
    homeNavbarSaga(),
    adminSaga(),
    appSaga(),
    calculatorSaga(),
    invoiceSaga(),
    reminderSaga(),
    orderSaga(),
    subscriptionSaga(),
    invoicePdfSaga(),
    espaceDebiteurSage(),
    clientsSaga(),
  ]);
}
