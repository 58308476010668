import React, { Component, useState } from 'react';
import { withRouter,useLocation } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { Form, Button, Row, Col, Divider, Tooltip, Upload } from 'antd';
import { PlusOutlined, DeleteOutlined, RightOutlined, LeftOutlined, CloseCircleOutlined} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import modalActions from '../../../Redux/modals/actions';
import calculatorActions from '../../../Redux/calculator/actions';
import invoicesPdfActions from '../../../Redux/invoicesPdf/actions';

import {showError} from '../../../helpers/notificationHelper';

import StyleWrapper from './StyleWrapper';
import {FormInput, FormRadio, FormAutocomplete, FormSelect, FormUpload} from '../../../helpers/forms';
import { colors } from '../../../helpers/appColors';
import redIcon from '../../../resources/images/landing_page/icone/icone-lecture-rouge.png';
import greyIcon from '../../../resources/images/landing_page/icone/icone-lecture-gris.png';
import blackIcon from '../../../resources/images/landing_page/icone/icone-lecture-noir.png';
import penaltyThumb from '../../../resources/images/landing_page/autre/autre-penalites.png';
import reminderThumb from '../../../resources/images/landing_page/autre/autre-relances.png';
import tribunalThumb from '../../../resources/images/landing_page/autre/autre-paiement.png';
import specimenJustificatif from '../../../resources/specimen_calcul.pdf';
import specimenRappel from '../../../resources/specimen_relance_niveau_1.pdf';
import specimenAvisImpaye from '../../../resources/specimen_relance_niveau_2.pdf';
import specimenMiseEnDemeure from '../../../resources/specimen_relance_niveau_3.pdf';
import specimenMailDR from '../../../resources/specimen_mail_direct_recouvrement.pdf';
import specimenCourrierDR from '../../../resources/specimen_courrier_direct_recouvrement.pdf';
import specimenMiseEnDemeureDR from '../../../resources/specimen_recommande_direct_recouvrement.pdf';
import specimenInjonction from '../../../resources/specimen_injonction_de_payer.pdf';
import WrittenProofModal from "../Modals/WrittenProof";
import MailDirectRecouvrementModal from "../Modals/MailDirectRecouvrement";
import CourrierDirectRecouvrementModal from "../Modals/CourrierDirectRecouvrement";
import MiseEnDemeureDirectRecouvrementModal from "../Modals/MiseEnDemeureDirectRecouvrement";
import InjonctionModal from "../Modals/Injonction";
import ReminderModal from "../Modals/Reminder";
import UnpaidNoticeModal from "../Modals/UnpaidNotice";
import MiseEnDemeureModal from "../Modals/MiseEnDemeure";
import {Collapse} from 'antd';
import { removeFileRequest, removePDFRequest } from '../../../Redux/invoicesPdf/saga';
import avisAmiable from '../../../resources/images/specimen/avis-amiable.jpg';

import { v4 as uuidv4 } from 'uuid'; 

import apiConfig from   '../../../config/api.config'
import { IdNameCompany } from '../../../helpers/utils';
import CustomerHeader from '../Header/CustomerHeader';
import CustomerReminders from '../Dashboard/CustomerReminders';

const { Panel } = Collapse;
class CustomerCalculator extends Component {

  state = {
    step: 1,
    etape3: 'Recouvrement',
    titleEtape3: 'Choisissez votre action de recouvrement',
    hasPenalty: 'false',
    penalty: 'false',
    companyId: null,
    companyName: null,
    companyType: null,
    maxLength: 45,
    index: 0,
    ouvertMenuPage: false,
    invoices: [
      {
        number: null,
        emittedAt:null,
        dueAt: null,
        totalWithTaxes: null,
        payments: [],
        disabled: false,
        factureName: '',
        factureUrl: ''
      },
    ]
  };

  formRef = React.createRef();


  async componentDidMount(){
     const { pathname } = window.location;
    let etape3;
    let titleEtape3;
    let ouvertMenuPage;

    if (pathname === '/penalites') {
      etape3 = 'Obtenez un justificatif comptable'
      titleEtape3 = 'Obtenez votre justificatif comptable en 1 clic'
      this.setState({ etape3,hasPenalty: "true",ouvertMenuPage: true});
    } else if (pathname === '/amiable') {
      etape3 = 'Sélectionnez votre courrier de relance'
      titleEtape3 = 'Sélectionnez le type de courrier que vous souhaitez envoyer à votre débiteur'
      this.setState({ etape3,hasPenalty: "false",ouvertMenuPage: true});
    } else if (pathname === '/contentieux') {
      etape3 = "Sélectionnez l’action du cabinet Direct Recouvrement ®"
      titleEtape3 = 'Choisissez l’action à mener par notre cabinet Direct Recouvrement'
      this.setState({ etape3,hasPenalty: "false", ouvertMenuPage: true});
    } else if (pathname === '/judiciaire') {
      etape3 = "Lancez la procédure d’injonction de payer"
      titleEtape3 = 'Lancez la procédure d’injonction de payer avec un huissier de justice'
      this.setState({ etape3,hasPenalty: "false",ouvertMenuPage: true});
    } else {
      etape3 = 'Recouvrement'
      titleEtape3 = 'Choisissez votre action de recouvrement'
      this.setState({ etape3,hasPenalty: "false", ouvertMenuPage: true});
    }

    this.setState({ etape3, titleEtape3, ouvertMenuPage });
    //this.setState({titleEtape3})
    await this.props.reqFetchMyCompanies();

    if(this.props.defaultFormValues){
      
      const {defaultFormValues} = this.props;
      if(!Array.isArray(defaultFormValues.invoices)) {
        defaultFormValues.invoices = JSON.parse(defaultFormValues.invoices);
      }
      defaultFormValues.invoices.map(el => {
        el.payments.map(payment => {
          payment.emittedAt = moment(payment.emittedAt);
        });
        el.dueAt = moment(el.dueAt);
        el.emittedAt = moment(el.emittedAt);
        el.disabled = true;
      });


      if(defaultFormValues.rateType !== 'none'){
        this.setState({hasPenalty: 'true'});
        defaultFormValues.hasPenalty = 'true';
        this.formRef.current.setFieldsValue({hasPenalty: 'true'});
      }else{
        this.setState({hasPenalty: 'false'});
        defaultFormValues.hasPenalty = 'false';
        this.formRef.current.setFieldsValue({hasPenalty: 'false'});
      }

      if (pathname === '/penalites') {
        this.setState({ hasPenalty: 'true' });
        defaultFormValues.hasPenalty = 'true';
        this.formRef.current.setFieldsValue({ hasPenalty: 'true' });
      }

      if(!defaultFormValues.companyType){
        switch(defaultFormValues.rateType){
          case 'commercial_majored_legal_interest':
            defaultFormValues.companyType = 'commercial_company'
            break;
          case 'commercial_legal_interest':
            defaultFormValues.companyType = 'commercial_company'
            break;
          case 'commercial_fixed_annual':
            defaultFormValues.companyType = 'commercial_company'
            break;
          case 'individual_majored_legal_interest':
            defaultFormValues.companyType = 'private_individual'
            break;
          case 'individual_legal_interest':
            defaultFormValues.companyType = 'private_individual'
            break;
          case 'individual_fixed_annual':
            defaultFormValues.companyType = 'private_individual'
            break;
          case 'public_majored_bce':
            defaultFormValues.companyType = 'public_facility'
            break;
          default:
            defaultFormValues.companyType = null
            break;
        }
      }

      const selectedCompany = _.find(this.props.myCompanies, {'name': defaultFormValues.companyName});
      if(!defaultFormValues.companyId){
        defaultFormValues.companyId = selectedCompany ? selectedCompany.id : null;
      }

      for(const key in defaultFormValues){
        if(defaultFormValues[key] === 'null'){
            defaultFormValues[key] = null
        }
      }

      this.formRef.current.setFieldsValue(defaultFormValues);

      this.setState({
        companyName: defaultFormValues.companyName,
        companyType: defaultFormValues.companyType,
        step: defaultFormValues.step,
        invoices: defaultFormValues.invoices
      });

      if(defaultFormValues.step === 3){
        this.props.compute(defaultFormValues);
        switch(defaultFormValues.submitType){
          case 'written_proof':
            this.props.showModal('customerWrittenProof');
            break;
          case 'reminder':
            this.props.showModal('customerReminder');
            break;
          case 'unpaid_notice':
            this.props.showModal('customerUnpaidNotice');
            break;
          case 'mise_en_demeure':
            this.props.showModal('customerMiseEnDemeure');
            break;
          case 'mail_direct_recouvrement':
            this.props.showModal('customerMailDirectRecouvrement');
            break;
          case 'courrier_direct_recouvrement':
            this.props.showModal('customerCourrierDirectRecouvrement');
            break;
          case 'mise_en_demeure_direct_recouvrement':
            this.props.showModal('customerMiseEnDemeureDirectRecouvrement');
            break;
          case 'injonction':
            this.props.showModal('customerInjonction');
            break;
        }
      }else{
        this.props.hideAllModal()
      }
    }else{
      this.formRef.current.setFieldsValue(this.state);
    }
  }
  
  async componentDidUpdate(prevProps) {
    const { invoicesPdf } = this.props;
    const { pdfUrl } = invoicesPdf;
    const { index } = this.state;
    const newFormValues = this.formRef.current.getFieldsValue();
  
    if (pdfUrl !== prevProps.invoicesPdf.pdfUrl) {
      newFormValues.invoices[index].factureUrl = pdfUrl;
      this.formRef.current.setFieldsValue(newFormValues);
      this.setState({ invoices: newFormValues.invoices });
    }

  }
  

  handleAddInvoice = () => {
    const obj = {
      number: null,
      emittedAt: null,
      dueAt: null,
      totalWithTaxes: null,
      payments: [],
      disabled: false,
      factureName: '',
      factureUrl: ''
    };

    const newFormValues = this.formRef.current.getFieldsValue();
    newFormValues.invoices.push(obj);

    this.formRef.current.setFieldsValue(newFormValues);
    this.setState({
      invoices: newFormValues.invoices
    })
  };

  resetCompanyName = () => {

    const obj = {
      companyName: null,
      invoices: [
        {
          number: null,
          emittedAt:null,
          dueAt: null,
          totalWithTaxes: null,
          payments: []
        }
      ]
    };

    this.formRef.current.setFieldsValue(obj);

    this.setState(obj);

  };

  onValuesChange = (changedValues, allValues) => {
    if(changedValues.hasPenalty !== undefined){
      this.setState({hasPenalty: changedValues.hasPenalty});
      if(changedValues.hasPenalty === 'false'){
        this.formRef.current.setFieldsValue({rateType: 'none'});
      }
    }
  };

  validateForm = (step) => {
    const values = this.formRef.current.getFieldsValue();
    if(step === 1){
      const {companyName, invoices} = values;
      if(!companyName){
        return showError('MISSING_INPUT');
      }

      for(let invoice of invoices){
        if(!(invoice.number && invoice.emittedAt && invoice.dueAt && invoice.totalWithTaxes)){
          return showError('MISSING_INPUT');
        }
        for(let payment of invoice.payments){
          if(!(payment.amount && payment.emittedAt)){
            return showError('MISSING_INPUT');
          }
        }
      }

      this.formRef.current.setFieldsValue({
        reminderCompanyName: companyName,
        unpaidNoticeCompanyName: companyName,
        miseEnDemeureCompanyName: companyName,
        courrierDrCompanyName: companyName,
        miseEnDemeureDrCompanyName: companyName,
        injonctionCompanyName: companyName,
      })

      this.setState({step: 2});
    }
    else if(step === 2){
      const {rateType, commercialFixedAnnualRate, individualFixedAnnualRate, hasPenalty} = values;

      if(hasPenalty !== 'false' && rateType === 'none')
        return showError('MISSING_INPUT_PENALTY');

      if(rateType === 'commercial_fixed_annual' && !commercialFixedAnnualRate)
        return showError('MISSING_INPUT');

      if(rateType === 'individual_fixed_annual' && !individualFixedAnnualRate)
        return showError('MISSING_INPUT');

      this.props.compute(values);
      this.setState({step: 3});
      window.scrollTo(0, 300);
    }
  };

  handleSelectCompany = (value, option) => {
    const { companyName } = IdNameCompany(value);
    if (_.find(this.props.myCompanies, {'name': companyName,'id': option.id})) {
      const obj = {
        number: null,
        emittedAt: null,
        dueAt: null,
        totalWithTaxes: null,
        payments: [],
        disabled: false
      };

      const newFormValues = this.formRef.current.getFieldsValue();
      newFormValues.invoices = [obj];
      newFormValues.companyId = option.id;
      newFormValues.companyName = companyName;

      this.formRef.current.setFieldsValue(newFormValues);
      this.setState({
        companyId: option.id,
        companyName: companyName,
        invoices: newFormValues.invoices
      });
    }
  };

  handleChangeCompany = (value) => {
    const { companyId, companyName } = this.formRef.current.getFieldsValue();
    if(companyId){
      if(!companyName || !_.find(this.props.myCompanies, {'name': companyName})){
        this.formRef.current.setFieldsValue({
          companyId: null
        });
      }
    }
  };

  onFinish = (values) => {
    if(values.submitType && this.state.step === 3){
      // Finalize form request
      this.props.updateDefaultFormValues(values);
      this.props.generateOrder(values);
    }else{
      this.props.compute(values);
    }
  };

  render(){

    const companies = this.props.myCompanies ? this.props.myCompanies : [];

    const { companyName, companyId } = this.state;
    const { pathname } = window.location;

    const selectedCompany = _.find(companies, {'name': companyName,'id' : companyId});

    const companyOptions =  [];

    companies.map((el) => {
      companyOptions.push({
        label: `${el.name} - ${el.city ? `${el.city}`:``}`,
        value: `${el.id}-${el.name}`,
        id: el.id
      })
    });

    const invoiceOptions = [];
    
    if(selectedCompany) {
      selectedCompany.invoices.map(el => {
        if(!el.archived) {
          invoiceOptions.push({
            id: el.id,
            value: el.number,
            emittedAt: el.emittedAt,
            dueAt: el.dueAt,
            totalWithTaxes: el.totalWithTaxes,
            factureName: el.factureName,
            factureUrl: el.factureUrl
          })
        }
      });
    }

    const PaymentForm = ({field, index, remove, allFields}) => {

      return (
        <Row gutter={16} style={{margin: 0}}>
          <Col span={11}>
            <FormInput preserve={true} name={[index, 'amount']} label={index === 0 ? `Montant`: ''} type='currency' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
          </Col>
          <Col span={11}>
            <FormInput preserve={true} name={[index, 'emittedAt']} label={index === 0 ? `Date` : ''} type='datePicker' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
          </Col>
          <Col span={2} style={{paddingTop: (index === 0 ? `30px`: '10px'),paddingLeft:0}}>
            <Button type='default' size='small' shape='circle' onClick={() => remove(field.name)}><DeleteOutlined/></Button>
          </Col>
        </Row>
      );
    };

    const InvoiceForm = ({field, index, remove, allFields}) => {

      const handleUploadFile = async (event,index) =>{
       
        const file = event.fileList[0];
        
        const newFormValues = this.formRef.current.getFieldsValue();
        newFormValues.invoices[index].factureName = file.name;

        this.formRef.current.setFieldsValue(newFormValues);
        this.setState({
          invoices: newFormValues.invoices,
          index: index
        })

        // SEND FILE
        this.props.uploadPDFRequest({pdf:file, invoiceId: newFormValues.invoices[index].id,number:newFormValues.invoices[index].number,isDashboard:false});
       
      };
    
      const handleRemoveFile = (event,index) => {


      const invoiceId = this.state.invoices[index].id;
      const path = this.state.invoices[index].factureUrl;

      const newFormValues = this.formRef.current.getFieldsValue();
      newFormValues.invoices[index].factureName = null;
      newFormValues.invoices[index].factureUrl = null;

      this.formRef.current.setFieldsValue(newFormValues);
      this.setState({
        invoices: newFormValues.invoices,
        index: index
      })

      if (invoiceId)
        this.props.removePDFRequest({id: invoiceId});
      else
        this.props.removeFileRequest(path)
      };

      if(!this.state.invoices[index]){
        return <></>
      }

      const handleOnChange = (value,index) => {
       
      }

      const handleOnBlur = (e) => {
        const value = e.target.value;
        const option = _.find(invoiceOptions, {'value': value});

        if(option) {
          const newFormValues = this.formRef.current.getFieldsValue();
          newFormValues.invoices[index].id = option.id;
          newFormValues.invoices[index].emittedAt = moment(option.emittedAt);
          newFormValues.invoices[index].dueAt = moment(option.dueAt);
          newFormValues.invoices[index].totalWithTaxes = option.totalWithTaxes;
          newFormValues.invoices[index].disabled = true;
          newFormValues.invoices[index].factureName = option.factureName;
          newFormValues.invoices[index].factureUrl = option.factureUrl;

          this.formRef.current.setFieldsValue(newFormValues);
          this.setState({
            invoices: newFormValues.invoices
          });
        }else{
          const newFormValues = this.formRef.current.getFieldsValue();
          newFormValues.invoices[index].id = null;
          newFormValues.invoices[index].emittedAt = null;
          newFormValues.invoices[index].dueAt = null;
          newFormValues.invoices[index].totalWithTaxes = null;
          newFormValues.invoices[index].disabled = false;

          this.formRef.current.setFieldsValue(newFormValues);
          this.setState({
            invoices: newFormValues.invoices
          });
        }
      };

      const handleOnSelect = (value,option) => {
        const newFormValues = this.formRef.current.getFieldsValue();
        newFormValues.invoices[index].id = option.id;
        newFormValues.invoices[index].emittedAt = moment(option.emittedAt);
        newFormValues.invoices[index].dueAt = moment(option.dueAt);
        newFormValues.invoices[index].totalWithTaxes = option.totalWithTaxes;
        newFormValues.invoices[index].disabled = true;
        newFormValues.invoices[index].factureName = option.factureName;
        newFormValues.invoices[index].factureUrl = option.factureUrl;

        this.formRef.current.setFieldsValue(newFormValues);
        this.setState({
          invoices: newFormValues.invoices
        });
      };

      const handleRemove = (fieldName, index) => {
        if(allFields.length === 1){
          const resetedInvoices = [{
            id:null,
            number: null,
            emittedAt: null,
            dueAt: null,
            totalWithTaxes: null,
            payments: [],
            disabled: false
          }];

          const newFormValues = this.formRef.current.getFieldsValue();
          newFormValues.invoices = resetedInvoices;
          this.formRef.current.setFieldsValue(newFormValues);

          this.setState({
            invoices: resetedInvoices
          });
        }else{
          let newInvoicesList = [];
          this.state.invoices.map((el, idx) => {
            if(index !== idx){
              newInvoicesList.push(el);
            }
          });
          this.setState({
            invoices: newInvoicesList
          });
          remove(fieldName);
        }
      };

      return (
        <Row gutter={[8,16]} style={{marginTop: 20}} className='grey-block'>
          <Col span={16}>
            <Row  gutter={[8,16]}>
              <Col span={6}>
                <FormAutocomplete preserve={true} name={[index, 'number']} options={invoiceOptions} onBlur={handleOnBlur} onSelect={handleOnSelect} label='N° de facture' rules={[{ required: true, message: `Ce champ est obligatoire` }]} onChange={(event)=>{handleOnChange(event,index)}}/>
              </Col>
              <Col span={5}>
                <FormInput disabled={this.state.invoices[index].disabled} preserve={true} name={[index, 'emittedAt']} label='Date de facture'  type='datePicker' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={5}>
                <FormInput disabled={this.state.invoices[index].disabled} preserve={true} name={[index, 'dueAt']} label={`Date d'échéance`} type='datePicker' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={6}>
                <FormInput disabled={this.state.invoices[index].disabled} preserve={true} name={[index, 'totalWithTaxes']} label='Montant (ttc)' type='currency' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={2} style={{paddingTop: 30}}>
                <Button type='default' size='small' shape='circle' onClick={() => handleRemove(field.name, index)}><DeleteOutlined/></Button>
              </Col>
            </Row>
            <Row gutter={[8,16]}>
              <Col span={24}>
                <div style={{marginBottom:10}}>
                  <div style={{display:'none'}}>
                    <FormInput  name={[index,'factureName']}  disabled={this.state.invoices[index].disabled} preserve={true} type='hidden'></FormInput>
                    <FormInput  name={[index,'factureUrl']}  disabled={this.state.invoices[index].disabled} preserve={true} type='hidden'></FormInput>
                  </div>
                  {!this.state.invoices[index].factureUrl && (
                   <>
                    <span className='label-upload'>
                      Facture 
                    </span>
                    <Upload key={uuidv4()} accept={'.pdf'}  onChange={(event)=>{ handleUploadFile (event,index)}} fileList={[]} maxCount={1}>
                        <Button className='upload-btn'  size='middle'>Parcourir...</Button>
                    </Upload>
                   </>
                    
                  )}
                  {this.state.invoices[index].factureUrl && (
                    <div>
                      <a href={`${apiConfig.baseUrl}/facture/${this.props.user.id}/${this.state.invoices[index].factureUrl}`} target='_blank' className='link-file'>{this.state.invoices[index].factureName}</a>
                      <Button className='remove-btn-file' onClick={(event)=>{handleRemoveFile(event,index)}}>supprimer</Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={[8,16]}>
               <Col span={24}>
               {
                  (index + 1 === allFields.length) && (
                    <Button
                      type='default'
                      style={{backgroundColor: colors.primaryBlue, border: 'none', color: 'white'}}
                      size='small'
                      onClick={this.handleAddInvoice}>
                      <PlusOutlined/> Ajouter
                    </Button>
                  )
                }
               </Col>
                
            </Row>
          </Col>
          <Col span={8} style={{borderLeft: 'solid 1px lightgrey', paddingLeft: 20, paddingRight: 0}}>
            <Form.List name={[field.name, 'payments']}>
              {
                (fields, {add, remove}) => (
                  <>
                    {
                      fields.map((field, index) => {
                        return (
                          <PaymentForm key={index} field={field} index={index} remove={remove} allFields={fields}/>
                        )
                      })
                    }

                    <Row gutter={16} style={{margin: 0}}>
                      <Col span={24}>
                        <Button
                          type='default'
                          style={{backgroundColor: colors.primaryBlue, border: 'none', color: 'white'}}
                          size='small'
                          onClick={() => add()}
                        >
                          <PlusOutlined/> Ajouter
                        </Button>
                      </Col>
                    </Row>
                  </>
                )
              }
            </Form.List>
          </Col>
        </Row>
      );
    };

    const  reminderButton = <Button type='default' className='blue-btn' style={{fontWeight: '600',padding: '0 30px'}} onClick={() => this.props.showModal('customerReminder')}>Envoyer un rappel</Button>;
    const  unpaidNoticeButton = <Button type='default' className='blue-btn'  style={{fontWeight: '600', padding: '0 30px'}} onClick={() => this.props.showModal('customerUnpaidNotice')}>Envoyer un avis d’impayé</Button>;
    const miseEnDemeureButton = <Button type='default' className='blue-btn' style={{fontWeight: '600', padding: '0 30px'}} onClick={() => this.props.showModal('customerMiseEnDemeure')}>Envoyer une mise en demeure</Button>;

    return (
      <StyleWrapper>
        <Row gutter={16} style={{textAlign: 'center', justifyContent: 'center',width: '95%',marginLeft: '85px'}}>
          <Col span={24} >
            <Row gutter={16} className='shadowed-block' style={{padding: 50}}>
              <Col span={8}>
                <div className={this.state.step === 1 ? "circle-btn-active" : "circle-btn"} id="circle-step-1">Etape 1</div>
                <strong>Saisissez vos factures impayées</strong>
              </Col>
              <Col span={8}>
                <div className={this.state.step === 2 ? "circle-btn-active" : "circle-btn"} id="circle-step-2">Etape 2</div>
                <strong>Estimez vos pénalités de retard</strong>
              </Col>
              <Col span={8}>
                <div className={this.state.step === 3 ? "circle-btn-active" : "circle-btn"} id="circle-step-3">Etape 3</div>
                <strong>{this.state.etape3}</strong>
              </Col>
              <div id="step-menu-bar"/>
            </Row>
          </Col>
          <Col span={24} >
            <Row gutter={16} className='shadowed-block' style={{padding: 30, textAlign: 'left'}}>
              <Col span={24}>
                <Form onFinish={this.onFinish} ref={this.formRef} id={'form'} onValuesChange={this.onValuesChange}>

                  <Form.Item name={'submitType'} hidden={true}/>
                  <Form.Item name={'companyId'} hidden={true}/>
                  <Form.Item name={'reference'} hidden={true}/>
                  <Form.Item name={'rateType'} initialValue={'none'} hidden={true}/>

                  <WrittenProofModal formRef={this.formRef}/>
                  <MailDirectRecouvrementModal formRef={this.formRef}/>
                  <CourrierDirectRecouvrementModal formRef={this.formRef}/>
                  <MiseEnDemeureDirectRecouvrementModal formRef={this.formRef}/>
                  <InjonctionModal formRef={this.formRef}/>
                  <ReminderModal formRef={this.formRef}/>
                  <UnpaidNoticeModal formRef={this.formRef}/>
                  <MiseEnDemeureModal formRef={this.formRef}/>

                  {/* --- STEP 1 --- */}
                  <div id='step1' style={this.state.step !== 1 ? {display: 'none'} : null}>
                    <h3><strong>Etape 1 :</strong> Saisissez vos factures impayées</h3>
                    <br/>
                    <Row gutter={16} className='grey-block'>
                      <Col span={24}>
                        <table style={{width: '100%'}}>
                          <tbody>
                            <tr>
                              <td><strong>Nom / Raison sociale de votre débiteur <span style={{color: 'red'}}>*</span></strong></td>
                              <td style={{width: "30%", paddingLeft: 10}}>
                                <FormAutocomplete
                                  disabled={selectedCompany}
                                  name='companyName'
                                  options={companyOptions}
                                  rules={[{ required: true, message: `Ce champ est obligatoire` },{max: this.state.maxLength-1, message: `Maximum ${this.state.maxLength} caractères`, warningOnly: true}]}
                                  onSelect={this.handleSelectCompany}
                                  onChange={this.handleChangeCompany}
                                  normalize={value => (value || '').toUpperCase()}
                                  maxLength={this.state.maxLength}
                                />
                              </td>
                              <td>
                                <Tooltip title={(<div style={{textAlign: 'center'}}>Attention la modification de l'entreprise va remettre à zéro toutes les factures saisies</div>)} placement='top'>
                                  <CloseCircleOutlined
                                    onClick={() => this.resetCompanyName()}
                                    style={ selectedCompany ? {fontSize: 16, marginLeft: 10, color: colors.textGrey} : {display: 'none'}}/>
                                </Tooltip>
                              </td>
                              <td style={{textAlign: 'right'}}><strong><a href='https://recouvrup.zendesk.com/hc/fr/articles/360020975899' target='_blank' rel="noreferrer">Comment saisir ses factures impayées?</a></strong></td>
                            </tr>
                          </tbody>
                        </table>

                      </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: 40}}>
                      <Col span={16} style={{textAlign: 'center'}}>
                        <h4>Factures</h4>
                        <em style={{color: 'grey', fontSize: 12}}>Factures issues de votre importation</em>
                      </Col>
                      <Col span={8} style={{textAlign: 'center'}}>
                        <h4>Règlements</h4>
                        <em style={{color: 'grey', fontSize: 12}}>Règlements reçus après l'échéance</em>
                      </Col>
                    </Row>
                    <Form.List name='invoices'>
                      {
                        (fields, {add, remove}) => (
                          <>
                            {
                              fields.map((field, index) => {
                                return (
                                  <InvoiceForm key={index} field={field} index={index} remove={remove} allFields={fields}/>
                                )
                              })
                            }
                          </>
                        )
                      }
                    </Form.List>
                  </div>

                  {/* --- STEP 2 --- */}
                  <div id='step2' style={this.state.step !== 2 ? {display: 'none'} : null}>
                    <h3><strong>Etape 2 :</strong> Calculez vos pénalités de retard  {pathname === '/penalites' ? "" : (<span style={{textDecoration: 'underline'}}>(Facultatif)</span>)}</h3>
                    <br/>
                    <Row gutter={16} className='grey-block'>
                      {pathname === '/penalites' ? "" : (
                        <>
                      <Col span={24} style={{textAlign: 'center'}}>
                        <h4>Souhaitez-vous calculer des pénalités de retard ?</h4>
                        {/*<strong style={{right: 0, top: 0, position: 'absolute'}}><a href='https://recouvrup.zendesk.com/hc/fr/articles/115002364189-Quel-taux-d-int%C3%A9r%C3%AAt-choisir-%C3%A0-l-%C3%A9tape-2-' target='_blank' rel="noreferrer">Quel taux choisir ?</a></strong>*/}
                      </Col>
                      <Col span={24} style={{textAlign: 'center'}}>
                        <FormRadio
                          style={{fontSize: 18}}
                          name='hasPenalty'
                          layout='horizontal'
                          initialValue={'false'}
                          preserve={false}
                          options={[
                            {label: 'OUI', value: 'true'},
                            {label: 'NON', value: 'false'},
                          ]}
                        />
                        </Col></>)}
                      <Col span={24} style={{display: this.state.hasPenalty  === 'true' ? 'flex' : 'none', justifyContent: 'space-around', alignItems: 'stretch', marginTop: 30}}>
                        <div className='bordered-block'>
                          <h2 style={{textAlign: 'center', fontWeight: '700'}}>Pour commencer, sélectionnez le type de client qui n’a pas payé votre ou vos factures</h2>
                          <Col span={24} >
                          <div style={{justifyContent: 'center',display: 'flex',alignItems: 'center'}}>
                            <strong>Votre client est</strong>
                            <FormSelect
                              options={[
                                {text: 'une société commerciale', value: 'commercial_company'},
                                {text: 'un établissement public', value: 'public_facility'},
                                {text: 'un particulier', value: 'private_individual'},
                              ]}
                              placeholder={`Sélectionner un type`}
                              name='companyType'
                              style={{display: 'inline-block', width: '300px', marginLeft: 10}}
                              onChange={(value) => {
                                this.setState({companyType: value});
                                this.formRef.current.setFieldsValue({rateType: 'none'})
                              }}
                            />
                            <strong style={{right: 0, top: '10px', position: 'absolute',alignItems: 'center'}}><a href='https://recouvrup.zendesk.com/hc/fr/articles/115002364189-Quel-taux-d-int%C3%A9r%C3%AAt-choisir-%C3%A0-l-%C3%A9tape-2-' target='_blank' rel="noreferrer">Quel taux choisir ?</a></strong>
                          </div>
                          </Col>
                          {
                            this.state.companyType === 'commercial_company' && (
                              <>
                                <p style={{textAlign: 'center'}}>Choisissez parmi ces 3 taux </p>
                                <br/>
                                <div style={{width: '50%', minWidth: 250, margin: 'auto'}}>
                                  <FormRadio
                                    name='rateType'
                                    layout='vertical'
                                    options={[
                                      {label: (
                                        <>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: 5 }}>Taux fixe annuel de </span>
                                            <FormInput
                                              name={'commercialFixedAnnualRate'}
                                              type='number'
                                              style={{
                                                borderBottom: 'solid grey 1px',
                                                borderLeft: 'none',
                                                borderTop: 'none',
                                                borderRight: 'none',
                                                borderRadius: '0'
                                              }}
                                            />
                                            <span style={{ marginLeft: 5 }}>%</span>
                                          </div>
                                        </>
                                      ), value: 'commercial_fixed_annual'},
                                      {label: `3x taux d'intérêt légal`, value: 'commercial_majored_legal_interest'},
                                      {label: <>Taux BCE majoré de 10 points (<span style={{textDecoration: 'underline'}}>par défaut</span>)</>, value: 'commercial_majored_bce'}
                                    ]}
                                  />
                                </div>
                              </>
                            )
                          }

                          {
                            this.state.companyType === 'public_facility' && (
                              <>
                              <Divider/>
                                <p style={{textAlign: 'center'}}>Choisissez ce taux </p>
                                <div style={{width: '50%', minWidth: 250, margin: 'auto'}}>
                                  <FormRadio
                                    name='rateType'
                                    layout='vertical'
                                    options={[
                                      {label: <>Taux BCE majoré de 8 points</>, value: 'public_majored_bce'}
                                    ]}
                                />
                                </div>
                              </>
                            )
                          }
                          {
                            this.state.companyType === 'private_individual' && (
                              <>
                              <Divider/>
                                <p style={{textAlign: 'center'}}>Choisissez parmi ces 3 taux </p>
                                <br/>
                                <div style={{width: '50%', minWidth: 250, margin: 'auto'}}>
                                  <FormRadio
                                    name='rateType'
                                    layout='vertical'
                                    options={[
                                      { label: (
                                        <>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: 5 }}>Taux fixe annuel de </span>
                                            <FormInput
                                              name={'individualFixedAnnualRate'}
                                              type='number'
                                              style={{
                                                borderBottom: 'solid grey 1px',
                                                borderLeft: 'none',
                                                borderTop: 'none',
                                                borderRight: 'none',
                                                borderRadius: '0'
                                              }}
                                            />
                                            <span style={{ marginLeft: 5 }}>%</span>
                                          </div>
                                        </>
                                      ), value: 'individual_fixed_annual'},
                                      {label: <>Taux d'intérêt légal (<span style={{textDecoration: 'underline'}}>par défaut</span>)</>, value: 'individual_legal_interest'},
                                      {label: `3x taux d'intérêt légal`, value: 'individual_majored_legal_interest'}
                                    ]}
                                  />
                                </div>
                              </>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                 {/* Nouveau design */}
                 <div id='step3' style={this.state.step !== 3 ? {display: 'none'} : null}>
                    <h3><strong>Etape 3 : </strong>{this.state.titleEtape3}</h3>
                    <br/>
                    <Collapse ghost accordion>
                        { pathname === '/penalites' && <div
                          className='step3-block'>
                            <Row>
                              <Col span={6} style={{padding: 10, display: 'flex', alignItems: 'right', marginLeft: '60px'}}>  <img alt='' src={penaltyThumb} style={{width: '80%', background: 'white'}}/></Col>
                              <Col span={12} style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}> 
                              <div className='step3-choices' >
                                <Button type='default' className='blue-btn' style={{fontWeight: '600',padding: '0 20px'}} onClick={() => this.props.showModal('customerWrittenProof')}> Obtenir mon justificatif (pdf)</Button><br/>
                                <p style={{lineHeight: 1}}><em>Nous fournirons un justificatif comptable<br/>certifié à envoyer directement à votre<br/>débiteur par mail ou courrier</em></p>
                                <a href={specimenJustificatif} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                              </Col>
                            </Row>
                        </div>}
                        { pathname === '/amiable' && <div
                          className='step3-block' >
                            <Row gutter={16}>
                            <Col span={4} style={{padding: 10, display: 'flex', alignItems: 'center'}}>
                              <img alt='' src={avisAmiable} style={{width: '100%', background: 'white'}}/>
                            </Col>
                            <Col span={20} style={{display: 'flex', justifyContent: 'space-around',  alignItems: 'flex-end'}}>
                              <div className='step3-choices btn'>
                                {reminderButton}<br/>
                                <p style={{lineHeight: 1}}><em>Optimal si vous n'avez pas<br/>été payé dans les délais <br/>impartis et souhaitez faire<br/>une première relance</em></p>
                                <a href={specimenRappel} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                              <div className='step3-choices btn'>
                                {unpaidNoticeButton}<br/>
                                <p style={{lineHeight: 1}}><em>A sélectionner si votre client ne <br/> s'est pas manifesté à la suite de <br/> vos premières relances<br/><br/></em></p>
                                <a href={specimenAvisImpaye} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                              <div className='step3-choices btn'>
                                {miseEnDemeureButton}<br/>
                                <p style={{lineHeight: 1}}><em>Votre situation n'est pas régularisée ? <br/> Envoyez une mise en demeure à votre <br/> client pour le faire réagir dans les plus <br/>brefs délais</em></p>
                                <a href={specimenMiseEnDemeure} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                            </Col>
                          </Row>
                        </div>}
                        { pathname === '/contentieux' && <div 
                          className='step3-block'>
                            <Row gutter={16}>
                            <Col span={4} style={{padding: 10, display: 'flex', alignItems: 'center'}}>
                              <img alt='' src={reminderThumb} style={{width: '100%', background: 'white'}}/>
                            </Col>
                            <Col span={20} style={{display: 'flex', justifyContent: 'space-around', alignItems: 'stretch'}}>
                              <div className='step3-choices'>
                                <Button type='default' className='blue-btn' style={{fontWeight: '600', padding: '0 20px'}} onClick={() => this.props.showModal('customerMailDirectRecouvrement')}>Envoyer notre mail à votre débiteur</Button><br/>
                                <p style={{lineHeight: 1}}><em>
                                  Notre cabinet Direct Recouvrement<br/>
                                  s'occupe de contacter votre<br/>
                                  débiteur par mail pour réclamer <br/>
                                  votre dû
                                </em></p>
                                <a href={specimenMailDR} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                              <div className='step3-choices'>
                                <Button type='default' className='blue-btn' style={{fontWeight: '600',  padding: '0 20px'}} onClick={() => this.props.showModal('customerCourrierDirectRecouvrement')}>Expédier notre courrier à votre débiteur</Button><br/>
                                <p style={{lineHeight: 1}}><em>Nous préparons un courrier "coup<br/> de poing", a forte valeur probante,<br/>pour inciter votre débiteur a <br/>régulariser sa situation</em></p>
                                <a href={specimenCourrierDR} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                              <div className='step3-choices'>
                                <Button type='default' className='blue-btn' style={{fontWeight: '600', padding: '0 20px'}} onClick={() => this.props.showModal('customerMiseEnDemeureDirectRecouvrement')}>Expédier notre mise en demeure à votre débiteur</Button><br/>
                                <p style={{lineHeight: 1}}><em>Nous envoyons une mise en demeure avec <br/> AR a votre débiteur afin de l'obliger a régler <br/> vos factures<br/><br/></em></p>
                                <a href={specimenMiseEnDemeureDR} target='_blank' rel="noreferrer">Spécimen</a>
                              </div>
                            </Col>
                          </Row>
                          </div>}
                         { pathname === '/judiciaire' && <div className='step3-block'>
                            <Row gutter={16}>
                              <Col span={4} style={{padding: 10, display: 'flex', alignItems: 'center'}}>
                                <img alt='' src={tribunalThumb} style={{width: '100%', background: 'white'}}/>
                              </Col>
                              <Col span={20} style={{display: 'flex', justifyContent: 'space-around', alignItems: 'stretch'}}>
                                <div className='step3-choices'>
                                  <Button type='default' className='blue-btn' style={{fontWeight: '600',padding: '0 20px'}} onClick={() => this.props.showModal('customerInjonction')}>Lancer la procédure d’injonction de payer</Button><br/>
                                  <p style={{lineHeight: 1}}><em>
                                    Nous adressons une requête au Tribunal<br/>
                                    et obtenons une ordonnance de paiement<br/>
                                    qui seran signifiée a votre débiteur par un <br/>
                                    huissier de justice
                                  </em></p>
                                  <a href={specimenInjonction} target='_blank' rel="noreferrer">Spécimen</a>
                                </div>
                              </Col>
                            </Row>
                          </div>}
                    </Collapse>
                  </div>
                  <Row gutter={16} style={{textAlign: 'right', borderTop: 'solid #f1f1f1 1px', paddingTop: 20, marginTop: 40}}>
                    <Col span={24}>
                      <Button type='primary' onClick={() => this.validateForm(1)} style={this.state.step !== 1 ? {display: 'none'} : null}>Étape Suivante <RightOutlined/></Button>

                      <Button type='primary' onClick={() => this.setState({step: 1})} style={this.state.step !== 2 ? {display: 'none'} : {float: 'left'}}><LeftOutlined/> Étape précédente</Button>
                      <Button type='primary' onClick={() => this.validateForm(2)} style={this.state.step !== 2 ? {display: 'none'} : null}>Étape Suivante <RightOutlined/></Button>

                      <Button type='primary' onClick={() => this.setState({step: 2})} style={this.state.step !== 3 ? {display: 'none'} : {float: 'left'}}><LeftOutlined/> Étape précédente</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <CustomerHeader ouvertMenuPage={this.state.ouvertMenuPage} />
      </StyleWrapper>
    )
  }
}



export default withRouter(connect(
  state => ({
    defaultFormValues: state.calculator.defaultFormValues,
    user: state.user.info,
    myCompanies: state.calculator.myCompanies,
    invoices: state.invoices,
    invoicesPdf: state.invoicesPdf
  }),
  {
    showModal: modalActions.showModal,
    hideAllModal: modalActions.hideAllModal,
    compute: calculatorActions.reqCompute,
    reqFetchMyCompanies: calculatorActions.fetchMyCompanies,
    generateOrder: calculatorActions.reqGenerateOrder,
    updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
    uploadPDFRequest: invoicesPdfActions.uploadPDFRequest,
    removePDFRequest: invoicesPdfActions.removePDFRequest,
    removeFileRequest: invoicesPdfActions.removeFileRequest
  }
)(CustomerCalculator));
