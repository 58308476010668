import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import StyleWrapper from './StyleWrapper';
import userActions from '../../../Redux/user/actions';
import modalActions from '../../../Redux/modals/actions';


import { Button } from 'antd';
import RdvModal from './Modals/Rdv';
import PricingModal from './Modals/Pricing';
import ContactModal from './Modals/Contact';


class CustomerFooter extends Component{

  render(){

    const {documents} = this.props;

    const mentionLegalesDocument = _.find(documents,{name: 'mentions_legales'});
    const cguDocument = _.find(documents,{name: 'cgu'});

    const MenuButton = ({text, link, isExternal, onClickHandler}) => {

      return (
        <li>
          <Button
            className={`menu-button${this.props.location.pathname === link ? ' active' : ''}`}
            type='text'
            href={link}
            target={isExternal ? '_blank': ''}
            rel={isExternal ? 'noreferrer' : null}
            onClick={onClickHandler}
          >
            <div className='menu-title'>{text}</div>
          </Button>
        </li>
      );
    };

    return (
      <StyleWrapper>
        <ul>
          <MenuButton text={'Notre cabinet'} link={'http://www.direct-recouvrement.fr/'} isExternal={true}/>
          <MenuButton text={'Rdv téléphonique'} link={'https://calendly.com/recouvrup/30min'} isExternal={true}/>
          <MenuButton text={'FAQ'} link={'https://recouvrup.zendesk.com/hc/fr'} isExternal={true}/>
          <MenuButton text={'Tarifs'} onClickHandler={() => this.props.showModal('customerPricing')}/>
          <MenuButton text={'Contact'} onClickHandler={() => this.props.showModal('customerContact')}/>
          <MenuButton text={'Mentions légales'} link={mentionLegalesDocument.url} isExternal={true}/>
          <MenuButton text={'CGU'} link={cguDocument.url} isExternal={true}/>
        </ul>
        <RdvModal/>
        <ContactModal/>
        <PricingModal/>
      </StyleWrapper>
    )
  }
}

export default withRouter(connect(
  state => ({
    user: state.user.info,
    documents: state.app.documents
  }),
  {
    logout: userActions.logout,
    showModal: modalActions.showModal
  }
)(CustomerFooter));