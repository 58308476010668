import React from 'react';
import { notification } from 'antd';
import errorMessage from "../helpers/errorMessages";

export const showError = code => {
  notification.error({
    duration: 10,
    message: 'Ooops...',
    description: errorMessage[code],
  })
};

export const showSuccess = (title, message, duration = 0) => {
  notification.success({
    duration: duration,
    message: title,
    description: message,
  })
};