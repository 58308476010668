/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { all, fork, put, takeLatest, call } from "redux-saga/effects";
import actions from "./actions";
import * as notificationHelper from "../../helpers/notificationHelper";
import ApiProvider from "./../../helpers/api";
import history from "../../helpers/history"
import download from 'downloadjs';
import apiConfig from "../../config/api.config";
import axios from "axios";

function* fetchReminder(action) {
  try {
    yield put(actions.isLoading(true));
    const reference = action.payload;
    const response = yield call(
      ApiProvider.axiosWithToken.get,
      `/reminder/${reference}`
    );
    yield put(actions.isLoading(false));
    yield put(actions.updateReminder(response.data.newReminder));
    history.push('/reference-dossier');
  } catch (error) {
    yield put(actions.isLoading(false));
    const errorMessage = error.response.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

function* computeStatus(action) {
  try {
    yield put(actions.isLoading(true));
    const { file, data } = action.payload;
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (file)
      formData.append("file", file.originFileObj);

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const response = yield call(
      ApiProvider.axiosWithToken.post,
      "/reminder/compute-status",
      formData,
      config
    );

    yield put(actions.isLoading(false));
    yield put(actions.updateReminder(null));
    history.push('/debiteur');
    notificationHelper.showSuccess(
      "Success",
      "Votre message a bien été expédié",
      4
    );
  } catch (error) {
    yield put(actions.isLoading(false));
    const errorMessage = error.response.data.error || "SERVER_ISSUE";
    notificationHelper.showError(errorMessage);
  }
}

function* downloadPdf(action){
    try{
      const pdfLinks = action.payload
      if(pdfLinks.length >0){
        pdfLinks.forEach(link => {
          const url =  encodeURI(link);
          download(url);
        });
      }else{
        notificationHelper.showError('NO_FACTURES');
      }
    }catch (error) {
      const errorMessage = error?.response?.data?.error || "SERVER_ISSUE";
      notificationHelper.showError(errorMessage);
    }
}

function* payWithBridge(data) {
  yield takeLatest(actions.PAY_WITH_BRIDGE, function* ({ payload }) {
    try {
      // console.log("payload", payload);
      const response = yield call(
        axios.post,
        `${apiConfig.apiUrl}/bridgePayment`,
        payload
      );
      notificationHelper.showSuccess(
        `Confirmation`,
        `Bridge Pay with success`,
        5
      );
      console.log(response, window);
      window.open(response?.data.paymentLink.url, "_blank");
    } catch (e) {
      if (e.response?.data.error) {
        notificationHelper.showError(e.response.data.error);
      } else {
        notificationHelper.showError("SERVER_ISSUES");
      }
    }
  });
}

export function* reqFetchReminder() {
  yield takeLatest(actions.REQ_FETCH_REMINDER, fetchReminder);
}

export function* reqComputeStatus() {
  yield takeLatest(actions.REQ_COMPUTE_STATUS, computeStatus);
}

export function* downloadFactures() {
  yield takeLatest(actions.DOWNLOAD_FACTURE,downloadPdf);
}



export default function* rootSaga() {
  yield all([
    fork(reqFetchReminder),
    fork(reqComputeStatus),
    fork(downloadFactures),
    fork(payWithBridge),
  ]);
}
